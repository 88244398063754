
/* =========================================================
section
========================================================= */

.l-faq{

  .leadtext {
    max-width: 780px;
    margin: 0 auto;
    text-align: center;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
      text-align: left;
    }
  }

  .inner {
    max-width: 780px;
    margin: 0 auto 80px;
    text-align: left;
    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }

  &__list {
    max-width: 780px;
    text-align: left;
    margin: 0 auto;
    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }

  &__item {
    margin: 0 auto 80px;
  }

  h3 {
    border-bottom: solid 1px #ccc;
    font-size: $FONT-SIZE-24;
    //line-height: 1;
    //padding-bottom: 10px;
  }
  p {
    font-size: $FONT-SIZE-18;
  }

  a {
    padding: 0 0 0 15px;
    background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
    background-size: 5px;
    font-size: $FONT-SIZE-18;
    display: inline-block;
    color: $COLOR__ACCENT-PRIMARY;
  }
  a + ul {
    margin: 10px 0 0 20px;
    a {
      font-size: $FONT-SIZE-16;
    }
  }
}


/* ------------------------------------------------------------ */


.l-firstaid {
    margin-bottom: 80px;

  .case {
    max-width: 1000px;
    margin: 0 auto 20px;
    text-align: left;
  }

  h3 {
    font-size: $FONT-SIZE-24;
    border-left: solid 5px $COLOR__ACCENT-PRIMARY;
    padding: 0 0 0 10px;
    color: $COLOR__ACCENT-PRIMARY;
    cursor: pointer;

    &:hover {

      @include mq(lg) {
        background-color: #e3f5ff;
      }
    }
  }

  h4 {
    font-size: $FONT-SIZE-18;
    //border-bottom: solid 1px #ccc;
    //padding-bottom: 10px;
    margin: 0;
    padding:  10px 10px 20px;
    line-height: 1
  }

  .inner {}

  .acd + .wrapper {
    display: none;
  }

  .block {
    background: #e3f5ff;
    padding: 10px;
    border-radius: 5px;

    & + .block {
      margin-top: 20px;
    }
  }

  ul {
    font-size: $FONT-SIZE-18;
    background-color: #fff;
    margin: 0;
    padding: 10px;

    & + h4 {
      padding: 20px 10px;
    }
  }

  li {
    margin-left: 2em;
    text-indent: -1.5em;

    & + li {
      margin-top: .5em;
    }
  }
}
