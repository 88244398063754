
/* =========================================================
section
========================================================= */

.l-sitemap {

  .layer {
    &--top {
      max-width: 1000px;
      margin: 0 auto 80px;
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      @include mq(md) {
        max-width: 95%;
      }

      @include mq(sm) {
        max-width: 95%;
        flex-direction: column;
      }

      h3 {
        width: 100%;
        margin: 0;
        margin-bottom: 40px;

        a {
          display: inline-block;
          width: 100%;
        }

        img {
          width: auto;
          height: auto;

          @include mq(sm) {
            max-width: 80%;
          }
        }
      }

      i {
        display: none;
      }
    }

    &--secondary {
      width: 48%;

      @include mq(sm) {
        width: 100%;
      }
      h4 {
        border-bottom: solid 1px #ccc;
        font-size: $FONT-SIZE-24;
        margin: 0;
        padding: 0;
      }

      > li {
        margin-bottom: 20px;
      }
    }
    &--tertiary {
      font-size: $FONT-SIZE-18;
      //margin-bottom: 20px;
      margin: 20px 0 0 20px;

      > li + li {
        //margin-top: 20px;
      }

      h5 {
        border-bottom: solid 1px #ccc;
        font-size: $FONT-SIZE-18;
        margin: 0;
        padding: 0;
      }
    }
    &--quaternary {
      font-size: $FONT-SIZE-14;
      //margin-bottom: 20px;
      margin: 20px 0 20px 20px;
    }
  }

  .aca {
    h4 {
      border: none;
    }

    img {
      width: auto;
      height: auto;
    }
  }
}
