
/* =========================================================
privacyPolicy
========================================================= */



.l-privacypolicy {
  margin:  0 0 80px;

  .leadtext {
    max-width: 780px;
    margin: 0 auto;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
      //font-size: $FONT-SIZE-14;
    }
  }

  &__list {
    max-width: 780px;
    margin: 80px auto 0;
    text-align: left;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }

  &__item {
    margin: 0;
    font-size: $FONT-SIZE-18;

    @include mq(sm) {
      //font-size: $FONT-SIZE-14;
    }

    h3 {
      border-bottom: solid 1px #ccc;
      font-size: $FONT-SIZE-24;
      padding-left: 1.50em;

      &:first-letter {
        font-size: $FONT-SIZE-18;
        margin-right: .9em;
        margin-left: -1.8em;
      }
    }

    p {}

    ul {

    }

    li {
      margin: 0 0 0 40px;
      list-style-type: decimal;
      & + li {
        margin-top: 10px;
      }
    }
  }

  .lastsentence {
    text-align: right;
    font-size: $FONT-SIZE-18;
    max-width: 780px;
    margin: 0 auto;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }
}
