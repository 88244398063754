
// ==========================================================================
// Layout
// ==========================================================================

.globalWrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 100px 0 0;
  position: relative;
  overflow-x: hidden;

  @include mq(md) {
    min-width: 100%;
    overflow-x: hidden;
  }

  @include mq(sm) {
    min-width: 100%;
    padding: 60px 0 0;
  }
}

/* ==========================================================================

========================================================================== */

main {
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  @include cf;
}

.primary {
  width: 100%;
  max-width: 960px;
  margin: 0 auto 80px;

  @include mq(md) {
    width: 95%;
  }

  @include mq(sm) {
    width: 95%;
  }
}


/* ==========================================================================

========================================================================== */

.page-heading-title {
  @include page-heading-title;
  text-shadow: 0px 4px 16px rgba(0,0,0,0.83);
  text-align: left;
  align-items: center;

  & + p {
    color: #fff;
    font-size: $FONT-SIZE-24;
    font-weight: bold;
    text-shadow: 0px 4px 16px rgba(0,0,0,0.83);
    margin: 0;
    padding: 0;
    text-align: left;
    align-items: center;

    @include mq(sm) {
      font-size: $FONT-SIZE-18;
    }
  }
}

.contents-heading-title {
  @include contents-heading-title;
  line-height: 1;
  margin: 0 0 40px;

  @include mq(md) {
  }

  @include mq(sm) {
    margin: 0 0 20px;
  }

  & + p {
    clear: both;
    font-size: $FONT-SIZE-18;
    line-height: 2;
    text-align: center;
  }
}

.section-heading-title {
  @include section-heading-title;
  line-height: 1;
  margin: 0 0 40px;

  @include mq(md) {
  }

  @include mq(sm) {
    margin: 0 0 40px;
  }

  & + p {
    clear: both;
    font-size: $FONT-SIZE-18;
  }
}

.three-icon {
  //background: url(#{$rootUrl}/images/common/decoration.png) center bottom no-repeat;
  //background-size: 70px;
  //padding-bottom: 70px;
  //line-height: 1;

  @include mq(md) {
  }

  @include mq(sm) {
    //background-size: 50px;
    //padding-bottom: 35px;
  }

  i {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70px;
    overflow: hidden;
    height: 30px;
    margin: 40px auto 0;
    span {
      display: inline-block;
      width: 10px;
      height: 30px;
      margin-top: 30px;

      @include mq(sm) {
        margin-top: 0;
      }

      &:nth-of-type(1) {
        background-color: $COLOR__SUB-PRIMARY;
      }
      &:nth-of-type(2) {
        background-color: $COLOR__SUB-SECONDARY;
      }
      &:nth-of-type(3) {
        background-color: $COLOR__SUB-TERTIARY;
      }
    }
  }
}

.textblock {
  padding: 0 40px;
  text-align: left;

  p {
    font-size: $FONT-SIZE-18;
  }
}

.leadtext {
  font-size: $FONT-SIZE-18;
  margin-bottom: 40px;
  text-align: left;
}

.fluide {
  width: 100%;

  &--30 {
    width: 30%;
  }
  &--40 {
    width: 40%;
  }
  &--50 {
    width: 50%;
  }
  &--60 {
    width: 60%;
  }
  &--70 {
    width: 70%;
  }
}

.frame {
  border: solid 1px #111;
}




/* ==========================================================================

========================================================================== */

.eyecatch {
  width: 100%;
  height: 320px;
  margin: 0;
  padding: 0;
  background-color: #eee;

  @include mq(md) {
  }

  @include mq(sm) {
    width: 100%;
    height: 0;
    padding-top: 22.22222%;
  }

  img {

    @include mq(md) {
    }

    @include mq(sm) {
    }
  }
}

.bleadclumb {
  background-color: #eee;
  width: 100%;
  height: 24px;
  margin: 0 auto 80px;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;

  &.productsStyle {
    background-color: #fff;
    max-width: 1000px;
    margin: 5px auto 40px;

    ul {
      padding-left: 0;

      @include mq(md) {
        padding-left: 20px;
      }

      @include mq(sm) {
        padding-left: 20px;
      }

      li:first-child a {
        padding-left: 0;
      }
    }
  }

  @include mq(md) {
    width: 100%;
  }

  @include mq(sm) {
    width: 100%;
    //height: 41px;
    //display: flex;
  }

  &__list {
    width: 100%;
    //max-width: 1000px;
    height: 24px;
    line-height: 1;
    text-align: left;
    margin: 0 auto 40px;
    padding: 0 10px;
    font-size: $FONT-SIZE-12;
    display: flex;
    flex-direction: row;
    //overflow-x: scroll;
    //overflow-y: hidden;
    //display: inline-block;

    @include mq(md) {
    }

    @include mq(sm) {
      width: auto;
      max-width: none;
      line-height: 30px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      //display: table;
      //margin: 0;
      //display: flex;
      //align-items: center;
    }
  }

  &__item {
    color: #444;
    margin: 0;
    padding: 0;
    height: 24px;
    line-height: 24px;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    //float: left;


    @include mq(sm) {
    }

    & + li {
      padding-left: 2em;
      //width: 9em;
      //min-width: 4em;
      //max-width: 16em;
    }

    & + li:before {
      content: "";
      border-top: solid 2px #999;
      border-right: solid 2px #999;
      width: 8px;
      height: 8px;
      line-height: 1;
      display: block;
      vertical-align: middle;
      transform: rotateZ(45deg);
      transform-origin: center;
      position: absolute;
      top: 8px;
      left: 0.5em;
    }

    &:last-child {
      //padding-right: 2em;
      padding: 0 1em 0 3em;
    }

    a {
      width: 100%;
      height: 24px;
      line-height: 24px;
      padding: 0 1em;
      display: block;
      overflow-wrap:normal;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  img {
    width: 16px;
    height: auto;
    margin: 4px 0 0;
  }
}

/* ==========================================================================

========================================================================== */

.pageheader {
  width: 100%;
  height: 180px;
  background-color: #738088;

  .inner {
    max-width: calc(100% - 20px);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
  }

  .company & {
    background: url(#{$rootUrl}/images/company/pageheader_company.jpg) right center no-repeat;
    background-size: cover;

    @include mq(sm) {
      background: url(#{$rootUrl}/images/company/pageheader_company_sp.jpg) right center no-repeat;
      background-size: cover;
    }
  }

  .companyOverview & {
    background: url(#{$rootUrl}/images/company/pageheader_company_overview.jpg) center no-repeat;
    background-size: cover;

    @include mq(sm) {
      background-position: 43%;
    }
  }

  .companyPhilosophy & {
    background: url(#{$rootUrl}/images/company/pageheader_company_philosophy.jpg) center no-repeat;
    background-size: cover;

    @include mq(sm) {
      background-position: 43%;
    }
  }

  .companyOffice & {
    background: url(#{$rootUrl}/images/company/pageheader_company_office.jpg) center no-repeat;
    background-size: cover;

    @include mq(md) {
      background-position: right center;
    }

    @include mq(sm) {
      background-position: right center;
    }
  }

  .business & {
    background: url(#{$rootUrl}/images/business/pageheader_business.jpg) center no-repeat;
    background-size: cover;
  }

  .businessService & {
    background: url(#{$rootUrl}/images/business/pageheader_business_service.jpg) center no-repeat;
    background-size: cover;
  }

  .businessIndustry & {
    background: url(#{$rootUrl}/images/business/pageheader_business_industry.jpg) center no-repeat;
    background-size: cover;
  }

  .businessAgency & {
    background: url(#{$rootUrl}/images/business/pageheader_business_agency.jpg) right center no-repeat;
    background-size: cover;
  }

  .sanitation & {
    background: url(#{$rootUrl}/images/sanitation/pageheader_sanitation.jpg) center no-repeat;
    background-size: cover;
  }

  .sanitationHACCP & {
    background: url(#{$rootUrl}/images/sanitation/pageheader_sanitation_haccp.jpg) left center no-repeat;
    background-size: cover;
  }

  .sanitationQanda & {
    background: url(#{$rootUrl}/images/sanitation/pageheader_sanitation_qanda.jpg) center no-repeat;
    background-size: cover;
  }

  .sanitationFood_poisoning & {
    background: url(#{$rootUrl}/images/sanitation/pageheader_sanitation_foodpoisoning.jpg) center no-repeat;
    background-size: cover;
  }

  .sanitationCreanpedia & {
    background: url(#{$rootUrl}/images/sanitation/pageheader_sanitation_creanpedia.jpg) center no-repeat;
    background-size: cover;
  }

  .pickup & {
    background: url(#{$rootUrl}/images/pickup/pageheader_pickup.jpg) right center no-repeat;
    background-size: cover;
  }

  .recruit & {
    background: url(#{$rootUrl}/images/recruit/pageheader_recruit.jpg) center no-repeat;
    background-size: cover;
  }

  .recruitSalesnclerk & {
    background: url(#{$rootUrl}/images/recruit/pageheader_recruit_salesnclerk.jpg) center no-repeat;
    background-size: cover;
  }

  .recruitResearcher & {
    background: url(#{$rootUrl}/images/recruit/pageheader_recruit_researcher.jpg) center no-repeat;
    background-size: cover;
  }

  .recruitEntry & {
    background: url(#{$rootUrl}/images/recruit/pageheader_recruit.jpg) center no-repeat;
    background-size: cover;
  }

  .recruitConfirm & {
    background: url(#{$rootUrl}/images/recruit/pageheader_recruit.jpg) center no-repeat;
    background-size: cover;
  }

  .recruitThanks & {
    background: url(#{$rootUrl}/images/recruit/pageheader_recruit.jpg) center no-repeat;
    background-size: cover;
  }

  .faq & {
    background: url(#{$rootUrl}/images/faq/pageheader_faq.jpg) center no-repeat;
    background-size: cover;
  }

  .faqCleanness & {
    background: url(#{$rootUrl}/images/faq/pageheader_faq_cleanness.jpg) center no-repeat;
    background-size: cover;
  }

  .faqFoodfactory & {
    background: url(#{$rootUrl}/images/faq/pageheader_faq_foodfactory.jpg) center no-repeat;
    background-size: cover;
  }

  .faqFirstaid & {
    background: url(#{$rootUrl}/images/faq/pageheader_faq_firstaid.jpg) center no-repeat;
    background-size: cover;
  }

  .news & {
    background: url(#{$rootUrl}/images/news/pageheader_news.jpg) center no-repeat;
    background-size: cover;
  }

  .privacypolicy & {
    background: url(#{$rootUrl}/images/privacypolicy/pageheader_privacypolicy.jpg) center no-repeat;
    background-size: cover;
  }

  .inquiry &,
  .form1 &,
  .form2 &,
  .form3 &,
  .confirm &,
  .thanks & {
    background: url(#{$rootUrl}/images/inquiry/pageheader_inquiry.jpg) right center no-repeat;
    background-size: cover;
  }

  .sitemap & {
    background: url(#{$rootUrl}/images/sitemap/pageheader_sitemap.jpg) center no-repeat;
    background-size: cover;
  }

}


/* ==========================================================================

========================================================================== */

.table {
  width: 100%;
  position: relative;
  margin: 0 0 80px;
  clear: both;

  &--basic {
    width: 100%;
    max-width: 100%;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    position: relative;
    z-index: 1;

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
    }

    tbody {

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
        display: block;
      }
    }

    tr {

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
        display: block;
      }

      th {
        font-size: $FONT-SIZE-18;
        font-weight: normal;
        width: 31.25%;
        padding: 20px;
        text-align: left;

        @include mq(md) {
        }

        @include mq(sm) {
          width: 100%;
          display: block;
          background-color: #eee;
        }
      }
      td {
        font-size: $FONT-SIZE-18;
        width: 68.75%;
        padding: 20px;
        text-align: left;

        @include mq(md) {
        }

        @include mq(sm) {
          width: 100%;
          display: block;
          padding: 20px 20px 40px;
        }
      }

      & + tr th,
      & + tr td {
        border-top: solid 1px #ccc;

        @include mq(md) {
        }

        @include mq(sm) {
        }
      }

      &:first-child th {
        border-bottom: solid 1px #ccc;
      }
    }

    .contact-address {
      //font-size: $FONT-SIZE-14;
      display: block;
      line-height: 2;
    }

    .label {
      &--tel {
        color: #fff;
        background-color: $COLOR__ACCENT-PRIMARY;
        border-radius: 2px;
        padding: 4px 8px;
        font-size: $FONT-SIZE-14;
        line-height: 1;
        margin: 0 .5em 0 0;
        position: relative;
        top: -1px;
      }

      &--fax {
        color: #fff;
        background-color: $COLOR__ACCENT-PRIMARY;
        border-radius: 2px;
        padding: 4px 8px;
        font-size: $FONT-SIZE-14;
        line-height: 1;
        margin: 0 .5em 0 2em;
        position: relative;
        top: -1px;

        @include mq(md) {
        }

        @include mq(sm) {
          margin: 0 .5em 0 0;
        }
      }
    }
  }

  a {
    color: $COLOR__FONT;
  }

  &-shadow {
    width: 31.25%;
    height: calc(100% + 20px);
    display: block;
    background: #eee;
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 0;

    @include mq(md) {
    }

    @include mq(sm) {
      display: none;
    }
  }
}


.defaultTable {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 80px;
  border-bottom: solid 1px #ccc;

  @include mq(md) {
    margin: 0 auto 40px;
  }

  @include mq(sm) {
    margin: 0 auto 40px;
  }
  tbody {

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
      width: 100%;
    }
  }
  tr {

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
      width: 100%;
    }
  }
  th {
    width: 20%;
    font-size: $FONT-SIZE-18;
    border-top: solid 1px #ccc;
    text-align: left;
    padding: 11px 10px;

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
      width: 100%;
      background-color: #f6f6f6;
    }
  }
  td {
    text-align: left;
    font-size: $FONT-SIZE-18;
    border-top: solid 1px #ccc;
    padding: 11px 10px;

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
      width: 100%;
      border-top: none;
    }
  }
  a {
    color: $COLOR__ACCENT-PRIMARY;
    i.fa {
      color: #ccc;
    }
  }
}







/* ==========================================================================

========================================================================== */

.l-localnavi {
  width: 100%;
  background-color: #f6f6f6;
  margin: 0 0 10px;
  padding: 20px 0;

  &__wrapper {
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include mq(md) {
      display: block;
    }

    @include mq(sm) {
      display: block;
    }
  }

  &__category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 100px;
    height: 60px;
    background-color: #f6f6f6;
    margin: 0;
    padding: 0 0 0 20px;

    @include mq(md) {
      height: 100%;
      margin: 0 0 20px;
    }

    @include mq(sm) {
      height: 100%;
      margin: 0 0 20px;
    }

    h4 {
      @include reset;
      color: #444;
      text-align: left;
      font-size: $FONT-SIZE-14;
      font-weight: 700;
      letter-spacing: normal;
      line-height: 1;
      margin-bottom: .5em;

      @include mq(md) {
      }
      @include mq(sm) {
        font-size: $FONT-SIZE-20;
      }
    }
    p { color: #999; }

    &:hover {
      @include mq(lg){
        h4 { color: $COLOR__ACCENT-PRIMARY; }
        p { color: $COLOR__ACCENT-PRIMARY; }
      }
    }
  }
  h5 {
    @include reset;
    align-items: center;
    text-align: left;
    font-size: $FONT-SIZE-13;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: .5em;

    &.fit {
      font-size: $FONT-SIZE-11;

      @include mq(sm) {
        font-size: $FONT-SIZE-18;
      }
    }

    @include mq(md) {
    }
    @include mq(sm) {
      font-size: $FONT-SIZE-18;
      margin: 10px 0 5px;
    }
  }
  p {
    @include reset;
    align-items: center;
    text-align: left;
    font-size: $FONT-SIZE-10;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 1;

    @include mq(md) {
    }
    @include mq(sm) {
      font-size: $FONT-SIZE-15;
    }
  }
  img {
    width: 96px;
    height: 48px;
    margin-right: 10px;

    @include mq(md) {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 6px;
    }

    @include mq(sm) {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 6px;
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 0 0 10px;
    width: 870px;
    height: auto;

    @include mq(md) {
      margin: 0 20px 0 10px;
      width: calc(100% - 30px);
    }

    @include mq(sm) {
      margin: 0 10px 0 10px;
      width: calc(100% - 20px);
      display: block;
    }
  }
  &__item {
    flex-basis: 210px;
    height: 100%;
    background-color: #fff;

    @include mq(md) {
      flex-basis: calc(100% - 50px / 4);
    }

    @include mq(sm) {
      display: block;
    }

    &:hover {
      @include mq(lg) {
        box-shadow: 4px 0 16px rgba(0,0,0,.2);
        transition: box-shadow .5s ease 0s;
      }
    }

    & + li {
      margin-left: 10px;

      @include mq(sm) {
        margin: 10px 0 0;
      }
    }
    a {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 6px;
      flex-basis: 210px;

      @include mq(md) {
        flex-direction: column;
      }

      @include mq(sm) {
        padding: 10px;
        display: block;
      }
      h5 { color: #999; }
      p { color: #ccc; }

      &:hover {
        @include mq(lg){
          h5 { color: $COLOR__ACCENT-PRIMARY; }
          p { color: $COLOR__ACCENT-PRIMARY; }
        }
      }

      img {
        height: 50%;
      }
    }
  }
  .menu-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .x4 {}
  .x3 {}
}
