// ==========================================================================
// Declaration of variables :: Variables SCSS
// ==========================================================================

//$rootUrl: "http://test-creation.com/sample/adeka";
$rootUrl: "";

// color setting
$COLOR__FONT             : #444444;

$COLOR__ACCENT-PRIMARY   : #07ace9;
$COLOR__ACCENT-SECONDARY : #fe000b;

$COLOR__BASE             : #eeeeee;

$COLOR__SUB-PRIMARY      : #0dadf1;
$COLOR__SUB-SECONDARY    : #0039ca;
$COLOR__SUB-TERTIARY     : #09c0a0;

$COLOR__ALERT            : #e61f18;

$COLOR__BORDAR-PRIMARY   : #cccccc;
$COLOR__BORDAR-SECONDARY : #cccccc;

$COLOR__LABEL__01        : #307dfb;
$COLOR__LABEL__02        : #eb371a;

// icon color setting
// $COLOR__ICON-INTERNAL-LINK:  #333333;
// $COLOR__ICON-EXTERNAL-LINK:  #333333;
// $COLOR__ICON-SPECIFIED-LINK: #333333;
// $COLOR__ICON-MAIL:           #333333;
// $COLOR__ICON-PDF:            #333333;
// $COLOR__ICON-IMG:            #333333;
// $COLOR__ICON-DOWNLOAD:       #333333;

// font-family setting
// $FONT-FAMILY__BASE :'Noto Sans JP','Yu Gothic', YuGothic ,'Lucida Grande','Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
// $FONT-FAMILY__BASE :'Lucida Grande','Hiragino Kaku Gothic ProN','Yu Gothic', YuGothic, Meiryo, sans-serif;
// $FONT-FAMILY__NOTO :'Noto Sans JP', sans-serif;
// $FONT-FAMILY__MIN :Georgia, 游明朝,"Yu Mincho", YuMincho,"Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
// $FONT-FAMILY__BASE : 游明朝,"Yu Mincho", YuMincho,"Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;

$FONT-FAMILY__BASE : 'Montserrat','Noto Sans JP','Lucida Grande','Hiragino Kaku Gothic ProN','Yu Gothic', YuGothic, Meiryo, sans-serif;
$FONT-FAMILY__IE : メイリオ, Meiryo,'Lucida Grande','Hiragino Kaku Gothic ProN', sans-serif;
$FONT-FAMILY__MIN : "Sawarabi Mincho", serif;



// font-size
$FONT-SIZE-8 : .8rem;
$FONT-SIZE-9 : .9rem;
$FONT-SIZE-10: 1rem;
$FONT-SIZE-11: 1.1rem;
$FONT-SIZE-12: 1.2rem;
$FONT-SIZE-13: 1.3rem;
$FONT-SIZE-14: 1.4rem;
$FONT-SIZE-15: 1.5rem;
$FONT-SIZE-16: 1.6rem;
$FONT-SIZE-17: 1.7rem;
$FONT-SIZE-18: 1.8rem;
$FONT-SIZE-19: 1.9rem;
$FONT-SIZE-20: 2rem;
$FONT-SIZE-21: 2.1rem;
$FONT-SIZE-22: 2.2rem;
$FONT-SIZE-23: 2.3rem;
$FONT-SIZE-24: 2.4rem;
$FONT-SIZE-25: 2.5rem;
$FONT-SIZE-26: 2.6rem;
$FONT-SIZE-27: 2.7rem;
$FONT-SIZE-28: 2.8rem;
$FONT-SIZE-29: 2.9rem;
$FONT-SIZE-30: 3rem;
$FONT-SIZE-31: 3.1rem;
$FONT-SIZE-32: 3.2rem;
$FONT-SIZE-33: 3.3rem;
$FONT-SIZE-34: 3.4rem;
$FONT-SIZE-35: 3.5rem;
$FONT-SIZE-36: 3.6rem;
$FONT-SIZE-37: 3.7rem;
$FONT-SIZE-38: 3.8rem;
$FONT-SIZE-39: 3.9rem;
$FONT-SIZE-40: 4rem;
$FONT-SIZE-41: 4.1rem;
$FONT-SIZE-42: 4.2rem;
$FONT-SIZE-43: 4.3rem;
$FONT-SIZE-44: 4.4rem;
$FONT-SIZE-45: 4.5rem;
$FONT-SIZE-46: 4.6rem;
$FONT-SIZE-47: 4.7rem;
$FONT-SIZE-48: 4.8rem;
$FONT-SIZE-49: 4.9rem;
$FONT-SIZE-50: 5rem;
$FONT-SIZE-60: 6rem;
$FONT-SIZE-70: 7rem;
$FONT-SIZE-72: 7.2rem;
$FONT-SIZE-80: 8rem;
$FONT-SIZE-84: 8.4rem;
$FONT-SIZE-90: 9rem;
$FONT-SIZE-96: 9.6rem;

// media queries
//$breakpoint-mobile: 767px !default;
//$breakpoint-tablet: 768px !default;
//$breakpoint-pc: 1280px !default;
//'md': 'screen and (min-width: 768px) and (max-width: 1439px)',

$breakpoints: (
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (min-width: 768px) and (max-width: 999px)',
  'lg': 'screen and (min-width: 1000px)',
) !default;
@mixin mq($breakpoint: sm) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin replace ($original, $replacement) {
  @at-root #{selector-replace(&, $original, $replacement)} {
    @content;
  }
}
//@include replace(".text", ".card") {
//  color: orange;
//  text-decoration: none;
//}


@mixin reset {
  margin: 0;
  padding: 0;
}

@mixin element-base {
  color: #fff;
  height: auto;
  position: relative;
  margin: 0;
  padding: 0;
}





@mixin page-heading-title {
  color: #fff;
  font-size: 72px;
  //font-family: 'Montserrat';
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 0;

  @include mq(md) {
    font-size: 48px;
  }

  @include mq(sm) {
    font-size: 36px;
  }
}

@mixin contents-heading-title {
  color: $COLOR__FONT;
  font-size: $FONT-SIZE-40;
  font-weight: 500;
  letter-spacing: .25em;

  @include mq(md) {
  }

  @include mq(sm) {
    font-size: $FONT-SIZE-30;
    letter-spacing: .1em;
  }
}

@mixin section-heading-title {
  color: $COLOR__FONT;
  font-size: $FONT-SIZE-40;
  font-weight: 500;
  letter-spacing: .25em;

  @include mq(md) {
  }

  @include mq(sm) {
    font-size: $FONT-SIZE-30;
    letter-spacing: .1em;
  }
}

@mixin news-heading {
  color: $COLOR__FONT;
  font-size: $FONT-SIZE-24;
}

@mixin inner {
  width: 1000px;
  margin: 0 auto;
  text-align: left;

  @include mq(sm) {
    width: 100%;
  }
}

@mixin cf {

  &:before ,
  &:after {
    content: "";// micro clearfix hack
    display: table;// For modern browsers
  }

  &:after {
    clear: both;
  }

  zoom: 1;// For IE 6/7 (trigger hasLayout)
}
