@charset "UTF-8";
/*
* Project Name: ADEKA クリーンエイド株式会社
* Site URI:
* Description: ADEKA クリーンエイド株式会社 style
* Version: 1.0.0
* Author: ADEKA クリーンエイド株式会社
* CSS: thema.css
*/
.globalWrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 100px 0 0;
  position: relative;
  overflow-x: hidden;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .globalWrapper {
    min-width: 100%;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 767px) {
  .globalWrapper {
    min-width: 100%;
    padding: 60px 0 0;
  }
}

/* ==========================================================================

========================================================================== */
main {
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  zoom: 1;
}

main:before, main:after {
  content: "";
  display: table;
}

main:after {
  clear: both;
}

.primary {
  width: 100%;
  max-width: 960px;
  margin: 0 auto 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .primary {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .primary {
    width: 95%;
  }
}

/* ==========================================================================

========================================================================== */
.page-heading-title {
  color: #fff;
  font-size: 72px;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.83);
  text-align: left;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .page-heading-title {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  .page-heading-title {
    font-size: 36px;
  }
}

.page-heading-title + p {
  color: #fff;
  font-size: 2.4rem;
  font-weight: bold;
  text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.83);
  margin: 0;
  padding: 0;
  text-align: left;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .page-heading-title + p {
    font-size: 1.8rem;
  }
}

.contents-heading-title {
  color: #444444;
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: .25em;
  line-height: 1;
  margin: 0 0 40px;
}

@media screen and (max-width: 767px) {
  .contents-heading-title {
    font-size: 3rem;
    letter-spacing: .1em;
  }
}

@media screen and (max-width: 767px) {
  .contents-heading-title {
    margin: 0 0 20px;
  }
}

.contents-heading-title + p {
  clear: both;
  font-size: 1.8rem;
  line-height: 2;
  text-align: center;
}

.section-heading-title {
  color: #444444;
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: .25em;
  line-height: 1;
  margin: 0 0 40px;
}

@media screen and (max-width: 767px) {
  .section-heading-title {
    font-size: 3rem;
    letter-spacing: .1em;
  }
}

@media screen and (max-width: 767px) {
  .section-heading-title {
    margin: 0 0 40px;
  }
}

.section-heading-title + p {
  clear: both;
  font-size: 1.8rem;
}

.three-icon i {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70px;
  overflow: hidden;
  height: 30px;
  margin: 40px auto 0;
}

.three-icon i span {
  display: inline-block;
  width: 10px;
  height: 30px;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .three-icon i span {
    margin-top: 0;
  }
}

.three-icon i span:nth-of-type(1) {
  background-color: #0dadf1;
}

.three-icon i span:nth-of-type(2) {
  background-color: #0039ca;
}

.three-icon i span:nth-of-type(3) {
  background-color: #09c0a0;
}

.textblock {
  padding: 0 40px;
  text-align: left;
}

.textblock p {
  font-size: 1.8rem;
}

.leadtext {
  font-size: 1.8rem;
  margin-bottom: 40px;
  text-align: left;
}

.fluide {
  width: 100%;
}

.fluide--30 {
  width: 30%;
}

.fluide--40 {
  width: 40%;
}

.fluide--50 {
  width: 50%;
}

.fluide--60 {
  width: 60%;
}

.fluide--70 {
  width: 70%;
}

.frame {
  border: solid 1px #111;
}

/* ==========================================================================

========================================================================== */
.eyecatch {
  width: 100%;
  height: 320px;
  margin: 0;
  padding: 0;
  background-color: #eee;
}

@media screen and (max-width: 767px) {
  .eyecatch {
    width: 100%;
    height: 0;
    padding-top: 22.22222%;
  }
}

.bleadclumb {
  background-color: #eee;
  width: 100%;
  height: 24px;
  margin: 0 auto 80px;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
}

.bleadclumb.productsStyle {
  background-color: #fff;
  max-width: 1000px;
  margin: 5px auto 40px;
}

.bleadclumb.productsStyle ul {
  padding-left: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .bleadclumb.productsStyle ul {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .bleadclumb.productsStyle ul {
    padding-left: 20px;
  }
}

.bleadclumb.productsStyle ul li:first-child a {
  padding-left: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .bleadclumb {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .bleadclumb {
    width: 100%;
  }
}

.bleadclumb__list {
  width: 100%;
  height: 24px;
  line-height: 1;
  text-align: left;
  margin: 0 auto 40px;
  padding: 0 10px;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .bleadclumb__list {
    width: auto;
    max-width: none;
    line-height: 30px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
}

.bleadclumb__item {
  color: #444;
  margin: 0;
  padding: 0;
  height: 24px;
  line-height: 24px;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

.bleadclumb__item + li {
  padding-left: 2em;
}

.bleadclumb__item + li:before {
  content: "";
  border-top: solid 2px #999;
  border-right: solid 2px #999;
  width: 8px;
  height: 8px;
  line-height: 1;
  display: block;
  vertical-align: middle;
  transform: rotateZ(45deg);
  transform-origin: center;
  position: absolute;
  top: 8px;
  left: 0.5em;
}

.bleadclumb__item:last-child {
  padding: 0 1em 0 3em;
}

.bleadclumb__item a {
  width: 100%;
  height: 24px;
  line-height: 24px;
  padding: 0 1em;
  display: block;
  overflow-wrap: normal;
  overflow: hidden;
  white-space: nowrap;
}

.bleadclumb img {
  width: 16px;
  height: auto;
  margin: 4px 0 0;
}

/* ==========================================================================

========================================================================== */
.pageheader {
  width: 100%;
  height: 180px;
  background-color: #738088;
}

.pageheader .inner {
  max-width: calc(100% - 20px);
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.company .pageheader {
  background: url(/images/company/pageheader_company.jpg) right center no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .company .pageheader {
    background: url(/images/company/pageheader_company_sp.jpg) right center no-repeat;
    background-size: cover;
  }
}

.companyOverview .pageheader {
  background: url(/images/company/pageheader_company_overview.jpg) center no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .companyOverview .pageheader {
    background-position: 43%;
  }
}

.companyPhilosophy .pageheader {
  background: url(/images/company/pageheader_company_philosophy.jpg) center no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .companyPhilosophy .pageheader {
    background-position: 43%;
  }
}

.companyOffice .pageheader {
  background: url(/images/company/pageheader_company_office.jpg) center no-repeat;
  background-size: cover;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .companyOffice .pageheader {
    background-position: right center;
  }
}

@media screen and (max-width: 767px) {
  .companyOffice .pageheader {
    background-position: right center;
  }
}

.business .pageheader {
  background: url(/images/business/pageheader_business.jpg) center no-repeat;
  background-size: cover;
}

.businessService .pageheader {
  background: url(/images/business/pageheader_business_service.jpg) center no-repeat;
  background-size: cover;
}

.businessIndustry .pageheader {
  background: url(/images/business/pageheader_business_industry.jpg) center no-repeat;
  background-size: cover;
}

.businessAgency .pageheader {
  background: url(/images/business/pageheader_business_agency.jpg) right center no-repeat;
  background-size: cover;
}

.sanitation .pageheader {
  background: url(/images/sanitation/pageheader_sanitation.jpg) center no-repeat;
  background-size: cover;
}

.sanitationHACCP .pageheader {
  background: url(/images/sanitation/pageheader_sanitation_haccp.jpg) left center no-repeat;
  background-size: cover;
}

.sanitationQanda .pageheader {
  background: url(/images/sanitation/pageheader_sanitation_qanda.jpg) center no-repeat;
  background-size: cover;
}

.sanitationFood_poisoning .pageheader {
  background: url(/images/sanitation/pageheader_sanitation_foodpoisoning.jpg) center no-repeat;
  background-size: cover;
}

.sanitationCreanpedia .pageheader {
  background: url(/images/sanitation/pageheader_sanitation_creanpedia.jpg) center no-repeat;
  background-size: cover;
}

.pickup .pageheader {
  background: url(/images/pickup/pageheader_pickup.jpg) right center no-repeat;
  background-size: cover;
}

.recruit .pageheader {
  background: url(/images/recruit/pageheader_recruit.jpg) center no-repeat;
  background-size: cover;
}

.recruitSalesnclerk .pageheader {
  background: url(/images/recruit/pageheader_recruit_salesnclerk.jpg) center no-repeat;
  background-size: cover;
}

.recruitResearcher .pageheader {
  background: url(/images/recruit/pageheader_recruit_researcher.jpg) center no-repeat;
  background-size: cover;
}

.recruitEntry .pageheader {
  background: url(/images/recruit/pageheader_recruit.jpg) center no-repeat;
  background-size: cover;
}

.recruitConfirm .pageheader {
  background: url(/images/recruit/pageheader_recruit.jpg) center no-repeat;
  background-size: cover;
}

.recruitThanks .pageheader {
  background: url(/images/recruit/pageheader_recruit.jpg) center no-repeat;
  background-size: cover;
}

.faq .pageheader {
  background: url(/images/faq/pageheader_faq.jpg) center no-repeat;
  background-size: cover;
}

.faqCleanness .pageheader {
  background: url(/images/faq/pageheader_faq_cleanness.jpg) center no-repeat;
  background-size: cover;
}

.faqFoodfactory .pageheader {
  background: url(/images/faq/pageheader_faq_foodfactory.jpg) center no-repeat;
  background-size: cover;
}

.faqFirstaid .pageheader {
  background: url(/images/faq/pageheader_faq_firstaid.jpg) center no-repeat;
  background-size: cover;
}

.news .pageheader {
  background: url(/images/news/pageheader_news.jpg) center no-repeat;
  background-size: cover;
}

.privacypolicy .pageheader {
  background: url(/images/privacypolicy/pageheader_privacypolicy.jpg) center no-repeat;
  background-size: cover;
}

.inquiry .pageheader,
.form1 .pageheader,
.form2 .pageheader,
.form3 .pageheader,
.confirm .pageheader,
.thanks .pageheader {
  background: url(/images/inquiry/pageheader_inquiry.jpg) right center no-repeat;
  background-size: cover;
}

.sitemap .pageheader {
  background: url(/images/sitemap/pageheader_sitemap.jpg) center no-repeat;
  background-size: cover;
}

/* ==========================================================================

========================================================================== */
.table {
  width: 100%;
  position: relative;
  margin: 0 0 80px;
  clear: both;
}

.table--basic {
  width: 100%;
  max-width: 100%;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .table--basic {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .table--basic tbody {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .table--basic tr {
    width: 100%;
    display: block;
  }
}

.table--basic tr th {
  font-size: 1.8rem;
  font-weight: normal;
  width: 31.25%;
  padding: 20px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .table--basic tr th {
    width: 100%;
    display: block;
    background-color: #eee;
  }
}

.table--basic tr td {
  font-size: 1.8rem;
  width: 68.75%;
  padding: 20px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .table--basic tr td {
    width: 100%;
    display: block;
    padding: 20px 20px 40px;
  }
}

.table--basic tr + tr th,
.table--basic tr + tr td {
  border-top: solid 1px #ccc;
}

.table--basic tr:first-child th {
  border-bottom: solid 1px #ccc;
}

.table--basic .contact-address {
  display: block;
  line-height: 2;
}

.table--basic .label--tel {
  color: #fff;
  background-color: #07ace9;
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 1.4rem;
  line-height: 1;
  margin: 0 .5em 0 0;
  position: relative;
  top: -1px;
}

.table--basic .label--fax {
  color: #fff;
  background-color: #07ace9;
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 1.4rem;
  line-height: 1;
  margin: 0 .5em 0 2em;
  position: relative;
  top: -1px;
}

@media screen and (max-width: 767px) {
  .table--basic .label--fax {
    margin: 0 .5em 0 0;
  }
}

.table a {
  color: #444444;
}

.table-shadow {
  width: 31.25%;
  height: calc(100% + 20px);
  display: block;
  background: #eee;
  position: absolute;
  top: -10px;
  left: 0;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  .table-shadow {
    display: none;
  }
}

.defaultTable {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 80px;
  border-bottom: solid 1px #ccc;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .defaultTable {
    margin: 0 auto 40px;
  }
}

@media screen and (max-width: 767px) {
  .defaultTable {
    margin: 0 auto 40px;
  }
}

@media screen and (max-width: 767px) {
  .defaultTable tbody {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .defaultTable tr {
    display: block;
    width: 100%;
  }
}

.defaultTable th {
  width: 20%;
  font-size: 1.8rem;
  border-top: solid 1px #ccc;
  text-align: left;
  padding: 11px 10px;
}

@media screen and (max-width: 767px) {
  .defaultTable th {
    display: block;
    width: 100%;
    background-color: #f6f6f6;
  }
}

.defaultTable td {
  text-align: left;
  font-size: 1.8rem;
  border-top: solid 1px #ccc;
  padding: 11px 10px;
}

@media screen and (max-width: 767px) {
  .defaultTable td {
    display: block;
    width: 100%;
    border-top: none;
  }
}

.defaultTable a {
  color: #07ace9;
}

.defaultTable a i.fa {
  color: #ccc;
}

/* ==========================================================================

========================================================================== */
.l-localnavi {
  width: 100%;
  background-color: #f6f6f6;
  margin: 0 0 10px;
  padding: 20px 0;
}

.l-localnavi__wrapper {
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-localnavi__wrapper {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-localnavi__wrapper {
    display: block;
  }
}

.l-localnavi__category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100px;
  height: 60px;
  background-color: #f6f6f6;
  margin: 0;
  padding: 0 0 0 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-localnavi__category {
    height: 100%;
    margin: 0 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-localnavi__category {
    height: 100%;
    margin: 0 0 20px;
  }
}

.l-localnavi__category h4 {
  margin: 0;
  padding: 0;
  color: #444;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: .5em;
}

@media screen and (max-width: 767px) {
  .l-localnavi__category h4 {
    font-size: 2rem;
  }
}

.l-localnavi__category p {
  color: #999;
}

@media screen and (min-width: 1000px) {
  .l-localnavi__category:hover h4 {
    color: #07ace9;
  }
  .l-localnavi__category:hover p {
    color: #07ace9;
  }
}

.l-localnavi h5 {
  margin: 0;
  padding: 0;
  align-items: center;
  text-align: left;
  font-size: 1.3rem;
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: .5em;
}

.l-localnavi h5.fit {
  font-size: 1.1rem;
}

@media screen and (max-width: 767px) {
  .l-localnavi h5.fit {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .l-localnavi h5 {
    font-size: 1.8rem;
    margin: 10px 0 5px;
  }
}

.l-localnavi p {
  margin: 0;
  padding: 0;
  align-items: center;
  text-align: left;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .l-localnavi p {
    font-size: 1.5rem;
  }
}

.l-localnavi img {
  width: 96px;
  height: 48px;
  margin-right: 10px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-localnavi img {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 767px) {
  .l-localnavi img {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 6px;
  }
}

.l-localnavi__list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0 0 0 10px;
  width: 870px;
  height: auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-localnavi__list {
    margin: 0 20px 0 10px;
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 767px) {
  .l-localnavi__list {
    margin: 0 10px 0 10px;
    width: calc(100% - 20px);
    display: block;
  }
}

.l-localnavi__item {
  flex-basis: 210px;
  height: 100%;
  background-color: #fff;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-localnavi__item {
    flex-basis: calc(100% - 50px / 4);
  }
}

@media screen and (max-width: 767px) {
  .l-localnavi__item {
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .l-localnavi__item:hover {
    box-shadow: 4px 0 16px rgba(0, 0, 0, 0.2);
    transition: box-shadow .5s ease 0s;
  }
}

.l-localnavi__item + li {
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .l-localnavi__item + li {
    margin: 10px 0 0;
  }
}

.l-localnavi__item a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px;
  flex-basis: 210px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-localnavi__item a {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .l-localnavi__item a {
    padding: 10px;
    display: block;
  }
}

.l-localnavi__item a h5 {
  color: #999;
}

.l-localnavi__item a p {
  color: #ccc;
}

@media screen and (min-width: 1000px) {
  .l-localnavi__item a:hover h5 {
    color: #07ace9;
  }
  .l-localnavi__item a:hover p {
    color: #07ace9;
  }
}

.l-localnavi__item a img {
  height: 50%;
}

.l-localnavi .menu-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* =========================================================
HERO AREA
========================================================= */
.l-slide {
  width: 100%;
  background-color: #eee;
  position: relative;
  display: flex;
}

.l-slide .manifest {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 640px;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 1;
  font-size: 90px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  letter-spacing: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-slide .manifest {
    font-size: 60px;
    text-shadow: 0 2px 16px rgba(0, 0, 0, 0.6);
  }
}

@media screen and (max-width: 767px) {
  .l-slide .manifest {
    font-size: 24px;
    text-shadow: 0 2px 16px rgba(0, 0, 0, 0.7);
  }
}

.l-slide .manifest.enscript {
  line-height: 1;
}

.l-slide .manifest span {
  display: block;
  line-height: 1;
}

.l-slide .manifest span:nth-of-type(1) {
  width: 407px;
  height: 64px;
  margin: 0 auto .25em;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-slide .manifest span:nth-of-type(1) {
    width: 273px;
    height: 43px;
  }
}

@media screen and (max-width: 767px) {
  .l-slide .manifest span:nth-of-type(1) {
    margin: 0 auto;
    width: 180px;
    height: 28px;
  }
}

.l-slide .manifest span:nth-of-type(2) {
  font-size: 72px;
  letter-spacing: .15em;
  font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-slide .manifest span:nth-of-type(2) {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  .l-slide .manifest span:nth-of-type(2) {
    font-size: 24px;
  }
}

/* =========================================================
section
========================================================= */
.l-message {
  padding: 80px 0 30vw;
  background: url(/images/home/water_bg.png) center bottom no-repeat;
  background-size: 100%;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-message {
    width: 100%;
    margin: 0 auto;
    padding: 80px 5% 30vw;
  }
}

@media screen and (max-width: 767px) {
  .l-message {
    width: 100%;
    margin: 0 auto;
    padding: 40px 5% 33vw;
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .l-message h2 {
    line-height: 1.5;
  }
}

@media screen and (max-width: 767px) {
  .l-message p {
    text-align: left;
  }
}

/* =========================================================
section
========================================================= */
.l-search {
  margin: -210px 0 0;
  padding: 80px 0;
  background: url(/images/home/bg.png) center bottom repeat-x;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-search {
    margin: 0;
    padding: 80px 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .l-search {
    margin: 0;
    padding: 60px 0 60px;
  }
}

.l-search .wrapper {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-search .wrapper {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 767px) {
  .l-search .wrapper {
    width: calc(100% - 20px);
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .l-search .left {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .l-search .left .buttonGroup__item {
    width: 32%;
  }
}

.l-search .left .buttonGroup__item + li {
  margin-top: 30px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-search .left .buttonGroup__item + li {
    margin-top: 16px;
  }
}

@media screen and (max-width: 767px) {
  .l-search .left .buttonGroup__item + li {
    margin-top: 0;
  }
}

.l-search .left .buttonGroup__item a {
  padding: 15px 0;
}

@media screen and (max-width: 767px) {
  .l-search .left .buttonGroup__item a {
    padding: 0 0 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-search .right .buttonGroup__item {
    width: 48.5%;
    margin-bottom: 10px;
  }
}

.l-search .right .buttonGroup__item + li {
  margin-top: 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-search .right .buttonGroup__item + li {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-search .right .buttonGroup__item + li {
    margin-top: 0;
  }
}

.l-search .buttonGroup {
  width: 48%;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-search .buttonGroup {
    width: calc((100% - 10px)/2);
  }
}

@media screen and (max-width: 767px) {
  .l-search .buttonGroup {
    width: 100%;
  }
}

.l-search .buttonGroup__title {
  margin: 0 0 30px;
}

.l-search .buttonGroup__title span {
  display: block;
  line-height: 1;
}

.l-search .buttonGroup__title span:nth-of-type(1) {
  font-size: 1.8rem;
  font-weight: 400;
}

.l-search .buttonGroup__title span:nth-of-type(2) {
  font-size: 3rem;
  margin: 10px 0;
  font-weight: 500;
  letter-spacing: .25em;
}

.l-search .buttonGroup__title span:nth-of-type(3) {
  font-size: 1.8rem;
  color: #999;
  letter-spacing: .25em;
}

.l-search .buttonGroup__list {
  width: 100%;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .l-search .buttonGroup__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }
}

.l-search .buttonGroup__item {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .l-search .buttonGroup__item {
    display: flex;
  }
}

.l-search .buttonGroup__item a {
  width: 100%;
  height: 100%;
  background-color: #07ace9;
  border-radius: 3px;
  color: #fff;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  font-size: 2.4rem;
  overflow: hidden;
  position: relative;
}

.l-search .buttonGroup__item a:after {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 2.4rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .5em);
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .l-search .buttonGroup__item a:after {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-search .buttonGroup__item a {
    font-size: 1.8rem;
    padding: 4px 0;
    word-break: keep-all;
  }
}

@media screen and (max-width: 767px) {
  .l-search .buttonGroup__item a {
    flex-direction: column;
    font-size: 1.4rem;
    width: 100%;
    padding: 0 0 10px;
    text-align: center;
  }
}

.l-search .buttonGroup__item img {
  width: 120px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-search .buttonGroup__item img {
    width: 80px;
    height: auto;
  }
}

/* =========================================================
section
========================================================= */
.l-works {
  padding: 80px 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-works {
    padding: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .l-works {
    padding: 60px 0;
  }
}

.l-works__board {
  background-color: #dfedf7;
  width: 50%;
  padding: 60px;
  text-align: left;
  transform: translate3d(20px, 20px, 0);
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-works__board {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-works__board {
    width: calc(100% - 10px);
    padding: 50px 60px 50px 50px;
  }
}

.l-works__board h4 {
  font-size: 2.4rem;
  letter-spacing: .5em;
  line-height: 1;
  color: #07ace9;
  margin: 0 0 25px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-works__board h4 {
    margin: 0 0 10px;
  }
}

.l-works__board p {
  font-size: 1.6rem;
  text-align: left;
  margin: 0 0 1em;
}

.l-works .container {
  width: calc(100% - 40px);
  margin: 0 20px 0;
}

@media screen and (min-width: 1000px) {
  .l-works .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-works .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }
}

.l-works .container::after {
  content: "";
  display: block;
}

.l-works .container.x2 a {
  height: auto;
  text-align: left;
  position: relative;
  display: block;
}

@media screen and (min-width: 1000px) {
  .l-works .container.x2 a {
    width: calc(50% - 10px);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-works .container.x2 a {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 767px) {
  .l-works .container.x2 a {
    height: 0;
    padding-top: 33%;
    overflow: hidden;
    position: relative;
  }
}

.l-works .container.x2 a + a {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .l-works .container.x2 a + a {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-works .container.x2 a figure {
    width: 100%;
    position: absolute;
    top: -25%;
    left: 0;
    z-index: 0;
  }
}

.l-works .container.x2 a .container__label {
  color: #fff;
  padding: 25px 20px;
  position: absolute;
  width: 30.1vw;
  height: 11.11111vw;
  min-width: 300px;
  min-height: 100px;
  top: calc(50% - 5.5vw);
  right: 0;
  background-color: rgba(255, 255, 255, 0.83);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-works .container.x2 a .container__label {
    top: calc(50% - 50px);
  }
}

@media screen and (max-width: 767px) {
  .l-works .container.x2 a .container__label {
    min-width: 190px;
    top: calc(50% - 30px);
    padding: 10px;
    min-height: 60px;
  }
}

.l-works .container.x2 a .container__label h4 {
  font-size: calc(3.75em + 24 * (100vw - 1000px) / 1000);
  line-height: 1;
  margin: 0;
  padding: 0;
  margin-bottom: .5em;
  color: #444444;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-works .container.x2 a .container__label h4 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .l-works .container.x2 a .container__label h4 {
    font-size: 1.6rem;
    margin-bottom: .25em;
  }
}

.l-works .container.x2 a .container__label p {
  font-size: calc(2.5em + 15 * (100vw - 1000px) / 1000);
  line-height: 1;
  margin: 0;
  padding: 0;
  color: #999;
  letter-spacing: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-works .container.x2 a .container__label p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .l-works .container.x2 a .container__label p {
    font-size: 1.2rem;
  }
}

.l-works .container.x2 a .container__label:after {
  content: "";
  display: block;
  width: calc(100% - 10px);
  min-width: 180px;
  height: 5px;
  position: absolute;
  bottom: -5px;
  right: 0;
}

.l-works .container.x2 a:nth-of-type(1) .container__label:after {
  background-color: rgba(13, 173, 241, 0.83);
}

.l-works .container.x2 a:nth-of-type(2) .container__label:after {
  background-color: rgba(0, 57, 202, 0.83);
}

.l-works .container.x2 a:nth-of-type(3) .container__label:after {
  background-color: rgba(9, 192, 160, 0.83);
}

/* =========================================================
section
========================================================= */
.l-contents {
  background-color: #dbe6ef;
  padding: 100px 0 80px;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-contents {
    padding: 100px 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .l-contents {
    padding: 60px 0 40px;
  }
}

.l-contents .container {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 1000px) {
  .l-contents .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-contents .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  .l-contents .container {
    display: block;
    width: calc(100% - 20px);
    margin: 0 auto;
  }
}

.l-contents .container::after {
  content: "";
  display: block;
}

.l-contents .container.x3 figure {
  width: 100%;
  height: auto;
  position: relative;
}

.l-contents .container.x3 figure span {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 100;
  text-align: left;
  font-size: 2rem;
  font-size: calc(2.8em + 20 * (100vw - 1000px) / 1000);
  text-shadow: 1px 0px 6px white, 1px 0px 6px white, 1px 0px 6px white, 1px 0px 6px white;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-contents .container.x3 figure span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .l-contents .container.x3 figure span {
    font-size: 1.1rem;
    display: none;
  }
}

.l-contents .container.x3 a {
  height: auto;
  display: block;
  text-align: right;
}

.l-contents .container.x3 a::after {
  flex-basis: 33.33333%;
}

@media screen and (min-width: 1000px) {
  .l-contents .container.x3 a {
    flex-basis: 33.33333%;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-contents .container.x3 a {
    flex-basis: 33.33333%;
  }
}

@media screen and (max-width: 767px) {
  .l-contents .container.x3 a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .l-contents .container.x3 a + a {
    margin-top: 20px;
  }
}

.l-contents .container.x3 a:nth-of-type(1) {
  background-color: #0dadf1;
}

.l-contents .container.x3 a:nth-of-type(2) {
  background-color: #0039ca;
}

.l-contents .container.x3 a:nth-of-type(3) {
  background-color: #09c0a0;
}

.l-contents .container__label {
  color: #fff;
  padding: 22px;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .l-contents .container__label {
    text-align: left;
    height: 100%;
    padding: 10px;
    display: block;
  }
}

.l-contents .container__label h4 {
  font-size: 2.4rem;
  line-height: 1;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
}

@media screen and (max-width: 767px) {
  .l-contents .container__label h4 {
    font-size: 1.5rem;
  }
}

.l-contents .container__label p {
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  padding: 0;
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  .l-contents .container__label p {
    font-size: 1.3rem;
  }
}

/* =========================================================
section
========================================================= */
.l-topics {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 100px 0 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-topics {
    width: calc(100% - 20px);
    padding: 100px 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .l-topics {
    width: calc(100% - 20px);
    padding: 60px 0 40px;
  }
}

.l-topics__list {
  clear: both;
  margin: 0 0 0;
}

.l-topics__item {
  text-align: left;
  min-height: 60px;
  border-bottom: solid 1px #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 50px;
  font-size: 1.6rem;
}

.l-topics__item:first-child {
  border-top: solid 1px #ddd;
}

@media screen and (max-width: 767px) {
  .l-topics__item {
    padding: 18px 0;
    flex-wrap: wrap;
  }
}

.l-topics__date {
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: -.025em;
  min-width: 5em;
}

@media screen and (max-width: 767px) {
  .l-topics__date {
    display: block;
    margin-left: 10px;
  }
}

.l-topics__label {
  display: inline-block;
  text-align: center;
  min-width: 120px;
  padding: 7px 10px;
  line-height: 1;
  font-size: 1.2rem;
  letter-spacing: -.01em;
  margin: 0 20px 0;
}

.l-topics__description {
  display: block;
  font-size: 1.6rem;
  margin: 0 0 0 10px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .l-topics__description {
    margin: 0;
    margin-top: calc(18px - .25em);
    padding: 0 10px;
    flex-basis: 100%;
  }
}

.l-topics .button {
  width: 200px;
  height: 40px;
  background-color: #07ace9;
  border-radius: 3px;
  color: #fff;
  line-height: 40px;
  margin: 20px;
  position: relative;
}

.l-topics .button:after {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 1.8rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .5em);
  line-height: 1;
}

@media screen and (min-width: 1000px) {
  .l-topics .button:hover {
    background-color: #0687b7;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-topics .button:hover {
    background-color: #07ace9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (max-width: 767px) {
  .l-topics .button {
    background-color: #07ace9;
  }
}

/* =========================================================
section
========================================================= */
.l-linkbnr {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 80px;
  padding: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-linkbnr {
    width: calc(100% - 20px);
    margin: 0 auto 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-linkbnr {
    width: calc(100% - 20px);
    margin: 0 auto 40px;
  }
}

.l-linkbnr ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.l-linkbnr li {
  width: 32%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: solid 1px #444444;
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-linkbnr li {
    width: 48%;
    min-height: 50px;
    flex-flow: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.l-linkbnr a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  font-size: 1.6rem;
  width: 100%;
  height: 100%;
  min-height: 10px;
  z-index: 1;
}

.l-linkbnr a:hover {
  color: #444444;
}

.l-linkbnr span {
  font-size: 1rem;
  text-align: left;
}

/* =========================================================
section
========================================================= */
.searchheader .inner {
  max-width: calc(100% - 20px);
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.searchheader h1 {
  font-size: 2.4rem;
  max-width: 1000px;
  margin: 20px auto;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .searchheader h1 {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .searchheader h1 {
    width: 95%;
  }
}

.searchheader {
  background-color: #fff;
  height: auto;
}

.searchheader .inner {
  max-width: 1000px;
  display: block;
}

.searchheader .category {
  float: right;
  margin: 15px 0;
}

.searchheader .category li {
  display: inline-block;
}

.searchheader .category li + li {
  margin-left: 20px;
}

.searchheader .category a {
  font-size: 1.4rem;
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  color: #07ace9;
}

.searchheader .searchBlock {
  clear: both;
}

.searchheader .freeword {
  background-color: #07ace9;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  margin: 0;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .searchheader .freeword {
    flex-wrap: wrap;
  }
}

.searchheader .freeword p {
  margin: 0 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .searchheader .freeword p {
    width: 20%;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .searchheader .freeword p {
    width: 100%;
    text-align: left;
    flex-direction: column;
  }
}

.searchheader .freeword__entry {
  position: relative;
  width: 560px;
  margin-right: 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .searchheader .freeword__entry {
    width: 70%;
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .searchheader .freeword__entry {
    width: 100%;
    margin: 10px 20px;
  }
}

.searchheader .freeword__entry label {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(/images/common/icon/search.png);
  background-size: 20px;
  position: absolute;
  top: calc( 50% - 10px);
  left: 10px;
  z-index: 1;
}

.searchheader .freeword__entry input {
  padding: 9px 90px 9px 40px;
  background-color: #fff;
  background-position: 11px center;
  font-size: 1.8rem;
  line-height: 1;
  width: 100%;
  border-radius: 3px;
  color: #444444;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .searchheader .freeword__entry input {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .searchheader .freeword__entry input {
    width: 100%;
    padding: 9px 60px 9px 40px;
  }
}

.searchheader .freeword__entry input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
}

.searchheader .freeword__entry button {
  position: absolute;
  top: 2px;
  right: 2px;
  line-height: 36px;
  padding: 0 25px;
  background-color: #07ace9;
  border-radius: 3px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .searchheader .freeword__entry button {
    padding: 0 10px;
  }
}

.searchheader .freeword__setting {
  font-size: 1.4rem;
  margin-left: 20px;
  display: none;
}

.searchheader .freeword__setting label {
  display: block;
  text-align: left;
  padding: 0 0 0 30px;
  cursor: pointer;
  line-height: 22px;
  position: relative;
}

.searchheader .freeword__setting label:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 9px;
  background-color: #fff;
  position: absolute;
  top: 3px;
  left: 0;
}

.searchheader .freeword__setting input[type=radio] {
  display: none;
}

.searchheader .freeword__setting input[type=radio]:checked + label:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #07ace9;
  position: absolute;
  top: 7px;
  left: 4px;
  z-index: 10;
}

.searchheader .freeword__setting input[type=radio] + label:after {
  display: none;
}

.searchheader .jpSyllabary {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  border-bottom: solid 1px #0dadf1;
  padding: 5px 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .searchheader .jpSyllabary {
    padding: 5px 2.5%;
  }
}

@media screen and (max-width: 767px) {
  .searchheader .jpSyllabary {
    padding: 5px 2.5%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
}

.searchheader .jpSyllabary ul {
  margin-left: 90px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .searchheader .jpSyllabary ul {
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  .searchheader .jpSyllabary ul {
    margin-left: 5%;
    padding-right: 5%;
  }
}

.searchheader .jpSyllabary li {
  display: inline-block;
}

.searchheader .jpSyllabary li + li {
  margin-left: 15px;
}

.searchheader .jpSyllabary a {
  color: #07ace9;
  padding: 10px;
}

.l-products {
  margin: 0;
}

.l-productsMenu {
  margin: 0 0 10px;
  padding-bottom: 80px;
  background: url(/images/products/products_repeat_bg.png) center bottom repeat-x;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu {
    margin: 0 0 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu {
    margin: 0 0 10px;
  }
}

.l-productsMenu__list {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu__list {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu__list {
    flex-direction: column;
    max-width: 95%;
  }
}

.l-productsMenu__item {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0 0 40px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu__item {
    margin: 0 0 4%;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu__item {
    margin: 0 0 2.5%;
  }
}

.l-productsMenu__label {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  letter-spacing: -.015em;
}

.l-productsMenu__label h3 {
  display: inline-block;
  line-height: 1;
  margin: 0 10px -.2em 0;
  font-size: 4.8rem;
  font-weight: bold;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu__label h3 {
    font-size: calc(4.8em + 12 * (100vw - 768px) / 232);
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu__label h3 {
    font-size: calc(4.8em + 48 * (100vw - 320px) / 448);
  }
}

.l-productsMenu__label p {
  display: inline-block;
  line-height: 1;
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  position: relative;
  bottom: -.2em;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu__label p {
    font-size: calc(1.8em + 6 * (100vw - 768px) / 232);
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu__label p {
    font-size: calc(1.8em + 18 * (100vw - 320px) / 448);
  }
}

.l-productsMenu .wrapper {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu .wrapper {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu .wrapper {
    width: calc(100% - 20px);
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu .left {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu .left .buttonGroup__item {
    width: 32%;
  }
}

.l-productsMenu .left .buttonGroup__item + li {
  margin-top: 30px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu .left .buttonGroup__item + li {
    margin-top: 16px;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu .left .buttonGroup__item + li {
    margin-top: 0;
  }
}

.l-productsMenu .left .buttonGroup__item a {
  padding: 15px 0;
}

@media screen and (max-width: 767px) {
  .l-productsMenu .left .buttonGroup__item a {
    padding: 0 0 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu .right .buttonGroup__item {
    width: 48.5%;
    margin-bottom: 10px;
  }
}

.l-productsMenu .right .buttonGroup__item + li {
  margin-top: 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu .right .buttonGroup__item + li {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu .right .buttonGroup__item + li {
    margin-top: 0;
  }
}

.l-productsMenu .buttonGroup {
  width: 48%;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu .buttonGroup {
    width: calc((100% - 10px)/2);
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu .buttonGroup {
    width: 100%;
    height: 100%;
  }
}

.l-productsMenu .buttonGroup__title {
  margin: 0 0 30px;
}

.l-productsMenu .buttonGroup__title span {
  display: block;
  line-height: 1;
}

.l-productsMenu .buttonGroup__title span:nth-of-type(1) {
  font-size: 1.8rem;
  font-weight: 400;
}

.l-productsMenu .buttonGroup__title span:nth-of-type(2) {
  font-size: 3rem;
  margin: 10px 0;
  font-weight: 500;
  letter-spacing: .25em;
}

.l-productsMenu .buttonGroup__title span:nth-of-type(3) {
  font-size: 1.8rem;
  color: #999;
  letter-spacing: .25em;
}

.l-productsMenu .buttonGroup__list {
  width: 90%;
  margin: 0 auto;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .l-productsMenu .buttonGroup__list {
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }
}

.l-productsMenu .buttonGroup__item {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .l-productsMenu .buttonGroup__item {
    display: flex;
  }
}

.l-productsMenu .buttonGroup__item a {
  border-radius: 3px;
  background-color: #07ace9;
  color: #fff;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.4rem;
  overflow: hidden;
  position: relative;
}

.l-productsMenu .buttonGroup__item a:after {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 2.4rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .5em);
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .l-productsMenu .buttonGroup__item a:after {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu .buttonGroup__item a {
    font-size: 1.8rem;
    padding: 4px 0;
    word-break: keep-all;
  }
}

@media screen and (max-width: 767px) {
  .l-productsMenu .buttonGroup__item a {
    flex-direction: column;
    font-size: 1.4rem;
    width: 100%;
    padding: 0 0 10px;
    text-align: center;
  }
}

.l-productsMenu .buttonGroup__item img {
  width: 120px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsMenu .buttonGroup__item img {
    width: 80px;
    height: auto;
  }
}

/* ------------------------------------------------------------
Detail
------------------------------------------------------------ */
.l-productsDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsDetail {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .l-productsDetail {
    flex-direction: column;
  }
}

.refineSearch {
  width: 200px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .refineSearch {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch {
    width: 95%;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    display: none;
  }
}

.refineSearch p {
  font-size: 1.5rem;
  font-weight: bold;
  position: relative;
  padding-right: calc(20px + 1em);
}

@media screen and (max-width: 767px) {
  .refineSearch p {
    border-top: solid 1px #ccc;
    margin: 0;
    padding: 15px calc(20px + 1em) 15px 0;
  }
}

.refineSearch p .fa-chevron-right {
  position: absolute;
  top: calc(50% - (.5em + 10px));
  right: 2%;
  color: #999;
  display: none;
}

@media screen and (max-width: 767px) {
  .refineSearch p .fa-chevron-right {
    top: calc(50% - (.5em + 10px));
    transform: rotate(90deg);
    padding: 10px;
    display: inline-block;
  }
}

.refineSearch__foodService {
  margin-bottom: 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .refineSearch__foodService {
    width: 48%;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch__foodService {
    width: 100%;
    margin-bottom: 0;
  }
}

.refineSearch__foodService ul {
  border-top: solid 1px #ccc;
}

@media screen and (max-width: 767px) {
  .refineSearch__foodService ul {
    display: none;
    margin: 0;
    background-color: #f9f9f9;
  }
}

.refineSearch__foodIndustry {
  margin-bottom: 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .refineSearch__foodIndustry {
    width: 48%;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch__foodIndustry {
    width: 100%;
    margin-bottom: 40px;
    margin-top: -1px;
  }
}

.refineSearch__foodIndustry ul {
  border-top: solid 1px #ccc;
}

@media screen and (max-width: 767px) {
  .refineSearch__foodIndustry ul {
    display: none;
    margin: 0;
    background-color: #f9f9f9;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch__foodIndustry p {
    border-bottom: solid 1px #ccc;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch__foodIndustry p.active {
    border-bottom: none;
  }
}

.refineSearch__case {
  width: 100%;
}

.refineSearch__item {
  display: block;
  width: 100%;
  line-height: 50px;
  border-bottom: solid 1px #ccc;
  font-size: 1.3rem;
  position: relative;
}

@media screen and (min-width: 1000px) {
  .refineSearch__item:hover {
    background-color: #eee;
  }
}

.refineSearch__item > a {
  display: block;
  padding: 0 0 0 10px;
  position: relative;
}

.refineSearch__item > a:after {
  content: "\f054";
  font-family: "fontAwesome";
  display: inline-block;
  position: absolute;
  right: 2%;
  color: #999;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .refineSearch__item > a:after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch__item > a:after {
    display: none;
  }
}

.refineSearch__item > a:hover {
  background-color: #eee;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .refineSearch__item > a:hover {
    background-color: transparent;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch__item > a:hover {
    background-color: transparent;
  }
}

.refineSearch__item ul {
  width: 200px;
  display: none;
  position: absolute;
  top: -1px;
  left: 200px;
  z-index: 100;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .refineSearch__item ul {
    position: static;
    width: 100%;
    box-shadow: none;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch__item ul {
    position: static;
    width: 100%;
    box-shadow: none;
    background-color: #eee;
  }
}

.refineSearch__item ul a {
  line-height: 30px;
  padding: 0 0 0 10px;
  display: block;
}

@media screen and (min-width: 1000px) {
  .refineSearch__item ul a:hover {
    background-color: #eee;
    background-image: url(/images/common/arrow.png);
    background-position: 95% center;
    background-repeat: no-repeat;
    background-size: 5px;
  }
}

.refineSearch__item .fa-chevron-right {
  display: inline-block;
  position: absolute;
  top: calc(50% - .5em);
  right: 2%;
  color: #999;
}

@media screen and (min-width: 1000px) {
  .refineSearch__item .fa-chevron-right {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .refineSearch__item .fa-chevron-right {
    top: calc(1em - .5em);
    transform: rotate(90deg);
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .refineSearch__item .fa-chevron-right {
    top: calc(1em - .5em);
    transform: rotate(90deg);
    padding: 10px;
  }
}

.productsMain {
  width: 780px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .productsMain {
    width: 95%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .productsMain {
    width: 95%;
    margin: 0 auto;
  }
}

.productsMain h1 {
  width: 100%;
  font-size: 2.4rem;
  border-bottom: solid 2px #ccc;
  padding: 0 0 10px;
  color: #07ace9;
  position: relative;
}

.productsMain h1[id^="category"] {
  border-top: solid 4px #0039ca;
  border-bottom: solid 1px #ccc;
  padding: 15px 10px;
  margin-bottom: 10px;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
  color: #444444;
  color: #0039ca;
}

.productsMain h1 button {
  position: relative;
  top: -4px;
  background-color: rgba(153, 153, 153, 0.2);
  border-radius: 16px;
  width: 110px;
  height: 32px;
  cursor: pointer;
  outline: none;
  z-index: 10;
  font-size: 1.2rem;
  color: #999;
}

.productsMain h1 button i {
  color: #999;
  font-size: 1.8rem;
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.productsMain h1 button.active {
  color: #f0c74d;
  background-color: rgba(240, 199, 77, 0.2);
}

.productsMain h1 button.active i {
  color: #f0c74d;
}

.productsMain h2 {
  font-size: 2.4rem;
  background: url(/images/products/three.png) left bottom no-repeat;
  position: relative;
  padding: 0 0 10px;
}

.productsMain h2:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 90px;
  border-bottom: solid 2px #ccc;
  width: calc(100% - 90px);
}

.productsMain .productMainBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .productsMain .productMainBody {
    flex-direction: column;
  }
}

.productsMain .productMainBody figure {
  width: 360px;
  min-height: 360px;
  max-height: 360px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  position: relative;
  margin: 0 0 10px;
}

@media screen and (max-width: 767px) {
  .productsMain .productMainBody figure {
    width: 100%;
    min-height: 240px;
    align-items: center;
  }
}

.productsMain .productMainBody figure img {
  max-width: 360px;
  max-height: 360px;
  width: auto;
  height: auto;
  position: relative;
}

@media screen and (max-width: 767px) {
  .productsMain .productMainBody figure img {
    max-width: 240px;
    max-height: 240px;
  }
}

.productsMain .productData {
  width: 400px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .productsMain .productData {
    width: 50%;
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .productsMain .productData {
    width: 100%;
  }
}

.productsMain .labellist {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 1.3rem;
}

.productsMain .labellist span {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 1px;
}

.productsMain .labellist span.type1 {
  background-color: #0dadf1;
}

.productsMain .labellist span.type2 {
  background-color: #09c0a0;
}

.productsMain .productDescription {
  font-size: 1.8rem;
  margin: 10px 0 20px;
}

.productsMain .productFeature {
  margin-bottom: 40px;
}

.productsMain .productFeature p {
  font-size: 1.6rem;
}

.productsMain .productDocument ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
}

.productsMain .productDocument ul:after {
  display: block;
  content: "";
  width: 32%;
  margin-left: 2%;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .productsMain .productDocument ul:after {
    width: 48%;
  }
}

@media screen and (max-width: 767px) {
  .productsMain .productDocument ul:after {
    width: 100%;
  }
}

.productsMain .productDocument li {
  width: 32%;
  height: 60px;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .productsMain .productDocument li {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .productsMain .productDocument li {
    width: 100%;
    margin-bottom: 10px;
  }
}

.productsMain .productDocument li + li {
  margin-left: auto;
}

@media screen and (min-width: 1000px) {
  .productsMain .productDocument li:nth-of-type(3n+1) {
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .productsMain .productDocument li:nth-of-type(2n+1) {
    margin-left: 0;
  }
}

.productsMain .productDocument li a {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  line-height: 1;
  color: #fff;
  font-size: 1.6rem;
  text-align: left;
  padding: 0 0 0 20px;
  margin: 0;
}

@media screen and (min-width: 1000px) {
  .productsMain .productDocument li a:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }
}

.productsMain .productDocument li.type1 a {
  background-color: #09c0a0;
}

.productsMain .productDocument li.type2 a {
  background-color: #456070;
}

.productsMain .productDocument li.type3 a {
  background-color: #4f6fbf;
}

.productsMain .productDocument li i {
  margin-right: 10px;
  line-height: 1;
}

.productsMain .productDocument li.prohibited a {
  background-image: url(/images/products/prohibited.png);
  background-position: right 10px center;
  background-size: 66px;
  padding-right: 80px;
}

.productsMain .productDocument span {
  font-size: 1.2rem;
  display: inline-block;
  width: 100%;
  line-height: 1;
  margin: -1.5em 0 0;
  padding: 0;
}

.productsMain .productTable {
  width: 100%;
}

.productsMain .productTable th {
  width: 120px;
  font-size: 1.4rem;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .productsMain .productTable th {
    width: 100%;
  }
}

.productsMain .productTable td {
  font-size: 1.4rem;
  vertical-align: middle;
  width: auto;
}

.productsMain .productTable td img {
  display: none;
  width: 40px;
  height: auto;
}

/* ------------------------------------------------------------
Type
------------------------------------------------------------ */
.l-productsType {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsType {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .l-productsType {
    flex-direction: column;
  }
}

.categoryList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-flow: wrap;
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryList {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  .categoryList {
    margin-bottom: 40px;
  }
}

.categoryList li {
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryList li {
    width: 48%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .categoryList li {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.categoryList li + li {
  margin-left: 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryList li + li {
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  .categoryList li + li {
    margin-left: 0;
  }
}

@media screen and (min-width: 1000px) {
  .categoryList li:nth-of-type(4n+1) {
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryList li:nth-of-type(2n+1) {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .categoryList li:nth-of-type(2n+1) {
    margin-left: 0;
  }
}

.categoryList a {
  width: 180px;
  min-height: 40px;
  height: 100%;
  line-height: 1;
  padding: 5px 30px 5px 10px;
  border-radius: 3px;
  background-color: #07ace9;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  position: relative;
}

.categoryList a:after {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 1.8rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .5em);
  line-height: 1;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryList a {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .categoryList a {
    width: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .categoryList a:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }
}

.categoryList + h1 {
  margin-bottom: 0;
}

.typeStyle {
  width: 100%;
  max-width: 780px;
  margin: 0 auto 80px;
  border-bottom: solid 1px #ccc;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .typeStyle {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle tbody {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle tr {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 10px;
    border-top: solid 1px #ccc;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle tr:first-child {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle tr + tr:nth-of-type(2) {
    border-top: none;
  }
}

.typeStyle th {
  font-size: 1.5rem;
  text-align: center;
  padding: 1em 10px;
}

.typeStyle td {
  text-align: left;
  font-size: 1.3rem;
  border-top: solid 1px #ccc;
  padding: 11px 0 11px 10px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .typeStyle td {
    display: block;
    width: 100%;
    padding: 0 10px;
  }
}

.typeStyle td:nth-of-type(1) {
  width: 410px;
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(1) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.typeStyle td:nth-of-type(1) figure {
  width: 90px;
  height: auto;
  min-height: 90px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(1) figure {
    float: none;
  }
}

.typeStyle td:nth-of-type(2) {
  width: 110px;
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(2) {
    padding-left: 4em;
    min-height: 1.5em;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(2):after {
    content: "形状 : ";
    color: #444444;
    position: absolute;
    left: 0;
  }
}

.typeStyle td:nth-of-type(3) {
  width: 110px;
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(3) {
    padding-left: 4em;
    min-height: 1.5em;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(3):after {
    content: "液性 : ";
    color: #444444;
    position: absolute;
    left: 0;
  }
}

.typeStyle td:nth-of-type(4) {
  width: 150px;
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(4) {
    padding-left: 4em;
    min-height: 1.5em;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(4):after {
    content: "荷姿 : ";
    color: #444444;
    position: absolute;
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .typeStyle td:nth-of-type(n) {
    width: 100%;
  }
}

.typeStyle td .block {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-around;
  align-content: center;
  flex-wrap: wrap;
  height: 100%;
  margin: 0 0 0 10px;
  padding: 10px 0;
  min-height: 90px;
}

.typeStyle td .name {
  margin: 5px 0;
  font-size: 1.5rem;
}

.typeStyle td .name a {
  color: #07ace9;
}

.typeStyle td .typeLabel {
  font-size: 1rem;
  display: inline-block;
}

.typeStyle td .typeLabel span {
  display: inline-block;
  border: solid 1px #999;
  color: #999;
  margin-right: 2px;
  margin-bottom: 2px;
  line-height: 1;
  padding: 5px;
}

@media screen and (max-width: 767px) {
  .typeStyle td {
    display: block;
    width: 100%;
    border-top: none;
  }
}

.typeStyle img {
  max-width: 90px;
  max-height: 90px;
  width: auto;
  height: auto;
}

/* ------------------------------------------------------------
Category
------------------------------------------------------------ */
.l-productsCategory {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .l-productsCategory {
    flex-direction: column;
  }
}

.testtest {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.testtest li {
  display: flex;
  flex-direction: column;
  width: 240px;
  background-color: #ccc;
}

.testtest .button {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #07ace9;
  color: #fff;
  line-height: 40px;
  margin: 10px 0 0;
  text-align: center;
}

.categoryStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
  width: 100%;
  max-width: 780px;
  margin: 0 auto 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryStyle {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .categoryStyle {
    max-width: 100%;
  }
}

.categoryStyle:after {
  display: block;
  content: "";
  width: 240px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryStyle:after {
    width: 32%;
  }
}

@media screen and (max-width: 767px) {
  .categoryStyle:after {
    width: 48%;
  }
}

.categoryStyle li {
  width: 240px;
  margin-bottom: 30px;
  min-height: 240px;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryStyle li {
    width: 32%;
  }
}

@media screen and (max-width: 767px) {
  .categoryStyle li {
    width: 48%;
  }
}

.categoryStyle figure {
  max-width: 240px;
  max-height: 240px;
  min-height: 240px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryStyle figure {
    max-width: 100%;
    max-height: 100%;
    min-height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .categoryStyle figure {
    max-width: 100%;
    max-height: 100%;
    min-height: 160px;
  }
}

.categoryStyle figure a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.categoryStyle figure img {
  width: auto;
  height: auto;
  max-width: 240px;
  max-height: 240px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryStyle figure img {
    max-width: 100%;
    max-height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .categoryStyle figure img {
    max-width: 100%;
    max-height: 160px;
    min-width: 140px;
  }
}

.categoryStyle h3 {
  font-size: 1.6rem;
}

.categoryStyle h3 a {
  color: #07ace9;
}

.categoryStyle .labellist {
  margin: 0;
}

.categoryStyle .labellist span {
  background-color: #e3f5ff;
  color: #07ace9;
  margin-right: 2px;
  margin-bottom: 2px;
  display: inline-block;
  padding: 5px;
}

.categoryStyle .button {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  background-color: #07ace9;
  color: #fff;
  line-height: 40px;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.categoryStyle .button:after {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 1.8rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .5em);
  line-height: 1;
}

@media screen and (min-width: 1000px) {
  .categoryStyle .button:hover {
    background-color: #0687b7;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .categoryStyle .button:hover {
    background-color: #07ace9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (max-width: 767px) {
  .categoryStyle .button {
    background-color: #07ace9;
  }
}

/* ------------------------------------------------------------
Device
------------------------------------------------------------ */
.l-device,
.l-document {
  text-align: left;
}

.l-device .inner,
.l-document .inner {
  max-width: 1000px;
  margin: 0 auto 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-device .inner,
  .l-document .inner {
    width: 95%;
    margin: 0 auto 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-device .inner,
  .l-document .inner {
    width: 95%;
    margin: 0 auto 40px;
  }
}

.l-device .leadtext,
.l-document .leadtext {
  margin: 0 0 20px;
  line-height: 2;
}

.l-device .headlead,
.l-document .headlead {
  margin: 0 0 40px;
}

.l-device .contentTitle,
.l-document .contentTitle {
  font-size: 2.4rem;
  padding-left: 10px;
  border-left: solid 5px #07ace9;
}

.l-device h3,
.l-document h3 {
  font-size: 1.8rem;
  margin: 0 0 20px;
}

.l-device .arrow,
.l-document .arrow {
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) left center no-repeat;
  background-size: 5px;
  font-size: 1.8rem;
  color: #07ace9;
}

.l-device .arrow i,
.l-document .arrow i {
  margin-left: 5px;
  color: #ccc;
}

.l-device .ex-l,
.l-document .ex-l {
  font-size: 1.8rem;
  color: #07ace9;
}

.l-device .ex-l i,
.l-document .ex-l i {
  margin-right: 5px;
  color: #ccc;
}

.l-device .filesize,
.l-document .filesize {
  font-weight: 400;
  font-size: calc(1em - .25em);
}

.l-device .note,
.l-document .note {
  font-size: 1.4rem;
}

.l-device .note + h3,
.l-document .note + h3 {
  margin-top: 80px;
}

.l-device .note + .contentTitle,
.l-document .note + .contentTitle {
  margin-top: 80px;
}

.l-device .pdflist + h3,
.l-document .pdflist + h3 {
  margin-top: 80px;
}

.l-device .pdflist + .contentTitle,
.l-document .pdflist + .contentTitle {
  margin-top: 80px;
}

/* ------------------------------------------------------------
Document
------------------------------------------------------------ */
.pdflist {
  border-bottom: solid 1px #ccc;
}

.pdflist li {
  border-top: solid 1px #ccc;
  font-size: 1.8rem;
  padding: 10px 0;
}

.pdflist li a {
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) left center no-repeat;
  background-size: 5px;
  color: #07ace9;
}

.pdflist li i {
  margin-left: 5px;
  color: #ccc;
}

/* ------------------------------------------------------------
Topics
------------------------------------------------------------ */
.l-productsTopics .inner {
  max-width: 1000px;
  margin: 0 auto;
  margin: 0 auto 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsTopics .inner {
    width: 95%;
    margin: 0 auto 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-productsTopics .inner {
    width: 95%;
    margin: 0 auto 40px;
  }
}

.l-productsTopics .button {
  width: 160px;
  height: 40px;
  background-color: #ccc;
  color: #fff;
  line-height: 40px;
  margin: 20px;
}

@media screen and (min-width: 1000px) {
  .l-productsTopics .button:hover {
    background-color: #07ace9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-productsTopics .button:hover {
    background-color: #07ace9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (max-width: 767px) {
  .l-productsTopics .button {
    background-color: #07ace9;
  }
}

/* =========================================================
Business
========================================================= */
.l-business {
  margin: 0 0 80px;
}

.l-business .leadtext {
  max-width: 780px;
  margin: 0 auto;
  line-height: 2;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .l-business .leadtext {
    max-width: 95%;
    text-align: left;
  }
}

.l-business__service h3, .l-business__industry h3 {
  text-align: left;
  font-size: 2.4rem;
  max-width: 1000px;
  margin: 0 auto 40px;
  padding-left: 10px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-business__service h3, .l-business__industry h3 {
    max-width: 95%;
  }
}

.l-business__service .inner, .l-business__industry .inner {
  max-width: 1000px;
  margin: 40px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-business__service .inner, .l-business__industry .inner {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-business__service .inner, .l-business__industry .inner {
    max-width: 95%;
    flex-direction: column;
  }
}

.l-business__service .side, .l-business__industry .side {
  width: 42%;
}

@media screen and (max-width: 767px) {
  .l-business__service .side, .l-business__industry .side {
    width: 100%;
  }
}

.l-business__service .side p, .l-business__industry .side p {
  font-size: 1.8rem;
  text-align: left;
  line-height: 2;
}

.l-business__service .side .en, .l-business__industry .side .en {
  font-size: 3.6rem;
  font-weight: bold;
  margin: 0;
  line-height: 1;
}

.l-business__service ul, .l-business__industry ul {
  margin: 0;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .l-business__service ul, .l-business__industry ul {
    margin-bottom: 40px;
  }
}

.l-business__service li + li, .l-business__industry li + li {
  margin-top: 10px;
}

.l-business__service a, .l-business__industry a {
  line-height: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 380px;
  height: 50px;
  color: #fff;
  text-align: left;
  padding-left: 54px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-business__service a, .l-business__industry a {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .l-business__service a, .l-business__industry a {
    width: 100%;
  }
}

.l-business__service .application, .l-business__industry .application {
  width: 56%;
  padding: 0 0 40px 40px;
  margin: 0 0 0 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-business__service .application, .l-business__industry .application {
    padding: 20px 0 40px;
    width: 100%;
  }
}

.l-business__service .application__description, .l-business__industry .application__description {
  width: 100%;
}

.l-business__service .application__description p, .l-business__industry .application__description p {
  font-size: 1.8rem;
  text-align: left;
  margin: 20px 0 0;
}

.l-business__service .application__description a, .l-business__industry .application__description a {
  background-color: transparent;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  width: 80%;
  height: auto;
}

.l-business__service .application__description img, .l-business__industry .application__description img {
  margin: 0 auto;
}

.l-business__service figure, .l-business__industry figure {
  max-width: 1000px;
  height: 360px;
  margin: 0 auto 0;
}

@media screen and (max-width: 767px) {
  .l-business__service figure, .l-business__industry figure {
    margin: 0 auto 0;
  }
}

.l-business__service h3 {
  border-left: solid 5px #0079a8;
}

.l-business__service .service__detail .en {
  color: #bfcde0;
}

.l-business__service li:nth-of-type(1) a {
  background-image: url(/images/common/icon/service.png);
  background-position: 9px center;
  background-repeat: no-repeat;
  background-size: 36px;
}

.l-business__service li:nth-of-type(2) a {
  background-image: url(/images/common/icon/agency.png);
  background-position: 12px center;
  background-repeat: no-repeat;
  background-size: 34px;
}

.l-business__service a {
  background-color: #0079a8;
  position: relative;
  border-radius: 3px;
  min-height: 50px;
}

.l-business__service a:after {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 2.4rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .5em);
  line-height: 1;
}

.l-business__service figure {
  background: url(/images/business/service_img.jpg) center no-repeat;
  background-size: cover;
}

.l-business__industry {
  margin: 40px 0 0;
}

@media screen and (max-width: 767px) {
  .l-business__industry {
    margin: 40px 0 0;
  }
}

.l-business__industry .industry__detail .en {
  color: #a3cda9;
}

.l-business__industry h3 {
  border-left: solid 5px #0a7c48;
}

.l-business__industry li:nth-of-type(1) a {
  background-image: url(/images/common/icon/industry.png);
  background-position: 12px center;
  background-repeat: no-repeat;
  background-size: 33px;
}

.l-business__industry a {
  background-color: #0a7c48;
  position: relative;
  border-radius: 3px;
  min-height: 50px;
}

.l-business__industry a:after {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 2.4rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .5em);
  line-height: 1;
}

.l-business__industry figure {
  background: url(/images/business/industry_img.jpg) center no-repeat;
  background-size: cover;
}

/* ------------------------------------------------------------ */
.l-businessField,
.l-soldto,
.l-mainProducts {
  margin-bottom: 160px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-businessField,
  .l-soldto,
  .l-mainProducts {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  .l-businessField,
  .l-soldto,
  .l-mainProducts {
    margin-bottom: 80px;
  }
}

.l-businessField .leadtext,
.l-soldto .leadtext,
.l-mainProducts .leadtext {
  max-width: 1000px;
  margin: 0 auto 40px;
  text-align: center;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .l-businessField .leadtext,
  .l-soldto .leadtext,
  .l-mainProducts .leadtext {
    max-width: 95%;
    text-align: left;
  }
}

.l-businessField .inner,
.l-soldto .inner,
.l-mainProducts .inner {
  max-width: 1000px;
  margin: 0 auto 0px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-businessField .inner,
  .l-soldto .inner,
  .l-mainProducts .inner {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-businessField .inner,
  .l-soldto .inner,
  .l-mainProducts .inner {
    max-width: 95%;
  }
}

.l-businessField .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .l-businessField .inner {
    flex-direction: column;
  }
}

.l-businessField figure {
  width: 48%;
}

@media screen and (max-width: 767px) {
  .l-businessField figure {
    width: 100%;
    margin-bottom: 20px;
  }
}

.l-businessField .block {
  width: 48%;
  text-align: left;
  line-height: 2;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .l-businessField .block {
    width: 100%;
  }
}

.l-businessField .block p {
  margin: 0;
}

.l-businessField .block a {
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  color: #07ace9;
  float: right;
}

@media screen and (max-width: 767px) {
  .l-businessField .block a {
    float: none;
  }
}

.l-soldto figure {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.l-soldto figure img {
  width: auto;
  height: auto;
}

@media screen and (max-width: 767px) {
  .l-soldto figure img {
    width: 90%;
  }
}

.l-mainProducts .inner {
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mainProducts .inner {
    width: 95%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .l-mainProducts .inner {
    flex-direction: column-reverse;
  }
}

.l-mainProducts figure {
  width: 48%;
}

@media screen and (max-width: 767px) {
  .l-mainProducts figure {
    width: 100%;
  }
}

.l-mainProducts .block {
  width: 48%;
}

@media screen and (max-width: 767px) {
  .l-mainProducts .block {
    width: 100%;
  }
}

.l-mainProducts h3 {
  font-size: 2.4rem;
  border-bottom: solid 1px #ccc;
}

.l-mainProducts p {
  font-size: 1.8rem;
}

.l-mainProducts ul {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.l-mainProducts li {
  font-size: 1.8rem;
  min-width: 50%;
  line-height: 2;
}

.l-mainProducts li a {
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  color: #07ace9;
}

/* ------------------------------------------------------------ */
.l-agency__map {
  background-color: #e8f7ff;
}

.l-agency__map .inner {
  max-width: 1000px;
  min-height: 760px;
  margin: 0 auto;
  padding: 20px 120px;
  background: url(/images/business/agency_map.png) center no-repeat;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__map .inner {
    max-width: 95%;
    padding: 20px 0;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__map .inner {
    max-width: 95%;
    min-height: auto;
    padding: 20px 0 85%;
    background-position: center bottom;
    background-size: 120%;
  }
}

.l-agency__map ul {
  margin: 0;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .l-agency__map ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.l-agency__map li {
  position: absolute;
  width: 180px;
  height: 60px;
  top: 50%;
  left: 50%;
}

@media screen and (min-width: 1000px) {
  .l-agency__map li:hover {
    background-color: #07ace9;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__map li {
    position: static;
  }
}

.l-agency__map li:nth-of-type(1) {
  margin: -330px 0 0 -90px;
}

.l-agency__map li:nth-of-type(2) {
  margin: -110px 0 0 190px;
}

.l-agency__map li:nth-of-type(3) {
  margin: 20px 0 0 190px;
}

.l-agency__map li:nth-of-type(4) {
  margin: 150px 0 0 -10px;
}

.l-agency__map li:nth-of-type(5) {
  margin: -30px 0 0 -220px;
}

.l-agency__map li:nth-of-type(6) {
  margin: 250px 0 0 -170px;
}

.l-agency__map li:nth-of-type(7) {
  width: 100px;
  height: 40px;
  margin: 290px 0 0 220px;
}

@media screen and (max-width: 767px) {
  .l-agency__map li:nth-of-type(7) a {
    line-height: 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__map li:nth-of-type(n) {
    width: 48.5%;
    margin: 10px 0 0;
  }
}

.l-agency__map h3 {
  color: #4370aa;
  font-size: 2.4rem;
  font-weight: 500;
  text-align: left;
  margin: 20px 0 0;
}

@media screen and (max-width: 767px) {
  .l-agency__map h3 {
    text-align: center;
  }
}

.l-agency__map p {
  font-size: 1.6rem;
  text-align: left;
}

.l-agency__map img {
  width: 120px;
  height: auto;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .l-agency__map img {
    width: 80px;
    margin: 0 auto;
  }
}

.l-agency__map a {
  display: block;
  background-color: #fff;
  font-size: 1.5rem;
  line-height: 1.35;
  padding: 10px 5px;
  color: #666;
  text-align: center;
}

.l-agency__list {
  max-width: 1000px;
  margin: 160px auto;
  border-top: solid 1px #ccc;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list {
    margin: 80px auto;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list {
    margin: 40px auto;
  }
}

.l-agency__list .region {
  margin: 0;
}

.l-agency__list .region__item {
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region__item {
    flex-direction: column;
    padding: 0 2.5%;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region__item {
    flex-direction: column;
  }
}

.l-agency__list .region__item:first-child {
  padding-top: 1px;
  margin-top: -1px;
}

.l-agency__list .region__item + li {
  border-top: solid 1px #ccc;
}

.l-agency__list .region__name {
  width: 220px;
  font-size: 2rem;
  text-align: left;
  padding-top: 20px;
}

@media screen and (max-width: 767px) {
  .l-agency__list .region__name {
    padding: 20px 10px 0;
    width: 100%;
  }
}

.l-agency__list .region__name i {
  margin-right: 10px;
  color: #fd0004;
  font-size: 2.2rem;
}

.l-agency__list .region__data {
  font-size: 1.3rem;
  text-align: left;
  padding-top: 20px;
}

.l-agency__list .region__head {
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  padding: 0 0 20px;
}

@media screen and (max-width: 767px) {
  .l-agency__list .region__head {
    flex-direction: column;
    padding: 0 10px 20px;
  }
}

.l-agency__list .region__head .agencyName {
  width: 240px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region__head .agencyName {
    width: 30%;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region__head .agencyName {
    width: 100%;
    margin-bottom: 5px;
    font-size: 1.6rem;
  }
}

.l-agency__list .region__head .agencyAddr {
  width: 540px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region__head .agencyAddr {
    width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region__head .agencyAddr {
    width: 100%;
  }
}

.l-agency__list .region__head .fa {
  margin-right: 5px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table tbody {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table tr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-top: solid 1px #ccc;
    width: 100%;
    padding: 20px 10px;
  }
}

.l-agency__list .region table tr + tr td {
  border-top: solid 1px #ccc;
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table tr + tr td {
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table tr:nth-of-type(1) {
    display: none;
  }
}

.l-agency__list .region table tr:nth-of-type(2) td {
  border-top: none;
}

.l-agency__list .region table th {
  background-color: #f6f6f6;
  line-height: 50px;
  vertical-align: middle;
  font-size: 1.6rem;
  font-weight: 500;
}

.l-agency__list .region table th:nth-of-type(1) {
  width: 240px;
  padding-left: 10px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table th:nth-of-type(1) {
    width: 30%;
  }
}

.l-agency__list .region table th:nth-of-type(2) {
  width: 220px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table th:nth-of-type(2) {
    width: 30%;
  }
}

.l-agency__list .region table th:nth-of-type(3) {
  width: 150px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table th:nth-of-type(3) {
    width: 20%;
  }
}

.l-agency__list .region table th:nth-of-type(4) {
  width: 150px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table th:nth-of-type(4) {
    width: 20%;
  }
}

.l-agency__list .region table td {
  min-height: 50px;
  vertical-align: middle;
  padding: 10px 0;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table td {
    width: 100%;
    min-height: auto;
    padding: 0;
    display: block;
  }
}

.l-agency__list .region table td:nth-of-type(1) {
  width: 240px;
  padding-left: 10px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table td:nth-of-type(1) {
    width: 30%;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table td:nth-of-type(1) {
    width: 100%;
    padding: 0;
    font-size: 1.6rem;
  }
}

.l-agency__list .region table td:nth-of-type(2) {
  width: 220px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table td:nth-of-type(2) {
    width: 30%;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table td:nth-of-type(2) {
    width: 100%;
  }
}

.l-agency__list .region table td:nth-of-type(3) {
  width: 150px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table td:nth-of-type(3) {
    width: 20%;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table td:nth-of-type(3) {
    width: 100%;
  }
}

.l-agency__list .region table td:nth-of-type(4) {
  width: 150px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-agency__list .region table td:nth-of-type(4) {
    width: 20%;
  }
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table td:nth-of-type(4) {
    width: 100%;
  }
}

.l-agency__list .region table td:nth-of-type(3) .fa,
.l-agency__list .region table td:nth-of-type(4) .fa {
  display: none;
}

@media screen and (max-width: 767px) {
  .l-agency__list .region table td:nth-of-type(3) .fa,
  .l-agency__list .region table td:nth-of-type(4) .fa {
    display: inline-block;
    margin-right: 5px;
  }
}

.l-agency__list .region table td + td {
  margin-top: 5px;
}

.l-agency__list .region table a {
  color: #07ace9;
}

.l-agency__list .region table a i.fa {
  color: #ccc;
  margin-left: 5px;
}

/* ------------------------------------------------------------ */
/* =========================================================
company
========================================================= */
.l-company {
  margin: 0 0 120px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-company {
    margin: 0 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .l-company {
    margin: 0 0 40px;
  }
}

.l-company__list {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-company__list {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-company__list {
    flex-direction: column;
    max-width: 95%;
  }
}

.l-company__list:after {
  content: "";
  display: none;
  background-color: #e3f5ff;
  width: 92%;
  height: calc(100% - 40px);
  position: absolute;
  top: 40px;
  left: 4%;
  z-index: -1;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-company__list:after {
    width: 90%;
    left: 5%;
  }
}

@media screen and (max-width: 767px) {
  .l-company__list:after {
    width: 90%;
    left: 5%;
  }
}

.l-company__item {
  width: 48%;
  height: auto;
  position: relative;
  margin: 0 0 40px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-company__item {
    margin: 0 0 4%;
  }
}

@media screen and (max-width: 767px) {
  .l-company__item {
    width: 95%;
    height: 100%;
    margin: 0 2.5% 2.5%;
  }
}

.l-company__item:nth-of-type(even) {
  margin-left: 4%;
}

@media screen and (max-width: 767px) {
  .l-company__item:nth-of-type(even) {
    margin-left: 2.5%;
  }
}

.l-company__label {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  letter-spacing: -.015em;
}

.l-company__label h3 {
  display: inline-block;
  line-height: 1;
  margin: 0 10px -.2em 0;
  font-size: 4.8rem;
  font-weight: bold;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-company__label h3 {
    font-size: calc(9.6em + 12 * (100vw - 768px) / 232);
  }
}

@media screen and (max-width: 767px) {
  .l-company__label h3 {
    font-size: calc(4.8em + 48 * (100vw - 320px) / 448);
  }
}

.l-company__label p {
  display: inline-block;
  line-height: 1;
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  position: relative;
  bottom: -.2em;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-company__label p {
    font-size: calc(3.6em + 6 * (100vw - 768px) / 232);
  }
}

@media screen and (max-width: 767px) {
  .l-company__label p {
    font-size: calc(1.8em + 18 * (100vw - 320px) / 448);
  }
}

/* =========================================================
overview
========================================================= */
.companyInfo__lead {
  font-size: 1.8rem;
  margin-bottom: 40px;
}

.companyInfo__list {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
}

.companyInfo__item {
  width: 460px;
  height: 260px;
  margin: 0 0 40px;
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .companyInfo__item {
    width: 46.875%;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .companyInfo__item {
    width: 100%;
    height: auto;
  }
}

.companyInfo__item a {
  display: block;
  padding: 20px 20px 0;
  height: 100%;
  background-color: #eee;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .companyInfo__item a {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .companyInfo__item a {
    padding: 20px;
  }
}

.companyInfo__item a:hover {
  background-color: #ddd;
}

.companyInfo__item img {
  width: 100%;
  margin-bottom: 20px;
}

/* ------------------------------------------------------------ */
.wrapper {
  width: 100%;
}

.l-affiliated__list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.l-affiliated__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px #ccc;
  padding: 0 0 10px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-affiliated__item {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .l-affiliated__item {
    flex-direction: column;
  }
}

.l-affiliated__item a,
.l-affiliated__item span {
  width: 50%;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-affiliated__item a,
  .l-affiliated__item span {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .l-affiliated__item a,
  .l-affiliated__item span {
    width: 100%;
  }
}

.l-affiliated__item + li {
  padding-top: 10px;
}

/* =========================================================
philosophy
========================================================= */
.l-philosophy {
  margin: 0 0 160px;
}

.l-philosophy__slogan {
  width: 462px;
  margin: 80px auto 0;
}

@media screen and (max-width: 767px) {
  .l-philosophy__slogan {
    width: 80%;
  }
}

.slogan {
  margin: 80px auto 0;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .slogan {
    width: 85%;
    margin: 80px 5% 0 10%;
  }
}

.slogan__item {
  font-size: 1.8rem;
  text-align: left;
  line-height: 2;
  list-style-type: disc;
}

.l-behavioralGuidelines {
  display: inline-block;
  margin: 0 0 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-behavioralGuidelines {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .l-behavioralGuidelines {
    width: 100%;
  }
}

.l-behavioralGuidelines__item {
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 2.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 780px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-behavioralGuidelines__item {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .l-behavioralGuidelines__item {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 5%;
  }
}

.l-behavioralGuidelines__item + li {
  margin-top: 1em;
  padding-top: 1em;
  border-top: solid 1px #eee;
}

.l-behavioralGuidelines__item h3 {
  line-height: 1.5;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.l-behavioralGuidelines__item h3::first-letter {
  font-size: 1.8rem;
  margin-right: 1em;
}

@media screen and (max-width: 767px) {
  .l-behavioralGuidelines__item h3 {
    text-align: left;
  }
}

.l-behavioralGuidelines__item p {
  font-size: 1.8rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  width: 420px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-behavioralGuidelines__item p {
    width: 48%;
  }
}

@media screen and (max-width: 767px) {
  .l-behavioralGuidelines__item p {
    width: calc(100% - 2em);
    margin-left: 2em;
  }
}

/* =========================================================
access office
========================================================= */
.l-gmap {
  width: 100%;
}

.l-gmap__ymap {
  width: 1000px;
  max-width: 1000px;
  height: 360px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-gmap__ymap {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-gmap__ymap {
    width: 95%;
  }
}

.l-gmap__ymap iframe {
  width: 100%;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.l-gmap__map {
  width: 100%;
  max-width: 1000px;
  padding-top: 360px;
  height: 0;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-gmap__map {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-gmap__map {
    width: 95%;
  }
}

.l-gmap__map iframe {
  width: 100%;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.l-gmap__profile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 1000px;
  margin: 40px auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-gmap__profile {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-gmap__profile {
    width: 95%;
    flex-direction: column;
  }
}

.l-gmap__profile figure {
  width: 320px;
  height: auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-gmap__profile figure {
    width: 32%;
  }
}

@media screen and (max-width: 767px) {
  .l-gmap__profile figure {
    width: 100%;
  }
}

.l-gmap__data {
  width: 680px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-gmap__data {
    width: calc(68% - 20px);
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-gmap__data {
    width: 100%;
    margin-top: 20px;
  }
}

.l-gmap__data h3 {
  font-size: 2.4rem;
  margin: 0;
  padding: 0;
  text-align: left;
  margin: 0px 0 0 80px;
  max-width: 520px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-gmap__data h3 {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-gmap__data h3 {
    margin: 0;
  }
}

.l-gmap__data p {
  text-align: left;
  margin: 10px 0 0 80px;
  max-width: 520px;
  font-size: 1.8rem;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-gmap__data p {
    margin: 10px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .l-gmap__data p {
    margin: 10px 0 0;
  }
}

.l-gmap__data dl {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  max-width: 520px;
  font-size: 1.8rem;
  margin: 0 0 0 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-gmap__data dl {
    max-width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-gmap__data dl {
    width: 100%;
    margin: 0;
  }
}

.l-gmap__data dt {
  width: 28%;
  padding: 20px 0;
}

.l-gmap__data dd {
  width: 72%;
  padding: 20px 0;
}

.l-gmap__data dd + dt {
  border-top: solid 1px #ddd;
}

.l-gmap__data dd + dt + dd {
  border-top: solid 1px #ddd;
}

.l-gmap__data img {
  width: 12px;
  height: auto;
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.l-gmap__inquiry {
  display: flex;
  flex-direction: column;
}

.l-gmap__inquiry a {
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  color: #07ace9;
}

.maplist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .maplist {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .maplist {
    width: 95%;
  }
}

.maplist__item {
  width: 46%;
  height: auto;
  position: relative;
  text-align: left;
  margin: 40px 0 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .maplist__item {
    width: 48%;
  }
}

@media screen and (max-width: 767px) {
  .maplist__item {
    width: 100%;
  }
}

.maplist h3 {
  font-size: 2.4rem;
  margin: 0 0 1em;
  padding: 0;
  float: left;
}

.maplist p {
  font-size: 1.8rem;
  margin: 0;
  padding: 0;
}

.maplist .addr {
  margin: 0 0 1em;
  width: 100%;
  min-height: 120px;
  clear: both;
}

.maplist .use {
  color: #fff;
  margin: 10px 0;
  float: right;
}

.maplist .use span {
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 1.2rem;
}

.maplist .use span + span {
  margin-left: 10px;
}

.maplist .use span.use--service {
  background-color: #0079a8;
}

.maplist .use span.use--industry {
  background-color: #0a7c48;
}

.maplist .officeMaps {
  width: 100%;
  height: 0;
  padding-top: 75%;
  overflow: hidden;
  position: relative;
}

.maplist .officeMaps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ------------------------------------------------------------ */
.localnavi {
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  height: 80px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: column;
}

@media screen and (max-width: 767px) {
  .localnavi {
    height: auto;
  }
}

.localnavi__list {
  align-items: center;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .localnavi__list {
    height: auto;
    flex-direction: column;
    padding: 0;
  }
}

.localnavi__item {
  display: inline-block;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .localnavi__item {
    width: 100%;
    padding-bottom: 10px;
  }
}

.localnavi__item + .localnavi__item {
  margin-left: 40px;
}

@media screen and (max-width: 767px) {
  .localnavi__item + .localnavi__item {
    margin: 0 auto;
  }
}

.localnavi__item:first-child {
  margin-right: 80px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .localnavi__item:first-child {
    margin: 0 auto 20px;
    border-bottom: solid 2px #444444;
  }
}

.localnavi__item:first-child:after {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 40px;
  transform: rotate(30deg);
  background-color: #444444;
  top: 0px;
  right: -65px;
}

@media screen and (max-width: 767px) {
  .localnavi__item:first-child:after {
    display: none;
  }
}

.localnavi a {
  display: inline-block;
  padding: 13px 0px;
  line-height: 1;
}

@media screen and (min-width: 1000px) {
  .localnavi a:hover {
    border-bottom: solid 3px #07ace9;
    padding: 13px 0px 10px;
    color: #07ace9;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .localnavi a:hover {
    border-bottom: solid 3px #07ace9;
    padding: 13px 0px 10px;
    color: #07ace9;
  }
}

.localnavi a.current {
  border-bottom: solid 3px #07ace9;
  padding: 13px 0px 10px;
  color: #07ace9;
}

@media screen and (max-width: 767px) {
  .localnavi a.current {
    color: #fff;
    background-color: #07ace9;
    display: block;
  }
}

/* ------------------------------------------------------------ */
/* =========================================================
Sanitation
========================================================= */
.l-sanitation {
  margin: 0 0 120px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-sanitation {
    margin: 0 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .l-sanitation {
    margin: 0 0 40px;
  }
}

.l-sanitation__list {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-sanitation__list {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-sanitation__list {
    flex-direction: column;
  }
}

.l-sanitation__list:after {
  content: "";
  display: block;
  background-color: #e3f5ff;
  width: 92%;
  height: calc(100% - 40px);
  position: absolute;
  top: 40px;
  left: 4%;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .l-sanitation__list:after {
    width: 90%;
    left: 5%;
  }
}

.l-sanitation__item {
  width: 48%;
  height: auto;
  position: relative;
  margin: 0 0 40px;
}

.l-sanitation__item:nth-of-type(even) {
  margin-left: 4%;
}

@media screen and (max-width: 767px) {
  .l-sanitation__item:nth-of-type(even) {
    margin-left: 2.5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-sanitation__item {
    margin: 0 0 4%;
  }
}

@media screen and (max-width: 767px) {
  .l-sanitation__item {
    width: 95%;
    height: 100%;
    margin: 0 2.5% 2.5%;
  }
}

.l-sanitation__item.longname h3 {
  font-size: 3rem;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-sanitation__item.longname h3 {
    font-size: calc(1.5em + 20 * (100vw - 768px) / 232);
  }
}

@media screen and (max-width: 767px) {
  .l-sanitation__item.longname h3 {
    font-size: calc(3em + 30 * (100vw - 320px) / 468);
  }
}

.l-sanitation__label {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  letter-spacing: -.015em;
}

.l-sanitation__label h3 {
  display: inline-block;
  line-height: 1;
  margin: 0 10px -.2em 0;
  font-size: 4.8rem;
  font-weight: bold;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-sanitation__label h3 {
    font-size: calc(4.4em + 12 * (100vw - 768px) / 232);
  }
}

@media screen and (max-width: 767px) {
  .l-sanitation__label h3 {
    font-size: calc(4.4em + 44 * (100vw - 320px) / 448);
  }
}

.l-sanitation__label p {
  display: inline-block;
  line-height: 1;
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  position: relative;
  bottom: -.2em;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-sanitation__label p {
    font-size: calc(2.4em + 6 * (100vw - 768px) / 232);
  }
}

@media screen and (max-width: 767px) {
  .l-sanitation__label p {
    font-size: calc(1.8em + 18 * (100vw - 320px) / 448);
  }
}

/* ------------------------------------------------------------ */
.l-aboutHACCP .leadtext {
  max-width: 780px;
  margin: 0 auto 40px;
  line-height: 2;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-aboutHACCP .leadtext {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-aboutHACCP .leadtext {
    width: 95%;
  }
}

.l-aboutHACCP .note {
  font-size: 1.6rem;
  max-width: 780px;
  margin: 40px auto 80px;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-aboutHACCP .note {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-aboutHACCP .note {
    width: 95%;
  }
}

.l-aboutHACCP .process {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 780px;
  margin: 40px auto 160px;
  height: auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-aboutHACCP .process {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-aboutHACCP .process {
    width: 95%;
    display: block;
  }
}

.l-aboutHACCP .process img {
  width: 360px;
  height: auto;
}

@media screen and (max-width: 767px) {
  .l-aboutHACCP .process img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .l-aboutHACCP .process img + img {
    margin-top: 80px;
  }
}

.haccpDescription {
  background-color: #07ace9;
  color: #fff;
}

.haccpDescription .inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 0;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .haccpDescription .inner {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .haccpDescription .inner {
    width: 95%;
  }
}

.haccpDescription .initial {
  font-size: 108px;
  font-weight: 700;
  letter-spacing: .75em;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  line-height: 1;
  text-indent: .8em;
  margin: 0 0 30px;
  padding: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .haccpDescription .initial {
    text-indent: .1em;
    letter-spacing: .25em;
    font-size: 68px;
    margin: 0 0 10px;
  }
}

@media screen and (max-width: 767px) {
  .haccpDescription .initial {
    margin: 0 0 10px;
    font-size: 41px;
    text-indent: .1em;
    letter-spacing: .25em;
  }
}

.haccpDescription .sentence {
  margin: 0;
  line-height: 1;
}

.haccpDescription .word {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  display: inline-block;
  letter-spacing: 0.03em;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .haccpDescription .word {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .haccpDescription .word {
    font-size: 1.2rem;
  }
}

.haccpDescription .word::first-letter {
  color: #ff0;
}

.haccpDescription .word:nth-of-type(1) {
  margin: 0 .75em 0 -13em;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .haccpDescription .word:nth-of-type(1) {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .haccpDescription .word:nth-of-type(1) {
    margin: 0;
  }
}

.haccpDescription .word:nth-of-type(2) {
  margin: 0 10.5em 0 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .haccpDescription .word:nth-of-type(2) {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .haccpDescription .word:nth-of-type(2) {
    margin: 0;
  }
}

.haccpDescription .word:nth-of-type(3) {
  margin: 0 .75em 0 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .haccpDescription .word:nth-of-type(3) {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .haccpDescription .word:nth-of-type(3) {
    margin: 0;
  }
}

.haccpDescription .word:nth-of-type(4) {
  margin: 0 .75em 0 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .haccpDescription .word:nth-of-type(4) {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .haccpDescription .word:nth-of-type(4) {
    margin: 0;
  }
}

.haccpDescription p {
  font-size: 3.6rem;
  text-align: left;
  display: inline-block;
  margin: 0;
  letter-spacing: .175em;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .haccpDescription p {
    font-size: 2.4rem;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 767px) {
  .haccpDescription p {
    font-size: 2.4rem;
    letter-spacing: 0;
  }
}

.haccpDescription p span {
  border-bottom: solid 2px #fff;
  display: inline-block;
  padding-bottom: 3px;
}

.l-institutionalization .inner {
  width: 780px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-institutionalization .inner {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-institutionalization .inner {
    width: 95%;
  }
}

.l-institutionalization .leadtext {
  width: 780px;
  margin: 0 auto 40px;
  line-height: 2;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-institutionalization .leadtext {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-institutionalization .leadtext {
    width: 95%;
  }
}

.l-institutionalization .guideline {
  background-color: #e3f5ff;
  text-align: left;
  padding: 80px 0;
}

.l-institutionalization .guideline__title {
  color: #07ace9;
  font-size: 2.4rem;
  letter-spacing: .1em;
  text-align: center;
  margin: 0 0 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-institutionalization .guideline__title {
    font-size: 2.2rem;
  }
}

.l-institutionalization .guideline__standard {
  width: 100%;
  padding: 20px;
  border: solid 1px #07ace9;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .guideline__standard {
    flex-direction: column;
  }
}

.l-institutionalization .guideline__standard p {
  color: #07ace9;
  font-size: 1.8rem;
  font-weight: bold;
  width: 230px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-institutionalization .guideline__standard p {
    width: auto;
    margin-right: 2%;
  }
}

@media screen and (max-width: 767px) {
  .l-institutionalization .guideline__standard p {
    width: auto;
  }
}

.l-institutionalization .guideline__situation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .guideline__situation {
    flex-direction: column;
  }
}

.l-institutionalization .guideline__situation p {
  width: 100%;
  font-size: 1.8rem;
  font-weight: bold;
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .guideline__situation p {
    text-align: center;
  }
}

.l-institutionalization .guideline__situation p span {
  color: #07ace9;
  font-size: 3.6rem;
  line-height: 1;
  position: absolute;
  top: -6px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .guideline__situation p span {
    display: none;
  }
}

.l-institutionalization .standard__item {
  width: 240px;
  height: 90px;
  padding: 11px 0 11px 11px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .standard__item {
    width: 100%;
  }
}

.l-institutionalization .standard__item img {
  width: auto;
  height: auto;
}

.l-institutionalization .standard__item .label {
  color: #444444;
  display: inline-block;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: .01em;
}

.l-institutionalization .standard__item .label span {
  color: #fe000b;
}

.l-institutionalization .standard__item + .standard__item {
  margin-left: 30px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-institutionalization .standard__item + .standard__item {
    margin-left: .5%;
  }
}

@media screen and (max-width: 767px) {
  .l-institutionalization .standard__item + .standard__item {
    margin-left: 0;
    margin-top: 20px;
  }
}

.l-institutionalization .commentary {
  font-size: 1.6rem;
  max-width: 780px;
  margin: 80px auto 40px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .commentary {
    flex-direction: column;
    width: 95%;
  }
}

.l-institutionalization .commentary .left {
  width: 360px;
  line-height: 2;
  margin-top: -.5em;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .commentary .left {
    width: 100%;
  }
}

.l-institutionalization .commentary .right {
  width: 360px;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .commentary .right {
    width: 100%;
  }
}

.l-institutionalization .commentary .largeScale,
.l-institutionalization .commentary .smallScale {
  background-color: #e3f5ff;
  padding: 20px;
}

.l-institutionalization .commentary .largeScale h4,
.l-institutionalization .commentary .smallScale h4 {
  margin: 0 0 20px;
  font-size: 1.8rem;
  color: #07ace9;
}

.l-institutionalization .commentary .largeScale p,
.l-institutionalization .commentary .smallScale p {
  margin: 0 0 20px;
}

.l-institutionalization .commentary .largeScale p .highlighter,
.l-institutionalization .commentary .smallScale p .highlighter {
  background: linear-gradient(transparent 70%, #ff0 30%);
  display: inline;
  font-size: 1.6rem;
  padding: 0;
}

.l-institutionalization .commentary .largeScale span,
.l-institutionalization .commentary .smallScale span {
  background-color: #fff;
  padding: 20px;
  display: inline-block;
  font-size: 1.2rem;
}

.l-institutionalization .commentary .largeScale {
  margin-bottom: 20px;
}

.l-institutionalization .note {
  font-size: 1.6rem;
  max-width: 780px;
  margin: 40px auto 40px;
  text-align: left;
}

.l-institutionalization .chronology {
  margin-bottom: 80px;
  position: relative;
  z-index: 0;
}

.l-institutionalization .chronology:after {
  content: "";
  background-color: #fff;
  display: block;
  width: 1px;
  height: 480px;
  top: 60px;
  left: 50%;
  position: absolute;
  z-index: 1000;
  margin: 0 0 0 -379px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-institutionalization .chronology:after {
    display: none;
  }
}

.l-institutionalization .chronology__year {
  padding: 10px 0;
}

.l-institutionalization .chronology__year img {
  width: 167px;
  height: 44px;
}

.l-institutionalization .chronology .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 780px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-institutionalization .chronology .inner {
    max-width: 780px;
  }
}

@media screen and (max-width: 767px) {
  .l-institutionalization .chronology .inner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.l-institutionalization .chronology .y2017 {
  background-color: #00b0f0;
}

.l-institutionalization .chronology .y2018 {
  background-color: #ffcc00;
}

.l-institutionalization .chronology .y2019 {
  background-color: #000;
}

.l-institutionalization .chronology .y2020 {
  background-color: #00b050;
}

.l-institutionalization .chronology .y2021 {
  background-color: #ff0000;
}

.l-institutionalization .chronology__detail {
  background-color: #fff;
  border-radius: 5px;
  width: 500px;
  height: 100px;
  padding: 20px;
  text-align: left;
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .l-institutionalization .chronology__detail {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
}

.l-institutionalization .chronology__detail + li {
  margin-top: 1px;
}

/* ------------------------------------------------------------ */
.l-aboutFoodpoisoning {
  max-width: 1000px;
  margin: 0 auto 160px;
}

@media screen and (max-width: 767px) {
  .l-aboutFoodpoisoning {
    max-width: 95%;
    margin: 0 auto 80px;
  }
}

.l-aboutFoodpoisoning .leadtext {
  max-width: 780px;
  margin: 0 auto 40px;
  line-height: 2;
}

.l-aboutFoodpoisoning figure {
  width: 640px;
  height: auto;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-aboutFoodpoisoning figure {
    width: 100%;
    max-width: 640px;
  }
}

@media screen and (max-width: 767px) {
  .l-aboutFoodpoisoning figure {
    width: 100%;
    max-width: 640px;
  }
}

.l-whenHappens {
  max-width: 1000px;
  margin: 0 auto 160px;
}

@media screen and (max-width: 767px) {
  .l-whenHappens {
    max-width: 95%;
    margin: 0 auto 80px;
  }
}

.l-whenHappens .leadtext {
  max-width: 780px;
  margin: 0 auto 40px;
  line-height: 2;
}

.l-whenHappens figure {
  width: 640px;
  height: auto;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-whenHappens figure {
    width: 100%;
    max-width: 640px;
  }
}

@media screen and (max-width: 767px) {
  .l-whenHappens figure {
    width: 100%;
    max-width: 640px;
  }
}

.l-causeAndMeasures {
  margin: 0 auto 80px;
}

.l-causeAndMeasures__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-causeAndMeasures__list {
    width: 100%;
    align-items: stretch;
  }
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__list {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-causeAndMeasures__item {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item {
    margin-bottom: 40px;
  }
}

.l-causeAndMeasures__item:nth-of-type(1) {
  width: 18%;
  min-width: 180px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-causeAndMeasures__item:nth-of-type(1) {
    min-width: auto;
  }
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item:nth-of-type(1) {
    width: 100%;
    min-width: auto;
  }
}

.l-causeAndMeasures__item:nth-of-type(1) ul {
  background-color: #e0caea;
  border-radius: 10px 0 0 10px;
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item:nth-of-type(1) ul {
    border-radius: 0;
  }
}

.l-causeAndMeasures__item:nth-of-type(2) {
  width: 63%;
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item:nth-of-type(2) {
    width: 100%;
  }
}

.l-causeAndMeasures__item:nth-of-type(2) ul {
  background-color: #f5f3c8;
}

.l-causeAndMeasures__item:nth-of-type(3) {
  width: 18%;
  min-width: 180px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-causeAndMeasures__item:nth-of-type(3) {
    min-width: auto;
  }
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item:nth-of-type(3) {
    width: 100%;
    min-width: auto;
  }
}

.l-causeAndMeasures__item:nth-of-type(3) ul {
  background-color: #e8e8e8;
  border-radius: 0 10px 10px 0;
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item:nth-of-type(3) ul {
    border-radius: 0;
  }
}

.l-causeAndMeasures__item h3 {
  margin: 0 0 20px;
  text-align: center;
  font-size: 1.6rem;
}

.l-causeAndMeasures__item ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  min-height: 330px;
  margin: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-causeAndMeasures__item ul {
    min-height: 88%;
  }
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item ul {
    flex-direction: column;
    min-height: auto;
    width: 100%;
  }
}

.l-causeAndMeasures__item li {
  width: 140px;
  height: 140px;
  margin: 0;
  background-color: #fff;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-causeAndMeasures__item li {
    width: 100%;
    height: 50%;
  }
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item li {
    width: 100%;
    height: auto;
  }
}

.l-causeAndMeasures__item li:nth-of-type(-n + 4) {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item li:nth-of-type(-n + 4) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-causeAndMeasures__item li + li {
    margin-top: 20px;
  }
}

.l-causeAndMeasures__item img {
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-causeAndMeasures__item:nth-of-type(2) li {
    width: 23.5%;
  }
}

.modaal-container {
  border-radius: 10px;
  width: calc( 100vw - 50px);
}

.modaal-content-container {
  display: flex;
  flex-direction: row;
  max-height: 80vh;
}

@media screen and (max-width: 767px) {
  .modaal-content-container {
    flex-direction: column;
    overflow-y: scroll;
  }
}

.modaal-content-container figure {
  width: 40%;
  height: calc(100% - 60px);
  margin: 30px 0 30px 30px;
}

@media screen and (max-width: 767px) {
  .modaal-content-container figure {
    width: 80%;
    margin: 0 auto;
  }
}

.modaal-content-container .modalBody {
  width: 60%;
  height: 80vh;
  overflow-y: scroll;
  color: #444444;
  padding: 30px 30px 30px 0;
}

@media screen and (max-width: 767px) {
  .modaal-content-container .modalBody {
    width: 100%;
    overflow-y: visible;
    padding-right: 10px;
  }
}

.modaal-content-container .modalBody h3 {
  font-size: 3.6rem;
  line-height: 1;
  margin: 0 0 20px;
  position: relative;
  padding-top: 60px;
  padding-left: 160px;
}

@media screen and (max-width: 767px) {
  .modaal-content-container .modalBody h3 {
    padding-top: 120px;
    padding-left: 0;
    text-align: center;
    margin: 0 0 10px;
  }
}

.modaal-content-container .modalBody h3:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 140px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .modaal-content-container .modalBody h3:before {
    left: calc(50% - 50px);
    font-size: 100px;
  }
}

.modaal-content-container .modalBody h3.num01:before {
  content: "01";
}

.modaal-content-container .modalBody h3.num02:before {
  content: "02";
}

.modaal-content-container .modalBody h3.num03:before {
  content: "03";
}

.modaal-content-container .modalBody h3.num04:before {
  content: "04";
}

.modaal-content-container .modalBody h3.num05:before {
  content: "05";
}

.modaal-content-container .modalBody h3.num06:before {
  content: "06";
}

.modaal-content-container .modalBody h3.num07:before {
  content: "07";
}

.modaal-content-container .modalBody h3.num08:before {
  content: "08";
}

.modaal-content-container .modalBody h3.num09:before {
  content: "09";
}

.modaal-content-container .modalBody h3.num10:before {
  content: "10";
}

.modaal-content-container .modalBody .en {
  font-size: 1.6rem;
  padding-left: 160px;
  margin: 0 0 40px;
  color: #999;
}

@media screen and (max-width: 767px) {
  .modaal-content-container .modalBody .en {
    padding-left: 0;
    text-align: center;
  }
}

.modaal-content-container .modalBody h4 {
  font-size: 1.8rem;
  border-left: solid 5px #972ab3;
  padding: 0;
  padding-left: 10px;
  margin: 0 0 20px;
  line-height: 1.5;
}

.modaal-content-container .modalBody ul {
  margin: 0 0 40px;
}

.modaal-content-container .modalBody li {
  font-size: 1.6rem;
  margin: 0 0 0 30px;
  list-style: disc;
}

/* ------------------------------------------------------------ */
.l-HygieneRelated {
  width: 100%;
  margin: 0 0 80px;
}

/* ------------------------------------------------------------ */
.l-latestIssue {
  margin: 0 auto 80px;
  max-width: 1000px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-latestIssue {
    width: 95%;
  }
}

.l-latestIssue__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2.4rem;
  margin: 0;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}

@media screen and (max-width: 767px) {
  .l-latestIssue__item {
    flex-direction: column;
  }
}

.l-latestIssue__vol {
  margin: 0;
  padding: 10px;
  background-color: #07ace9;
  color: #fff;
  width: 20%;
}

@media screen and (max-width: 767px) {
  .l-latestIssue__vol {
    width: 100%;
  }
}

.l-latestIssue__title {
  margin: 0 0 0 20px;
  padding: 10px;
  width: 60%;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .l-latestIssue__title {
    width: 95%;
    margin: 5px auto 0;
  }
}

.l-latestIssue__time {
  margin: 0 0 0 20px;
  padding: 10px;
  width: 20%;
}

@media screen and (max-width: 767px) {
  .l-latestIssue__time {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    font-size: 1.6rem;
    padding: 0 10px 10px;
  }
}

.l-latestIssue a {
  color: #07ace9;
}

.l-latestIssue a i.fa {
  color: #ccc;
  margin-left: .5em;
}

.l-backNumber {
  margin: 0 auto 80px;
  max-width: 1000px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-backNumber {
    width: 95%;
  }
}

.l-backNumber__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2.4rem;
  margin: 0;
  border-top: solid 1px #ccc;
}

@media screen and (max-width: 767px) {
  .l-backNumber__item {
    flex-direction: column;
  }
}

.l-backNumber__item:last-child {
  border-bottom: solid 1px #ccc;
}

.l-backNumber__vol {
  margin: 0;
  padding: 10px;
  width: 20%;
  background-color: #eee;
}

@media screen and (max-width: 767px) {
  .l-backNumber__vol {
    width: 100%;
  }
}

.l-backNumber__title {
  margin: 0 0 0 20px;
  padding: 10px;
  width: 60%;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .l-backNumber__title {
    width: 95%;
    margin: 5px auto 0;
  }
}

.l-backNumber__time {
  margin: 0 0 0 20px;
  padding: 10px;
  width: 20%;
}

@media screen and (max-width: 767px) {
  .l-backNumber__time {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    font-size: 1.6rem;
    padding: 0 10px 10px;
  }
}

.l-backNumber a {
  color: #07ace9;
}

.l-backNumber a i.fa {
  color: #ccc;
  margin-left: .5em;
}

/* ------------------------------------------------------------ */
/* =========================================================
section
========================================================= */
.l-recruit h3 {
  font-size: 4.8rem;
  line-height: 1;
  margin: 0 0 40px;
  position: relative;
  z-index: 1000;
}

.l-recruit h3 + p {
  font-size: 2.4rem;
  font-style: italic;
  margin: 0 0;
  position: relative;
  z-index: 1000;
}

@media screen and (max-width: 767px) {
  .l-recruit h3 + p {
    font-size: 1.8rem;
  }
}

.l-recruit .recruitImage {
  margin-top: -80px;
  margin-bottom: 80px;
  padding: 200px 0;
  background: url(/images/recruit/recruit_img_01.jpg) center no-repeat;
  background-size: cover;
  color: #fff;
  position: relative;
  z-index: 100;
}

@media screen and (max-width: 767px) {
  .l-recruit .recruitImage {
    padding: 120px 0;
  }
}

.l-recruit .recruitImage:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0039ca;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0dadf1+0,0039ca+50,09c0a0+100 */
  background: #0dadf1;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #0dadf1 0%, #0039ca 50%, #09c0a0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #0dadf1 0%, #0039ca 50%, #09c0a0 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #0dadf1 0%, #0039ca 50%, #09c0a0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0dadf1', endColorstr='#09c0a0',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  opacity: .83;
  z-index: 120;
}

.l-recruit .recruitImage svg {
  fill: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: calc(50% - 111px);
  left: calc(50% - 226px);
  z-index: 140;
}

.l-recruit .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1% 1%;
  margin: 0 0 80px;
}

@media screen and (max-width: 767px) {
  .l-recruit .wrapper {
    padding: 0 2.5% 2.5%;
    flex-direction: column;
  }
}

.l-recruit .wrapper .leadtext {
  width: 98%;
  text-align: center;
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  .l-recruit .wrapper .leadtext {
    width: 95%;
    text-align: left;
    margin: 0 auto 20px;
  }
}

.l-recruit .wrapper .block {
  width: 49.5%;
  padding: 80px 20px;
}

@media screen and (max-width: 767px) {
  .l-recruit .wrapper .block {
    width: 100%;
  }
}

.l-recruit .salesNclerk,
.l-recruit .researcher {
  position: relative;
  z-index: 0;
}

.l-recruit .salesNclerk:after,
.l-recruit .researcher:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  border: solid 1px #eee;
}

.l-recruit .salesNclerk h4,
.l-recruit .researcher h4 {
  font-size: 2.4rem;
  margin: 0 0 10px;
  line-height: 1;
  position: relative;
  z-index: 10;
}

.l-recruit .salesNclerk .en,
.l-recruit .researcher .en {
  font-size: 1.4rem;
  color: #999;
  margin: 0;
  line-height: 1;
  position: relative;
  z-index: 10;
}

.l-recruit .salesNclerk ul,
.l-recruit .researcher ul {
  margin: 40px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  z-index: 10;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-recruit .salesNclerk ul,
  .l-recruit .researcher ul {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .l-recruit .salesNclerk ul,
  .l-recruit .researcher ul {
    flex-direction: column;
    align-items: center;
  }
}

.l-recruit .salesNclerk li,
.l-recruit .researcher li {
  font-size: 1.8rem;
  width: 240px;
  line-height: 2;
}

.l-recruit .salesNclerk li + li,
.l-recruit .researcher li + li {
  margin-left: 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-recruit .salesNclerk li + li,
  .l-recruit .researcher li + li {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-recruit .salesNclerk li + li,
  .l-recruit .researcher li + li {
    margin-left: 0;
    margin-top: 20px;
  }
}

.l-recruit .salesNclerk li a,
.l-recruit .researcher li a {
  height: 60px;
  border-radius: 3px;
  background-color: #07ace9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #fff;
  padding: 10px 5px;
  position: relative;
  line-height: 1;
}

.l-recruit .salesNclerk li a:after,
.l-recruit .researcher li a:after {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 2.4rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .5em);
  line-height: 1;
}

.l-recruit .salesNclerk {
  background: url(/images/recruit/salesnclerk_bg.jpg) center no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .l-recruit .salesNclerk {
    margin-bottom: 20px;
  }
}

.l-recruit .researcher {
  background: url(/images/recruit/researcher_bg.jpg) center no-repeat;
  background-size: cover;
}

/* ------------------------------------------------------------ */
.l-salesnclerk .leadtext,
.l-researcher .leadtext {
  max-width: 1000px;
  margin: 0 auto 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-salesnclerk .leadtext,
  .l-researcher .leadtext {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-salesnclerk .leadtext,
  .l-researcher .leadtext {
    max-width: 95%;
  }
}

.l-salesnclerk .recruitMenu,
.l-researcher .recruitMenu {
  max-width: 1000px;
  margin: 40px auto 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-salesnclerk .recruitMenu,
  .l-researcher .recruitMenu {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-salesnclerk .recruitMenu,
  .l-researcher .recruitMenu {
    max-width: 95%;
    flex-direction: column;
  }
}

.l-salesnclerk .recruitMenu__item,
.l-researcher .recruitMenu__item {
  font-size: 1.8rem;
  text-align: left;
  width: calc((100% - 40px ) / 2);
  padding: 20px;
  background-color: #f6f6f6;
}

@media screen and (max-width: 767px) {
  .l-salesnclerk .recruitMenu__item,
  .l-researcher .recruitMenu__item {
    width: 100%;
  }
}

.l-salesnclerk .recruitMenu__item + li,
.l-researcher .recruitMenu__item + li {
  margin-left: 40px;
}

@media screen and (max-width: 767px) {
  .l-salesnclerk .recruitMenu__item + li,
  .l-researcher .recruitMenu__item + li {
    margin-left: 0;
    margin-top: 10px;
  }
}

.l-salesnclerk .recruitMenu__item span,
.l-researcher .recruitMenu__item span {
  display: block;
}

.l-salesnclerk .recruitMenu__item ul,
.l-researcher .recruitMenu__item ul {
  margin: 5px 0 0;
}

.l-salesnclerk .recruitMenu__item ul li,
.l-researcher .recruitMenu__item ul li {
  display: inline-block;
}

.l-salesnclerk .recruitMenu__item ul li + li,
.l-researcher .recruitMenu__item ul li + li {
  margin-left: 20px;
}

.l-salesnclerk .recruitMenu a,
.l-researcher .recruitMenu a {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 20px;
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  color: #07ace9;
}

.l-salesnclerk h3,
.l-researcher h3 {
  border-left: solid 5px #07ace9;
  margin: 0 auto;
  padding: 0 0 0 10px;
  font-size: 2.4rem;
  max-width: 1000px;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-salesnclerk h3,
  .l-researcher h3 {
    max-width: 95%;
  }
}

.l-salesnclerk h4,
.l-researcher h4 {
  margin: 0 auto 20px;
  font-size: 1.8rem;
  max-width: 1000px;
  text-align: left;
  color: #07ace9;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-salesnclerk h4,
  .l-researcher h4 {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-salesnclerk h4,
  .l-researcher h4 {
    max-width: 95%;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-salesnclerk__table,
  .l-researcher__table {
    max-width: 95%;
  }
}

.l-salesnclerk .entry,
.l-researcher .entry {
  margin: 40px auto 80px;
  max-width: 1000px;
  font-size: 1.8rem;
  line-height: 2;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-salesnclerk .entry,
  .l-researcher .entry {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-salesnclerk .entry,
  .l-researcher .entry {
    max-width: 95%;
  }
}

.l-salesnclerk .entry a,
.l-researcher .entry a {
  display: block;
  text-align: center;
  padding: 15px 10px;
  background-color: #07ace9;
  color: #fff;
  font-weight: bold;
  line-height: 1;
}

.l-salesnclerk .entry a span,
.l-researcher .entry a span {
  display: block;
  font-size: 1rem;
  margin-top: 5px;
}

@media screen and (min-width: 1000px) {
  .l-salesnclerk .entry a:hover,
  .l-researcher .entry a:hover {
    background-color: #0687b7;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-salesnclerk .entry a:hover,
  .l-researcher .entry a:hover {
    background-color: #07ace9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (max-width: 767px) {
  .l-salesnclerk .entry a,
  .l-researcher .entry a {
    background-color: #07ace9;
  }
}

.l-salesnclerk .contactus,
.l-researcher .contactus {
  margin: 40px auto 80px;
  max-width: 1000px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-salesnclerk .contactus,
  .l-researcher .contactus {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-salesnclerk .contactus,
  .l-researcher .contactus {
    max-width: 95%;
  }
}

.l-salesnclerk .contactus h4,
.l-researcher .contactus h4 {
  border-bottom: solid 1px #ccc;
  margin: 0 0 20px;
  padding-bottom: 20px;
}

.l-salesnclerk .contactus p,
.l-researcher .contactus p {
  font-size: 1.8rem;
  text-align: left;
}

.l-salesnclerk .contactus a:hover,
.l-researcher .contactus a:hover {
  color: #444444;
}

.l-salesnclerk .contactus .arrow,
.l-researcher .contactus .arrow {
  display: inline-block;
  margin-top: 10px;
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  color: #07ace9;
}

.l-salesnclerk .contactus .arrow:hover,
.l-researcher .contactus .arrow:hover {
  color: #07ace9;
}

/* =========================================================
section
========================================================= */
.l-news .inner {
  max-width: 1000px;
  margin: 0 auto;
  margin: 0 auto 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-news .inner {
    width: 95%;
    margin: 0 auto 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-news .inner {
    width: 95%;
    margin: 0 auto 40px;
  }
}

/* =========================================================
privacyPolicy
========================================================= */
.l-pickup {
  margin: 0 0 10px;
}

.l-pickup .leadtext {
  max-width: 1000px;
  margin: 0 auto 80px;
  line-height: 2;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .l-pickup .leadtext {
    width: 90%;
  }
}

.l-pickup .inner {
  max-width: 1000px;
  margin: 0 auto;
}

.l-pickup__new {
  background-color: #e3f5ff;
  padding: 80px 0 160px;
}

.l-pickup__hygieneRelated {
  background-color: #e3f5ff;
  padding: 80px 0;
}

.l-pickup h3 {
  font-size: 3rem;
  font-weight: 500;
  margin: 0 0 10px;
  line-height: 1;
}

.l-pickup .en {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0 0 40px;
  line-height: 1;
  color: #999;
  letter-spacing: .25em;
}

.l-pickup__products {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  margin: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-pickup__products {
    width: 95%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .l-pickup__products {
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
  }
}

.l-pickup__products:after {
  content: "";
  display: block;
  width: 32%;
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  .l-pickup__products:after {
    display: none;
  }
}

.l-pickup__item {
  width: 32%;
  height: auto;
  background-color: #fff;
  margin: 0 0 20px;
  padding: 20px;
  text-align: left;
}

.l-pickup__item:hover {
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.1);
  transition: box-shadow .5s ease 0s;
}

@media screen and (max-width: 767px) {
  .l-pickup__item {
    width: 100%;
  }
}

.l-pickup__item a {
  color: #07ace9;
}

.l-pickup__item a i.fa {
  color: #ccc;
  margin-left: 5px;
}

.l-pickup figure {
  margin: 0 0 20px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.l-pickup figure img {
  width: auto;
  height: auto;
  max-width: 280px;
  max-height: 210px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-pickup figure img {
    max-width: 200px;
  }
}

@media screen and (max-width: 767px) {
  .l-pickup figure img {
    max-width: 240px;
  }
}

.l-pickup h4 {
  font-size: 1.8rem;
  line-height: 1.125;
  font-weight: 500;
  margin: 0 0 10px;
}

.l-pickup .usecase {
  font-size: 1.4rem;
  margin: 0 0 10px;
}

.l-pickup .usecase span {
  display: inline-block;
  background-color: #07ace9;
  color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
  text-align: center;
  margin-right: 10px;
  font-size: 1.2rem;
}

.l-pickup .feature {
  font-size: 1.6rem;
  margin: 0;
}

/* =========================================================
section
========================================================= */
.l-faq .leadtext {
  max-width: 780px;
  margin: 0 auto;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-faq .leadtext {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-faq .leadtext {
    max-width: 95%;
    text-align: left;
  }
}

.l-faq .inner {
  max-width: 780px;
  margin: 0 auto 80px;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-faq .inner {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-faq .inner {
    max-width: 95%;
  }
}

.l-faq__list {
  max-width: 780px;
  text-align: left;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-faq__list {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-faq__list {
    max-width: 95%;
  }
}

.l-faq__item {
  margin: 0 auto 80px;
}

.l-faq h3 {
  border-bottom: solid 1px #ccc;
  font-size: 2.4rem;
}

.l-faq p {
  font-size: 1.8rem;
}

.l-faq a {
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  font-size: 1.8rem;
  display: inline-block;
  color: #07ace9;
}

.l-faq a + ul {
  margin: 10px 0 0 20px;
}

.l-faq a + ul a {
  font-size: 1.6rem;
}

/* ------------------------------------------------------------ */
.l-firstaid {
  margin-bottom: 80px;
}

.l-firstaid .case {
  max-width: 1000px;
  margin: 0 auto 20px;
  text-align: left;
}

.l-firstaid h3 {
  font-size: 2.4rem;
  border-left: solid 5px #07ace9;
  padding: 0 0 0 10px;
  color: #07ace9;
  cursor: pointer;
}

@media screen and (min-width: 1000px) {
  .l-firstaid h3:hover {
    background-color: #e3f5ff;
  }
}

.l-firstaid h4 {
  font-size: 1.8rem;
  margin: 0;
  padding: 10px 10px 20px;
  line-height: 1;
}

.l-firstaid .acd + .wrapper {
  display: none;
}

.l-firstaid .block {
  background: #e3f5ff;
  padding: 10px;
  border-radius: 5px;
}

.l-firstaid .block + .block {
  margin-top: 20px;
}

.l-firstaid ul {
  font-size: 1.8rem;
  background-color: #fff;
  margin: 0;
  padding: 10px;
}

.l-firstaid ul + h4 {
  padding: 20px 10px;
}

.l-firstaid li {
  margin-left: 2em;
  text-indent: -1.5em;
}

.l-firstaid li + li {
  margin-top: .5em;
}

/* =========================================================
privacyPolicy
========================================================= */
.l-privacypolicy {
  margin: 0 0 80px;
}

.l-privacypolicy .leadtext {
  max-width: 780px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-privacypolicy .leadtext {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-privacypolicy .leadtext {
    max-width: 95%;
  }
}

.l-privacypolicy__list {
  max-width: 780px;
  margin: 80px auto 0;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-privacypolicy__list {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-privacypolicy__list {
    max-width: 95%;
  }
}

.l-privacypolicy__item {
  margin: 0;
  font-size: 1.8rem;
}

.l-privacypolicy__item h3 {
  border-bottom: solid 1px #ccc;
  font-size: 2.4rem;
  padding-left: 1.50em;
}

.l-privacypolicy__item h3:first-letter {
  font-size: 1.8rem;
  margin-right: .9em;
  margin-left: -1.8em;
}

.l-privacypolicy__item li {
  margin: 0 0 0 40px;
  list-style-type: decimal;
}

.l-privacypolicy__item li + li {
  margin-top: 10px;
}

.l-privacypolicy .lastsentence {
  text-align: right;
  font-size: 1.8rem;
  max-width: 780px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-privacypolicy .lastsentence {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-privacypolicy .lastsentence {
    max-width: 95%;
  }
}

/* =========================================================
section
========================================================= */
.l-entry .leadtext,
.l-inquiry .leadtext {
  max-width: 780px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-entry .leadtext,
  .l-inquiry .leadtext {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-entry .leadtext,
  .l-inquiry .leadtext {
    max-width: 95%;
  }
}

.l-entry .inner,
.l-inquiry .inner {
  max-width: 780px;
  margin: 0 auto 80px;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-entry .inner,
  .l-inquiry .inner {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-entry .inner,
  .l-inquiry .inner {
    max-width: 95%;
  }
}

.l-entry .inquiryType + .inquiryType,
.l-inquiry .inquiryType + .inquiryType {
  margin-top: 80px;
}

.l-entry h3,
.l-inquiry h3 {
  border-bottom: solid 1px #ccc;
  font-size: 2.4rem;
}

.l-entry p,
.l-inquiry p {
  font-size: 1.8rem;
}

.l-entry a,
.l-inquiry a {
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  color: #07ace9;
}

.l-entry table,
.l-inquiry table {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-entry table,
  .l-inquiry table {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-entry table tbody,
  .l-inquiry table tbody {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-entry table tr,
  .l-inquiry table tr {
    width: 100%;
    display: block;
  }
}

.l-entry table th,
.l-inquiry table th {
  width: 31.25%;
}

@media screen and (max-width: 767px) {
  .l-entry table th,
  .l-inquiry table th {
    width: 100%;
    display: block;
    background-color: #eee;
  }
}

.l-entry table td,
.l-inquiry table td {
  width: 68.75%;
}

@media screen and (max-width: 767px) {
  .l-entry table td,
  .l-inquiry table td {
    width: 100%;
    display: block;
    padding: 20px 20px 40px;
  }
}

.l-entry table input,
.l-entry table textarea,
.l-entry table select,
.l-inquiry table input,
.l-inquiry table textarea,
.l-inquiry table select {
  padding: 9px;
  background-color: #fff;
  border: solid 1px #07ace9;
  border-radius: 3px;
  font-size: 1.8rem;
  line-height: 1;
  width: 100%;
  color: #444444;
}

.l-entry table input + span,
.l-entry table textarea + span,
.l-entry table select + span,
.l-inquiry table input + span,
.l-inquiry table textarea + span,
.l-inquiry table select + span {
  display: block;
  margin-top: 10px;
  font-size: 1.4rem;
}

.l-entry table select,
.l-inquiry table select {
  height: 2.4em;
  margin-bottom: 2em;
}

.l-entry table textarea,
.l-inquiry table textarea {
  min-height: 5em;
}

.l-entry table .required,
.l-inquiry table .required {
  background-color: #e61f18;
  color: #fff;
  font-size: 1rem;
  padding: 3px 6px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  position: relative;
  top: -2px;
}

.l-entry .block--submit,
.l-inquiry .block--submit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.l-entry .button,
.l-inquiry .button {
  height: 40px;
  background-color: #07ace9;
  color: #fff;
  line-height: 40px;
  margin: 20px 0 0;
  padding: 0 20px;
  cursor: pointer;
}

@media screen and (min-width: 1000px) {
  .l-entry .button:hover,
  .l-inquiry .button:hover {
    background-color: #0687b7;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-entry .button:hover,
  .l-inquiry .button:hover {
    background-color: #07ace9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (max-width: 767px) {
  .l-entry .button,
  .l-inquiry .button {
    background-color: #07ace9;
  }
}

.l-entry label,
.l-inquiry label {
  width: 48%;
  display: flex;
  padding: 9px;
  border-radius: 3px;
  cursor: pointer;
}

.l-entry .radiogroup,
.l-inquiry .radiogroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.l-entry .radiogroup input[type=radio],
.l-inquiry .radiogroup input[type=radio] {
  display: none;
}

.l-entry .radiogroup input[type=radio] + label,
.l-inquiry .radiogroup input[type=radio] + label {
  background-color: #f9f9f9;
  border: solid 1px #eee;
}

.l-entry .radiogroup input[type=radio] + label + input,
.l-inquiry .radiogroup input[type=radio] + label + input {
  pointer-events: none;
  background-color: #f9f9f9;
  border: solid 1px #eee;
}

.l-entry .radiogroup input[type=radio]:checked + label,
.l-inquiry .radiogroup input[type=radio]:checked + label {
  background-color: #07ace9;
  border: solid 1px #07ace9;
  color: #fff;
}

.l-entry .radiogroup input[type=radio]:checked + label + input,
.l-inquiry .radiogroup input[type=radio]:checked + label + input {
  background-color: #fff;
  border: solid 1px #07ace9;
  pointer-events: auto;
}

.l-entry .otherRegion,
.l-inquiry .otherRegion {
  margin-top: 20px;
}

.l-entry table select {
  margin: 0;
}

.l-entry .graduation {
  margin-top: 20px;
}

.l-entry .half {
  width: 50%;
  margin-right: .5em;
}

@media screen and (max-width: 767px) {
  .l-entry .half {
    width: 70%;
  }
}

.l-entry .tableTitle {
  border-bottom: none;
  font-size: 2.4rem;
  margin: 80px 0 0;
}

.l-confirm .leadtext {
  margin: 0 auto 40px;
}

.l-confirm table p {
  padding: 10px 0;
  background-color: #fff;
  font-size: 1.8rem;
  line-height: 1;
  width: 100%;
  color: #444444;
}

.l-confirm table h4 {
  font-size: 1.8rem;
  border-bottom: solid 1px #ccc;
}

.l-confirm .block--confirm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.l-confirm .block--confirm .button + .button {
  margin-left: 20px;
}

.l-confirm .button--back {
  background-color: #444444;
}

@media screen and (min-width: 1000px) {
  .l-confirm .button--back:hover {
    background-color: #2b2b2b;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-confirm .button--back:hover {
    background-color: #444444;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (max-width: 767px) {
  .l-confirm .button--back {
    background-color: #444444;
  }
}

/* =========================================================
section
========================================================= */
.l-sitemap .layer--top {
  max-width: 1000px;
  margin: 0 auto 80px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-sitemap .layer--top {
    max-width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .l-sitemap .layer--top {
    max-width: 95%;
    flex-direction: column;
  }
}

.l-sitemap .layer--top h3 {
  width: 100%;
  margin: 0;
  margin-bottom: 40px;
}

.l-sitemap .layer--top h3 a {
  display: inline-block;
  width: 100%;
}

.l-sitemap .layer--top h3 img {
  width: auto;
  height: auto;
}

@media screen and (max-width: 767px) {
  .l-sitemap .layer--top h3 img {
    max-width: 80%;
  }
}

.l-sitemap .layer--top i {
  display: none;
}

.l-sitemap .layer--secondary {
  width: 48%;
}

@media screen and (max-width: 767px) {
  .l-sitemap .layer--secondary {
    width: 100%;
  }
}

.l-sitemap .layer--secondary h4 {
  border-bottom: solid 1px #ccc;
  font-size: 2.4rem;
  margin: 0;
  padding: 0;
}

.l-sitemap .layer--secondary > li {
  margin-bottom: 20px;
}

.l-sitemap .layer--tertiary {
  font-size: 1.8rem;
  margin: 20px 0 0 20px;
}

.l-sitemap .layer--tertiary h5 {
  border-bottom: solid 1px #ccc;
  font-size: 1.8rem;
  margin: 0;
  padding: 0;
}

.l-sitemap .layer--quaternary {
  font-size: 1.4rem;
  margin: 20px 0 20px 20px;
}

.l-sitemap .aca h4 {
  border: none;
}

.l-sitemap .aca img {
  width: auto;
  height: auto;
}

/* =========================================================
section
========================================================= */
.login .globalWrapper {
  background-color: #eee;
  background: url(/images/home/water_bg.png);
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  min-height: calc(100vh - 63px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}

.loginbox {
  width: 640px;
  min-height: 400px;
  background-color: #fff;
  padding: 40px 0;
  box-shadow: 4px 0 32px rgba(0, 0, 0, 0.15);
  border-top: solid 5px #07ace9;
}

@media screen and (max-width: 767px) {
  .loginbox {
    width: 100%;
  }
}

.loginbox img {
  width: 80px;
  height: auto;
  margin: 0 auto 20px;
}

.loginbox h1 {
  font-size: 2.4rem;
  margin: 0 0 20px;
  padding: 0;
}

.firstTime__entry {
  display: none;
}

.firstTime .firstTime__entry {
  display: block;
}

.formRow {
  border-top: solid 1px #ccc;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 20px;
  text-align: left;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .formRow {
    flex-direction: column;
    margin: 0;
  }
}

.formRow .title {
  width: 30%;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .formRow .title {
    width: 100%;
    background-color: #eee;
  }
}

.formRow .input {
  width: 70%;
  padding: 10px 10px 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .formRow .input {
    width: 100%;
    padding: 10px 10px 20px;
  }
}

.formRow input {
  border: solid 1px #ccc;
  font-size: 1.8rem;
  padding: 5px;
  width: 100%;
  border-radius: 3px;
}

.formRow input[data-input-name="name"] {
  width: calc((100% - 10px) / 2);
}

.formRow input[data-input-name="kana"] {
  width: calc((100% - 10px) / 2);
}

.formRow.submit {
  justify-content: center;
}

.formRow .error {
  font-size: 1.4rem;
  color: #e61f18;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
}

.formRow .error + span {
  margin-top: 5px;
}

.formRow .button {
  height: 40px;
  background-color: #07ace9;
  color: #fff;
  line-height: 40px;
  margin: 20px 0 0;
  padding: 0 20px;
  cursor: pointer;
}

@media screen and (min-width: 1000px) {
  .formRow .button:hover {
    background-color: #0687b7;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .formRow .button:hover {
    background-color: #07ace9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (max-width: 767px) {
  .formRow .button {
    background-color: #07ace9;
  }
}

.backtoHome {
  text-align: left;
  margin-left: 20px;
  font-size: 1.4rem;
}

.backtoHome i {
  margin-right: 5px;
}

.l-mypage__browse, .l-mypage__favorite {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__browse, .l-mypage__favorite {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__browse, .l-mypage__favorite {
    width: calc(100% - 20px);
  }
}

.l-mypage__list {
  clear: both;
  margin: 0 0 0;
}

.l-mypage__item {
  text-align: left;
  min-height: 60px;
  border-bottom: solid 1px #ddd;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 50px;
  font-size: 1.6rem;
}

.l-mypage__item:first-child {
  border-top: solid 1px #ddd;
}

@media screen and (max-width: 767px) {
  .l-mypage__item {
    padding: 18px 0;
    flex-wrap: wrap;
  }
}

.l-mypage__favorite .l-mypage__item {
  padding: 0;
}

.l-mypage__browse {
  padding: 0 0 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__browse {
    padding: 40px 0 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__browse {
    padding: 40px 0 40px;
  }
}

.l-mypage__browse .l-mypage__date {
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: -.025em;
  min-width: 5em;
}

@media screen and (max-width: 767px) {
  .l-mypage__browse .l-mypage__date {
    display: block;
    margin-left: 10px;
  }
}

.l-mypage__browse .l-mypage__date img {
  width: 80px;
  height: auto;
}

.l-mypage__browse .l-mypage__description {
  display: block;
  font-size: 1.6rem;
  margin: 0 0 0 10px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .l-mypage__browse .l-mypage__description {
    margin: 0;
    margin-top: calc(18px - .25em);
    padding: 0 10px;
    flex-basis: 100%;
  }
}

.l-mypage__favorite {
  padding: 80px 0 80px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__favorite {
    padding: 40px 0 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__favorite {
    padding: 40px 0 40px;
  }
}

.l-mypage__favorite .l-mypage__date {
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: -.025em;
  width: 80px;
}

.l-mypage__favorite .l-mypage__date img {
  width: 80px;
  height: auto;
}

.l-mypage__favorite .l-mypage__description {
  display: block;
  font-size: 1.6rem;
  margin: 0 0 0 10px;
  width: calc(100% - 180px);
  word-break: break-all;
  padding-right: 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__favorite .l-mypage__description {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__favorite .l-mypage__description {
    padding-right: 10px;
  }
}

.l-mypage__delete .button, .l-mypage__detail .button {
  height: 40px;
  color: #fff;
  line-height: 40px;
  padding: 0 10px;
  cursor: pointer;
}

@media screen and (min-width: 1000px) {
  .l-mypage__delete .button:hover, .l-mypage__detail .button:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__delete .button:hover, .l-mypage__detail .button:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
  }
}

.l-mypage__delete .button i, .l-mypage__detail .button i {
  margin-right: 5px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__delete .button i, .l-mypage__detail .button i {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__delete .button i, .l-mypage__detail .button i {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__delete .button span, .l-mypage__detail .button span {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__delete .button span, .l-mypage__detail .button span {
    display: none;
  }
}

.l-mypage__delete .button--delete {
  background-color: #444444;
  width: 80px;
}

@media screen and (min-width: 1000px) {
  .l-mypage__delete .button--delete:hover {
    background-color: #2b2b2b;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__delete .button--delete {
    width: 40px;
    border-radius: 20px;
  }
  .l-mypage__delete .button--delete:hover {
    background-color: #444444;
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__delete .button--delete {
    background-color: #444444;
    width: 40px;
    border-radius: 20px;
  }
}

.l-mypage__detail .button--detail {
  background-color: #07ace9;
  width: 80px;
  margin-left: 20px;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .l-mypage__detail .button--detail:hover {
    background-color: #0687b7;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__detail .button--detail {
    width: 40px;
    border-radius: 20px;
    margin-left: 10px;
  }
  .l-mypage__detail .button--detail:hover {
    background-color: #07ace9;
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__detail .button--detail {
    width: 40px;
    border-radius: 20px;
    margin-left: 10px;
    background-color: #07ace9;
  }
}

.l-mypage__logout {
  margin: 0 auto 80px;
}

.l-mypage__logout .button--logout {
  background-color: #07ace9;
  text-align: center;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
}

@media screen and (min-width: 1000px) {
  .l-mypage__logout .button--logout:hover {
    background-color: #0687b7;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-mypage__logout .button--logout:hover {
    background-color: #07ace9;
  }
}

@media screen and (max-width: 767px) {
  .l-mypage__logout .button--logout {
    background-color: #07ace9;
  }
}

.l-anet .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 0 80px;
}

.l-anet .anetMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 40px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-anet .anetMenu {
    width: 95%;
    margin: 0 auto 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-anet .anetMenu {
    width: 95%;
    margin: 0 auto 40px;
  }
}

.l-anet .anetMenu ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .l-anet .anetMenu ul {
    flex-direction: column;
  }
}

.l-anet .anetMenu ul li {
  width: 23%;
  background-color: #e3f5ff;
  padding: 20px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .l-anet .anetMenu ul li {
    width: 49%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-anet .anetMenu ul li {
    width: 100%;
    margin-bottom: 20px;
  }
}

.l-anet .anetMenu ul p {
  font-size: 1.2rem;
  line-height: 1.25;
  margin: 10px 0 0;
}

.l-anet .anetMenu a {
  font-size: 1.8rem;
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
  color: #07ace9;
}

.l-anet .anetHead {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px;
}

.l-anet .anetHead p {
  font-size: 1.8rem;
  text-align: left;
}

.l-anet .anetHead img {
  width: 80px;
  margin-right: 20px;
}
