@charset "utf-8";
// ==========================================================================
// Thema SCSS
// ==========================================================================
/*
* Project Name: ADEKA クリーンエイド株式会社
* Site URI:
* Description: ADEKA クリーンエイド株式会社 style
* Version: 1.0.0
* Author: ADEKA クリーンエイド株式会社
* CSS: thema.css
*/

// ==========================================================================
// Declaration of variables
// ==========================================================================

@import "_foundation/variables";

// ==========================================================================
// Import SCSS
// ==========================================================================

@import "_thema/main";

@import "_thema/home";
@import "_thema/products";
@import "_thema/business";
@import "_thema/company";
@import "_thema/sanitation";
@import "_thema/recruit";

@import "_thema/news";
@import "_thema/pickup";
@import "_thema/faq";

@import "_thema/privacypolicy";
@import "_thema/inquiry";
@import "_thema/sitemap";

@import "_thema/aca";
