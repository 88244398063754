
/* =========================================================
company
========================================================= */

.l-company {
  margin: 0 0 120px;

  @include mq(md) {
    margin: 0 0 80px;
  }

  @include mq(sm) {
    margin: 0 0 40px;
  }

  &__list {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    z-index: 1;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      flex-direction: column;
      max-width: 95%;
    }

    &:after {
      content: "";
      display: none;
      background-color: #e3f5ff;
      width: 92%;
      height: calc(100% - 40px);
      position: absolute;
      top: 40px;
      left: 4%;
      z-index: -1;

      @include mq(md) {
        width: 90%;
        left: 5%;
      }

      @include mq(sm) {
        width: 90%;
        left: 5%;
      }
    }
  }

  &__item {
    width: 48%;
    height: auto;
    position: relative;
    margin: 0 0 40px;

    @include mq(md) {
      margin: 0 0 4%;
    }

    @include mq(sm) {
      width: 95%;
      height: 100%;
      margin: 0 2.5% 2.5%;
    }

    &:nth-of-type(even) {
      margin-left: 4%;

      @include mq(md) {
      }

      @include mq(sm) {
        margin-left: 2.5%;
      }
    }
  }

  &__label {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    letter-spacing: -.015em;

    h3 {
      display: inline-block;
      line-height: 1;
      margin: 0 10px -.2em 0;
      font-size: $FONT-SIZE-48;
      font-weight: bold;
      text-shadow: 0 0 16px rgba(0,0,0,.5);

      @include mq(md) {
        font-size: calc(9.6em + 12 * (100vw - 768px) / 232)
      }

      @include mq(sm) {
        font-size: calc(4.8em + 48 * (100vw - 320px) / 448)
      }
    }
    p {
      display: inline-block;
      line-height: 1;
      margin: 0;
      font-size: $FONT-SIZE-18;
      font-weight: bold;
      text-shadow: 0 0 16px rgba(0,0,0,.5);
      position: relative;
      bottom: -.2em;

      @include mq(md) {
        font-size: calc(3.6em + 6 * (100vw - 768px) / 232)
      }

      @include mq(sm) {
        font-size: calc(1.8em + 18 * (100vw - 320px) / 448)
      }
    }
  }
}










/* =========================================================
overview
========================================================= */

.companyInfo {

  &__lead {
    font-size: $FONT-SIZE-18;
    margin-bottom: 40px;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__item {
    width: 460px;
    height: 260px;
    margin: 0 0 40px;
    font-size: $FONT-SIZE-14;
    font-weight: bold;
    display: block;

    @include mq(md) {
      width: 46.875%;
      height: auto;
    }

    @include mq(sm) {
      width: 100%;
      height: auto;
    }

    a {
      display: block;
      padding: 20px 20px 0;
      height: 100%;
      background-color: #eee;

      @include mq(md) {
        padding: 20px;
      }

      @include mq(sm) {
        padding: 20px;
      }

      &:hover {
        background-color: #ddd;
      }
    }

    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

/* ------------------------------------------------------------ */

.wrapper {
  width: 100%;
}

.l-overview {
  &__table {
    tr {}
    th {}
    td {}
  }
}

.l-affiliated {
  &__table {
    tr {}
    th {}
    td {}
  }
  p {
    a {}
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: solid 1px #ccc;
    padding: 0 0 10px;

    @include mq(md) {
      flex-direction: column;
    }

    @include mq(sm) {
      flex-direction: column;
    }
    a ,
    span {
      width: 50%;
      display: block;

      @include mq(md) {
        width: 100%;
      }

      @include mq(sm) {
        width: 100%;
      }
    }
    & + li {
      padding-top: 10px;
    }
  }
}

.l-history {
  &__table {
    tr {}
    th {}
    td {}
  }
}

/* =========================================================
philosophy
========================================================= */

.l-philosophy {
  margin: 0 0 160px;

  &__slogan {
    width: 462px;
    margin: 80px auto 0;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 80%;
    }
  }
}

.slogan {
  margin: 80px auto 0;
  display: inline-block;

  @include mq(md) {
  }

  @include mq(sm) {
    width: 85%;
    margin: 80px 5% 0 10%;
  }

  &__item {
    font-size: $FONT-SIZE-18;
    text-align: left;
    line-height: 2;
    list-style-type: disc;
  }
}

.l-behavioralGuidelines {
  display: inline-block;
  margin: 0 0 80px;

  @include mq(md) {
    width: 100%;
  }

  @include mq(sm) {
    width: 100%;
  }

  &__item {
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: $FONT-SIZE-24;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 780px;

    @include mq(md) {
      width: 100%;
    }

    @include mq(sm) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 0 5%;
    }

    & + li {
      margin-top: 1em;
      padding-top: 1em;
      border-top: solid 1px #eee;
    }

    h3 {
      line-height: 1.5;
      margin: 0;
      padding: 0;
      font-weight: 500;
      &::first-letter {
        font-size: $FONT-SIZE-18;
        margin-right: 1em;
      }

      @include mq(md) {
      }

      @include mq(sm) {
        text-align: left;
      }
    }

    p {
      font-size: $FONT-SIZE-18;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      width: 420px;

      @include mq(md) {
        width: 48%;
      }

      @include mq(sm) {
        width: calc(100% - 2em);
        margin-left: 2em;
      }
    }
  }
}










/* =========================================================
access office
========================================================= */

.l-gmap {
  width: 100%;
  &__ymap {
    width: 1000px;
    max-width: 1000px;
    height: 360px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
    }

    iframe {
      width: 100%;
      height: 360px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__map {
    width: 100%;
    max-width: 1000px;
    padding-top: 360px;
    height: 0;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
    }

    iframe {
      width: 100%;
      height: 360px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__profile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1000px;
    margin: 40px auto;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
      flex-direction: column;
    }

    figure {
      width: 320px;
      height: auto;

      @include mq(md) {
        width: 32%;
      }

      @include mq(sm) {
        width: 100%;
      }
    }
  }

  &__data {
    width: 680px;

    @include mq(md) {
      width: calc(68% - 20px);
      margin-left: 20px;
    }

    @include mq(sm) {
      width: 100%;
      margin-top: 20px;
    }
    h3 {
      font-size: $FONT-SIZE-24;
      margin: 0;
      padding: 0;
      text-align: left;
      margin: 0px 0 0 80px;
      max-width: 520px;

      @include mq(md) {
        margin: 0;
      }

      @include mq(sm) {
        margin: 0;
      }
    }
    p {
      text-align: left;
      margin: 10px 0 0 80px;
      max-width: 520px;
      font-size: $FONT-SIZE-18;

      @include mq(md) {
        margin: 10px 0 0;
      }

      @include mq(sm) {
        margin: 10px 0 0;
      }
    }
    dl {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: left;
      max-width: 520px;
      font-size: $FONT-SIZE-18;
      margin: 0 0 0 80px;

      @include mq(md) {
        max-width: 100%;
        margin: 0;
      }

      @include mq(sm) {
        width: 100%;
        margin: 0;
      }
    }
    dt {
      width: 28%;
      padding: 20px 0;

      @include mq(md) {
      }

      @include mq(sm) {
      }
    }
    dd {
      width: 72%;
      padding: 20px 0;

      @include mq(md) {
      }

      @include mq(sm) {
      }
    }
    dd + dt {
      border-top: solid 1px #ddd;

      @include mq(md) {
      }

      @include mq(sm) {
      }
    }
    dd + dt + dd {
      border-top: solid 1px #ddd;

      @include mq(md) {
      }

      @include mq(sm) {
      }
    }
    img {
      width: 12px;
      height: auto;
      display: inline-block;
      margin: 0 10px 0 0;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }
  &__inquiry {
    display: flex;
    flex-direction: column;
    a {
      padding: 0 0 0 15px;
      background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
      background-size: 5px;
      color: $COLOR__ACCENT-PRIMARY;
    }
  }
}



.maplist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto 80px;

  @include mq(md) {
    width: 95%;
  }

  @include mq(sm) {
    width: 95%;
  }

  &__item {
    width: 46%;
    height: auto;
    position: relative;
    text-align: left;
    margin: 40px 0 0;

    @include mq(md) {
      width: 48%;
    }

    @include mq(sm) {
      width: 100%;
    }
  }

  h3 {
    font-size: $FONT-SIZE-24;
    margin: 0 0 1em;
    padding: 0;
    float: left;
  }

  p {
    font-size: $FONT-SIZE-18;
    margin: 0;
    padding: 0;
    //line-height: 1;
  }

  .addr {
    margin: 0 0 1em;
    width: 100%;
    min-height: 120px;
    clear: both;
  }

  .use {
    color: #fff;
    margin: 10px 0;
    float: right;
    span {
      padding: 5px 10px;
      border-radius: 3px;
      font-size: $FONT-SIZE-12;
      & + span {
        margin-left: 10px;
      }
      &.use--service {
        background-color: #0079a8;
      }
      &.use--industry {
        background-color: #0a7c48;
      }
    }
  }

  .officeMaps {
    width: 100%;
    height: 0;
    padding-top: 75%;
    overflow: hidden;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }


}













/* ------------------------------------------------------------ */



.localnavi {
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  height: 80px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: column;

  @include mq(md) {
  }

  @include mq(sm) {
    height: auto;
  }

  &__list {
    align-items: center;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include mq(md) {
    }

    @include mq(sm) {
      height: auto;
      flex-direction: column;
      padding: 0;
    }
  }

  &__item {
    display: inline-block;
    font-size: $FONT-SIZE-14;
    justify-content: center;
    align-items: center;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
      padding-bottom: 10px;
    }

    & + & {
      margin-left: 40px;

      @include mq(md) {
      }

      @include mq(sm) {
        margin: 0 auto;
      }
    }
    &:first-child {
      margin-right: 80px;
      position: relative;

      @include mq(md) {
      }

      @include mq(sm) {
        margin: 0 auto 20px;
        border-bottom: solid 2px $COLOR__FONT;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 40px;
        transform: rotate(30deg);
        background-color: $COLOR__FONT;
        top: 0px;
        right: -65px;

        @include mq(md) {
        }

        @include mq(sm) {
          display: none;
        }
      }
    }
  }

  a {
    display: inline-block;
    padding: 13px 0px;
    line-height: 1;
    &:hover {

      @include mq(lg) {
        border-bottom: solid 3px $COLOR__ACCENT-PRIMARY;
        padding: 13px 0px 10px;
        color: $COLOR__ACCENT-PRIMARY;
      }

      @include mq(md) {
        border-bottom: solid 3px $COLOR__ACCENT-PRIMARY;
        padding: 13px 0px 10px;
        color: $COLOR__ACCENT-PRIMARY;
      }

      @include mq(sm) {
      }
    }

    &.current {
      border-bottom: solid 3px $COLOR__ACCENT-PRIMARY;
      padding: 13px 0px 10px;
      color: $COLOR__ACCENT-PRIMARY;

      @include mq(sm) {
        color: #fff;
        background-color: $COLOR__ACCENT-PRIMARY;
        display: block;
      }
    }
  }
}

/* ------------------------------------------------------------ */
