
/* =========================================================
privacyPolicy
========================================================= */



.l-pickup {
  margin:  0 0 10px;

  .leadtext {
    max-width: 1000px;
    margin: 0 auto 80px;
    line-height: 2;
    display: inline-block;

    @include mq(sm) {
      width: 90%;
    }
  }

  .inner {
    max-width: 1000px;
    margin: 0 auto;
  }

  &__new {
    background-color: #e3f5ff;
    padding: 80px 0 160px;
  }

  &__hygieneRelated {
    background-color: #e3f5ff;
    padding: 80px 0;
  }

  h3 {
    font-size: $FONT-SIZE-30;
    font-weight: 500;
    margin: 0 0 10px;
    line-height: 1;
  }

  .en {
    font-size: $FONT-SIZE-18;
    font-weight: bold;
    margin: 0 0 40px;
    line-height: 1;
    color: #999;
    letter-spacing: .25em;
  }

  &__products {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    //align-content: stretch;
    flex-wrap: wrap;
    position: relative;
    margin: 0;

    @include mq(md) {
      width: 95%;
      margin: 0 auto;
    }

    @include mq(sm) {
      flex-direction: column;
      width: 95%;
      margin: 0 auto;
    }

    &:after {
      content:"";
      display: block;
      width: 32%;
      margin: 0 0 20px;

      @include mq(sm) {
        display: none;
      }
    }
  }

  &__item {
    width: 32%;
    height: auto;
    background-color: #fff;
    margin: 0 0 20px;
    padding: 20px;
    text-align: left;

    &:hover {
      box-shadow: 4px 0 16px rgba(0,0,0,.1);
      transition: box-shadow .5s ease 0s;
    }

    @include mq(sm) {
      width: 100%;
    }

    a {
      color: $COLOR__ACCENT-PRIMARY;
      i.fa {
        color: #ccc;
        margin-left: 5px;
      }
    }
  }

  figure {
    margin: 0 0 20px;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;


    img {
      width: auto;
      height: auto;
      max-width: 280px;
      max-height: 210px;

      @include mq(md) {
        max-width: 200px;
      }

      @include mq(sm) {
        max-width: 240px;
      }
    }
  }

  h4 {
    font-size: $FONT-SIZE-18;
    line-height: 1.125;
    font-weight: 500;
    margin: 0 0 10px;
  }

  .usecase {
    font-size: $FONT-SIZE-14;
    margin: 0 0 10px;

    span {
      display: inline-block;
      background-color: $COLOR__ACCENT-PRIMARY;
      color: #fff;
      padding: 4px 8px;
      border-radius: 3px;
      text-align: center;
      margin-right: 10px;
      font-size: $FONT-SIZE-12;
    }
  }

  .feature {
    font-size: $FONT-SIZE-16;
    margin: 0;
  }

}
