
/* =========================================================
HERO AREA
========================================================= */

.l-slide {
  width: 100%;
  //height: 0;
  //padding-top: 38.095238%;
  //overflow: hidden;
  background-color: #eee;
  position: relative;
  display: flex;

  @include mq(md) {
  }
  @include mq(sm) {
  }

  .manifest {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 640px;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 1;
    font-size: 90px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 2px 16px rgba(#000,.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    letter-spacing: 0;

    @include mq(md) {
      font-size: 60px;
      text-shadow: 0 2px 16px rgba(#000,.6);
    }
    @include mq(sm) {
      font-size: 24px;
      text-shadow: 0 2px 16px rgba(#000,.7);
    }

    &.enscript {
      line-height: 1;
      //font-size: 1rem;
    }

    span {
      display: block;
      line-height: 1;

      &:nth-of-type(1){
        width: 407px;
        height: 64px;
        margin: 0 auto .25em;

        @include mq(md) {
          width: 273px;
          height: 43px;
        }
        @include mq(sm) {
          margin: 0 auto;
          width: 180px;
          height: 28px;
        }
      }
      &:nth-of-type(2){
        font-size: 72px;
        letter-spacing: .15em;
        font-weight: bold;

        @include mq(md) {
          font-size: 48px;
        }
        @include mq(sm) {
          font-size: 24px;
        }
      }
    }
  }

  &.slider2 {

  }
}



/* =========================================================
section
========================================================= */

.l-message {
  padding: 80px 0 30vw;
  background: url(#{$rootUrl}/images/home/water_bg.png) center bottom no-repeat;
  background-size: 100%;

  @include mq(md) {
    width: 100%;
    margin: 0 auto;
    padding: 80px 5% 30vw;
  }

  @include mq(sm) {
    width: 100%;
    margin: 0 auto;
    padding: 40px 5% 33vw;
    display: none;
  }

  .leadtext {}

  h2 {

    @include mq(md) {
    }

    @include mq(sm) {
      line-height: 1.5;
    }
  }

  p {

    @include mq(md) {
      //text-align: left;
    }

    @include mq(sm) {
      text-align: left;
    }
  }
}





/* =========================================================
section
========================================================= */

.l-search {
  margin: -210px 0 0;
  padding: 80px 0;
  background: url(#{$rootUrl}/images/home/bg.png) center bottom repeat-x;

  @include mq(md) {
    //margin: -20vw 0 0;
    margin: 0;
    padding: 80px 0 80px;
  }

  @include mq(sm) {
    //margin: -23vw 0 0;
    margin: 0;
    padding: 60px 0 60px;
  }

  .wrapper {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mq(md) {
      width: calc(100% - 20px);
    }

    @include mq(sm) {
      width: calc(100% - 20px);
      flex-direction: column;
    }

  }

  .left {

    @include mq(sm) {
      margin-bottom: 60px;
    }
    .buttonGroup__item {

      @include mq(sm) {
        width: 32%;
      }

      & + li {
        margin-top: 30px;

        @include mq(md) {
          margin-top: 16px;
        }

        @include mq(sm) {
          //margin-top: 10px;
          margin-top: 0;
        }
      }

      a {
        padding: 15px 0;

        @include mq(sm) {
          padding: 0 0 10px;
        }
      }
    }
  }

  .right {
    .buttonGroup__item {

      @include mq(sm) {
        width: 48.5%;
        margin-bottom: 10px;
      }

      & + li {
        margin-top: 20px;

        @include mq(md) {
          margin-top: 10px;
        }

        @include mq(sm) {
          //margin-top: 10px;
          margin-top: 0;
        }
      }
    }
  }

  .buttonGroup {
    width: 48%;

    @include mq(md) {
      width: calc((100% - 10px)/2);
    }

    @include mq(sm) {
      width: 100%;
    }

    &__title {
      margin: 0 0 30px;
      span {
        display: block;
        line-height: 1;
        &:nth-of-type(1) {
          font-size: $FONT-SIZE-18;
          font-weight: 400;
        }
        &:nth-of-type(2) {
          font-size: $FONT-SIZE-30;
          margin: 10px 0;
          font-weight: 500;
          letter-spacing: .25em;
        }
        &:nth-of-type(3) {
          font-size: $FONT-SIZE-18;
          color: #999;
          letter-spacing: .25em;
        }
      }
    }

    &__list {
      width: 100%;
      @include reset;

      @include mq(sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;
      }
    }

    &__item {
      width:100%;

      @include mq(sm) {
        display: flex;
      }

      a {
        width: 100%;
        height: 100%;
        background-color: $COLOR__ACCENT-PRIMARY;
        border-radius: 3px;
        color: #fff;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
        font-size: $FONT-SIZE-24;
        overflow: hidden;
        position: relative;

        &:after {
          content: "\f105";
          font-family: "fontAwesome";
          font-size: $FONT-SIZE-24;
          position: absolute;
          right: 10px;
          top: calc(50% - .5em);
          line-height: 1;

          @include mq(md) {
          }

          @include mq(sm) {
            display: none;
          }
        }

        @include mq(md) {
          font-size: $FONT-SIZE-18;
          padding: 4px 0;
          word-break: keep-all;
        }

        @include mq(sm) {
          flex-direction: column;
          font-size: $FONT-SIZE-14;
          width: 100%;
          padding: 0 0 10px;
          text-align: center;
        }
      }

      img {
        width: 120px;
        @include mq(lg) {
        }
        @include mq(md) {
          width: 80px;
          height: auto;
        }
        @include mq(sm) {
        }
      }
    }
  }
}








/* =========================================================
section
========================================================= */

.l-works {
  padding: 80px 0;

  @include mq(md) {
    padding: 80px 0;
  }

  @include mq(sm) {
    padding: 60px 0;
  }

  &__board {
    background-color: #dfedf7;
    width: 50%;
    padding: 60px;
    text-align: left;

    @include mq(md) {
      padding: 20px;
    }

    @include mq(sm) {
      width: calc(100% - 10px);
      padding: 50px 60px 50px 50px;
    }


    h4 {
      font-size: $FONT-SIZE-24;
      letter-spacing: .5em;
      line-height: 1;
      color: $COLOR__ACCENT-PRIMARY;
      margin: 0 0 25px;

      @include mq(md) {
        margin: 0 0 10px;
      }

      @include mq(sm) {

      }
    }
    p {
      font-size: $FONT-SIZE-16;
      text-align: left;
      margin: 0 0 1em;
    }
    transform: translate3d(20px,20px,0);
  }

  .container {
    width: calc(100% - 40px);
    margin: 0 20px 0;

    @include mq(lg) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @include mq(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
    }

    @include mq(sm) {
    }

    &::after{
      content:"";
      display: block;
    }

    &.x2 {
      a {
        height: auto;
        text-align: left;
        position: relative;
        display: block;

        @include mq(lg) {
          //flex-basis: 49%;
          width: calc(50% - 10px);
        }

        @include mq(md) {
          //flex-basis: 49%;
          width: calc(50% - 10px);
        }

        @include mq(sm) {
          height: 0;
          padding-top: 33%;
          overflow: hidden;
          position: relative;
        }

        & + a {
          margin-left: 20px;

          @include mq(sm) {
            margin-left: 0;
            margin-top: 20px;
          }
        }

        figure {

          @include mq(sm) {
            width: 100%;
            position: absolute;
            top: -25%;
            left: 0;
            z-index: 0;
          }
        }

        .container__label {
          color: #fff;
          padding: 25px 20px;
          position: absolute;
          width: 30.1vw;
          height: 11.11111vw;
          min-width: 300px;
          min-height: 100px;
          top: calc(50% - 5.5vw);
          right: 0;
          background-color: rgba(#fff,.83);
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include mq(md) {
            top: calc(50% - 50px);
          }

          @include mq(sm) {
            min-width: 190px;
            top: calc(50% - 30px);
            padding: 10px;
            min-height: 60px;
          }

          h4 {
            font-size: calc(3.75em + 24 * (100vw - 1000px) / 1000);
            line-height: 1;
            @include reset;
            margin-bottom: .5em;
            color: $COLOR__FONT;

            @include mq(md) {
              font-size: $FONT-SIZE-24;
            }

            @include mq(sm) {
              font-size: $FONT-SIZE-16;
              margin-bottom: .25em;
            }
          }
          p {
            font-size: calc(2.5em + 15 * (100vw - 1000px) / 1000);
            line-height: 1;
            @include reset;
            color: #999;
            letter-spacing: 0;

            @include mq(md) {
              font-size: $FONT-SIZE-15;
            }

            @include mq(sm) {
              font-size: $FONT-SIZE-12;
            }
          }
          &:after {
            content: "";
            display: block;
            width: calc(100% - 10px);
            min-width: 180px;
            height: 5px;
            position: absolute;
            bottom: -5px;
            right: 0;

            @include mq(md) {

            }
          }
        }
        &:nth-of-type(1) {
          .container__label {
            &:after {
              background-color: rgba($COLOR__SUB-PRIMARY,.83);
            }
          }
        }
        &:nth-of-type(2) {
          .container__label {
            &:after {
              background-color: rgba($COLOR__SUB-SECONDARY,.83);
            }
          }
        }
        &:nth-of-type(3) {
          .container__label {
            &:after {
              background-color: rgba($COLOR__SUB-TERTIARY,.83);
            }
          }
        }
      }
    }
  }
}


/* =========================================================
section
========================================================= */

.l-contents {
  background-color: #dbe6ef;
  padding: 100px 0 80px;
  overflow: hidden;

  @include mq(md) {
    padding: 100px 0 80px;
  }

  @include mq(sm) {
    padding: 60px 0 40px;
  }

  .container {
    width: 100%;
    height: auto;

    @include mq(lg) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
    }

    @include mq(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
    }

    @include mq(sm) {
      display: block;
      width: calc(100% - 20px);
      margin: 0 auto;
    }

    &::after{
      content:"";
      display: block;
    }

    &.x3 {
      figure {
        width: 100%;
        height: auto;
        position: relative;

        span {
          position: absolute;
          left: 10px;
          bottom: 10px;
          z-index: 100;
          text-align: left;
          font-size: $FONT-SIZE-20;
          font-size: calc(2.8em + 20 * (100vw - 1000px) / 1000);
          text-shadow: 1px 0px 6px rgba(#fff,1),1px 0px 6px rgba(#fff,1),1px 0px 6px rgba(#fff,1),1px 0px 6px rgba(#fff,1);
          //background-color: rgba(#fff,.83);
          //display: inline-block;

          @include mq(md) {
            font-size: $FONT-SIZE-16;
            //display: none;
          }

          @include mq(sm) {
            font-size: $FONT-SIZE-11;
            display: none;
          }
        }
      }
      a {
        height: auto;
        display: block;
        text-align: right;

        &::after{
          flex-basis: 33.33333%;
        }

        @include mq(lg) {
          flex-basis: 33.33333%;
        }

        @include mq(md) {
          flex-basis: 33.33333%;
        }

        @include mq(sm) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        & + a {
          @include mq(sm) {
            margin-top: 20px;
          }
        }

        &:nth-of-type(1) {
          background-color: $COLOR__SUB-PRIMARY;
          .container__label {
          }
        }
        &:nth-of-type(2) {
          background-color: $COLOR__SUB-SECONDARY;
          .container__label {
          }
        }
        &:nth-of-type(3) {
          background-color: $COLOR__SUB-TERTIARY;
          .container__label {
          }
        }
      }
    }

    &__label {
      color: #fff;
      padding: 22px;
      width: 100%;
      height: auto;

      @include mq(md) {
      }

      @include mq(sm) {
        text-align: left;
        height: 100%;
        padding: 10px;
        display: block;
      }

      h4 {
        font-size: $FONT-SIZE-24;
        line-height: 1;
        @include reset;
        margin-bottom: 12px;
        @include mq(sm) {
          font-size: $FONT-SIZE-15;
        }
      }
      p {
        font-size: $FONT-SIZE-15;
        line-height: 1;
        @include reset;
        letter-spacing: 0;
        @include mq(sm) {
          font-size: $FONT-SIZE-13;
        }
      }
    }
  }
}

/* =========================================================
section
========================================================= */


.l-topics {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 100px 0 80px;

  @include mq(md) {
    width: calc(100% - 20px);
    padding: 100px 0 80px;
  }

  @include mq(sm) {
    width: calc(100% - 20px);
    padding: 60px 0 40px;
  }

  &__list {
    clear: both;
    margin: 0 0 0;
  }

  &__item {
    text-align: left;
    min-height: 60px;
    border-bottom: solid 1px #ddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 50px;
    font-size: $FONT-SIZE-16;

    &:first-child {
      border-top: solid 1px #ddd;
    }

    @include mq(md) {
    }

    @include mq(sm) {
      padding: 18px 0;
      flex-wrap: wrap;
    }
  }

  &__date {
    display: inline-block;
    font-size: $FONT-SIZE-16;
    //width: 160px;
    letter-spacing: -.025em;
    min-width: 5em;

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
      margin-left: 10px;
    }
  }

  &__label {
    display: inline-block;
    text-align: center;
    min-width: 120px;
    padding: 7px 10px;
    line-height: 1;
    font-size: $FONT-SIZE-12;
    letter-spacing: -.01em;
    margin: 0 20px 0;

    @include mq(md) {
    }

    @include mq(sm) {
    }
  }

  &__description {
    display: block;
    font-size: $FONT-SIZE-16;
    margin: 0 0 0 10px;
    width: 100%;

    @include mq(md) {
    }

    @include mq(sm) {
      margin: 0;
      margin-top: calc(18px - .25em);
      padding: 0 10px;
      flex-basis: 100%;
      //background-color: #fafafa;
    }
  }

  .button {
    width: 200px;
    height: 40px;
    background-color: $COLOR__ACCENT-PRIMARY;
    border-radius: 3px;
    color: #fff;
    line-height: 40px;
    margin: 20px;
    position: relative;

    &:after {
      content: "\f105";
      font-family: "fontAwesome";
      font-size: $FONT-SIZE-18;
      position: absolute;
      right: 10px;
      top: calc(50% - .5em);
      line-height: 1;
    }

    @include mq(lg) {
      &:hover {
        background-color: darken($COLOR__ACCENT-PRIMARY,10%);
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(md) {
      &:hover {
        background-color: $COLOR__ACCENT-PRIMARY;
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(sm) {
      background-color: $COLOR__ACCENT-PRIMARY;
    }
  }
}

/* =========================================================
section
========================================================= */



.l-linkbnr {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 80px;
  padding: 0;

  @include mq(md) {
    width: calc(100% - 20px);
    margin: 0 auto 40px;
  }

  @include mq(sm) {
    width: calc(100% - 20px);
    margin: 0 auto 40px;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    //min-height: 120px;

    @include mq(sm) {
    }
  }
  li {
    width: 32%;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border: solid 1px $COLOR__FONT;
    position: relative;

    @include mq(sm) {
      width: 48%;
      min-height: 50px;
      flex-flow: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    & + li {

      @include mq(sm) {
      }
    }

    // &:first-child {
    //   background-color: #fff;
    //
    //   @include mq(sm) {
    //     width: 100%;
    //   }
    //
    //   &:after {
    //     display: block;
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 0;
    //     padding-top: 37.5%;
    //   }
    //
    //   a {
    //     padding: 5px 20px;
    //   }
    // }
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    font-size: $FONT-SIZE-16;
    width: 100%;
    height: 100%;
    min-height: 10px;
    z-index: 1;

    &:hover {
      color: $COLOR__FONT;
    }

    // &:before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   width: calc(100% - 4px);
    //   height: calc(100% - 4px);
    //   top: 2px;
    //   left: 2px;
    //   z-index: -1;
    //   border: solid 1px #fff;
    // }
    // &:after {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   width: calc(100% - 20px);
    //   height: calc(100% - 20px);
    //   top: 10px;
    //   left: 10px;
    //   background-color: #fff;
    //   z-index: -1;
    // }
  }
  span {
    font-size: $FONT-SIZE-10;
    text-align: left;
  }
}
