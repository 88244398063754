
/* =========================================================
section
========================================================= */

.searchheader {

  .inner {
    max-width: calc(100% - 20px);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }
  h1 {
    font-size: $FONT-SIZE-24;
    max-width: 1000px;
    margin: 20px auto;
    text-align: left;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
    }
  }
}
.searchheader {
  background-color: #fff;
  height: auto;

  .inner {
    max-width: 1000px;
    display: block;
  }
  .category {
    float: right;
    margin: 15px 0;

    li {
      display: inline-block;
      & + li {
        margin-left: 20px;
      }
    }

    a{
      font-size: $FONT-SIZE-14;
      padding: 0 0 0 15px;
      background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
      background-size: 5px;
      color: $COLOR__ACCENT-PRIMARY;
    }
  }
  .searchBlock {
    clear: both;
  }
  .freeword {
    background-color: $COLOR__ACCENT-PRIMARY;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: $FONT-SIZE-14;
    margin: 0;
    padding: 10px 0;

    @include mq(sm) {
      flex-wrap: wrap;
    }

    p {
      margin: 0 20px;

      @include mq(md) {
        width: 20%;
        text-align: left;
        //margin-bottom: 10px;
      }

      @include mq(sm) {
        width: 100%;
        text-align: left;
        flex-direction: column;
      }
    }

    &__entry {
      position: relative;
      width: 560px;
      margin-right: 20px;

      @include mq(md) {
        width: 70%;
        margin-left: 20px;
      }

      @include mq(sm) {
        width: 100%;
        margin: 10px 20px;
      }

      label {
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(#{$rootUrl}/images/common/icon/search.png);
        background-size: 20px;
        position: absolute;
        top: calc( 50% - 10px );
        left: 10px;
        z-index: 1;
      }

      input {
        padding: 9px 90px 9px 40px;
        background-color: #fff;
        background-position: 11px center;
        font-size: $FONT-SIZE-18;
        line-height: 1;
        width: 100%;
        border-radius: 3px;
        color: $COLOR__FONT;
        position: relative;

        @include mq(md) {
          width: 100%;
        }

        @include mq(sm) {
          width: 100%;
          padding: 9px 60px 9px 40px;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
        }
      }
      button {
        position: absolute;
        top: 2px;
        right: 2px;
        line-height: 36px;
        padding: 0 25px;
        background-color: $COLOR__ACCENT-PRIMARY;
        border-radius: 3px;
        cursor: pointer;

        @include mq(sm) {
          padding: 0 10px;
        }
      }
    }

    &__setting {
      font-size: $FONT-SIZE-14;
      margin-left: 20px;
      display: none;
      label {
        display: block;
        text-align: left;
        padding: 0 0 0 30px;
        cursor: pointer;
        line-height: 22px;
        position: relative;

        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 9px;
          background-color: #fff;
          position: absolute;
          top: 3px;
          left: 0;
        }
      }
      input[type=radio] {
        display: none;
      }
      input[type=radio]:checked + label:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: $COLOR__ACCENT-PRIMARY;
        position: absolute;
        top: 7px;
        left: 4px;
        z-index: 10;
      }
      input[type=radio] + label:after {
        display: none;
      }
    }
  }
  .jpSyllabary {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: $FONT-SIZE-14;
    border-bottom: solid 1px $COLOR__SUB-PRIMARY;
    padding: 5px 0;

    @include mq(md) {
      padding: 5px 2.5%;
    }

    @include mq(sm) {
      padding: 5px 2.5%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
    }

    p {
    }

    ul {
      //margin: 0 auto;
      margin-left: 90px;

      @include mq(md) {
        margin-left: auto;
      }

      @include mq(sm) {
        margin-left: 5%;
        padding-right: 5%;
      }
    }

    li {
      display: inline-block;

      & + li {
        margin-left: 15px;
      }
    }

    a {
      color: $COLOR__ACCENT-PRIMARY;
      padding: 10px;

    }
  }
}












.l-products {
  margin: 0;
}

.l-productsMenu {

  margin: 0 0 10px;
  padding-bottom: 80px;
  background: url(#{$rootUrl}/images/products/products_repeat_bg.png) center bottom repeat-x;

  @include mq(md) {
    margin: 0 0 10px;
  }

  @include mq(sm) {
    margin: 0 0 10px;
  }

  &__list {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      flex-direction: column;
      max-width: 95%;
    }
  }

  &__item {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0 0 40px;

    @include mq(md) {
      margin: 0 0 4%;
    }

    @include mq(sm) {
      //width: 95%;
      margin: 0 0 2.5%;
    }
  }

  &__label {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    letter-spacing: -.015em;

    h3 {
      display: inline-block;
      line-height: 1;
      margin: 0 10px -.2em 0;
      font-size: $FONT-SIZE-48;
      font-weight: bold;
      text-shadow: 0 0 16px rgba(0,0,0,.5);

      @include mq(md) {
        font-size: calc(4.8em + 12 * (100vw - 768px) / 232)
      }

      @include mq(sm) {
        font-size: calc(4.8em + 48 * (100vw - 320px) / 448)
      }
    }

    p {
      display: inline-block;
      line-height: 1;
      margin: 0;
      font-size: $FONT-SIZE-18;
      font-weight: bold;
      text-shadow: 0 0 16px rgba(0,0,0,.5);
      position: relative;
      bottom: -.2em;

      @include mq(md) {
        font-size: calc(1.8em + 6 * (100vw - 768px) / 232)
      }

      @include mq(sm) {
        font-size: calc(1.8em + 18 * (100vw - 320px) / 448)
      }
    }
  }

  .wrapper {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mq(md) {
      width: calc(100% - 20px);
    }

    @include mq(sm) {
      width: calc(100% - 20px);
      flex-direction: column;
    }

  }

  .left {

    @include mq(sm) {
      margin-bottom: 60px;
    }
    .buttonGroup__item {

      @include mq(sm) {
        width: 32%;
      }

      & + li {
        margin-top: 30px;

        @include mq(md) {
          margin-top: 16px;
        }

        @include mq(sm) {
          margin-top: 0;
        }
      }

      a {
        padding: 15px 0;

        @include mq(md) {
        }

        @include mq(sm) {
          padding: 0 0 10px;
        }
      }
    }
  }

  .right {
    .buttonGroup__item {

      @include mq(sm) {
        width: 48.5%;
        margin-bottom: 10px;
      }


      & + li {
        margin-top: 20px;

        @include mq(md) {
          margin-top: 10px;
        }

        @include mq(sm) {
          margin-top: 0;
        }
      }
    }
  }

  .buttonGroup {
    width: 48%;

    @include mq(md) {
      width: calc((100% - 10px)/2);
    }

    @include mq(sm) {
      width: 100%;
      height: 100%;
    }

    &__title {
      margin: 0 0 30px;
      span {
        display: block;
        line-height: 1;
        &:nth-of-type(1) {
          font-size: $FONT-SIZE-18;
          font-weight: 400;
        }
        &:nth-of-type(2) {
          font-size: $FONT-SIZE-30;
          margin: 10px 0;
          font-weight: 500;
          letter-spacing: .25em;
        }
        &:nth-of-type(3) {
          font-size: $FONT-SIZE-18;
          color: #999;
          letter-spacing: .25em;
        }
      }
    }

    &__list {
      width: 90%;
      margin: 0 auto;
      padding: 0;

      @include mq(sm) {
        width: 95%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;
      }
    }

    &__item {
      width:100%;

      @include mq(sm) {
        display: flex;
      }

      a {
        border-radius: 3px;
        background-color: $COLOR__ACCENT-PRIMARY;
        color: #fff;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: $FONT-SIZE-24;
        overflow: hidden;
        position: relative;

        &:after {
          content: "\f105";
          font-family: "fontAwesome";
          font-size: $FONT-SIZE-24;
          position: absolute;
          right: 10px;
          top: calc(50% - .5em);
          line-height: 1;

          @include mq(md) {
          }

          @include mq(sm) {
            display: none;
          }
        }

        @include mq(md) {
          font-size: $FONT-SIZE-18;
          padding: 4px 0;
          word-break: keep-all;
        }

        @include mq(sm) {
          flex-direction: column;
          font-size: $FONT-SIZE-14;
          width: 100%;
          padding: 0 0 10px;
          text-align: center;
        }
      }

      img {
        width: 120px;
        @include mq(lg) {
        }
        @include mq(md) {
          width: 80px;
          height: auto;
        }
        @include mq(sm) {
        }
      }
    }
  }
}

/* ------------------------------------------------------------
Detail
------------------------------------------------------------ */

.l-productsDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;

  @include mq(md) {
    flex-direction: column;
  }
  @include mq(sm) {
    flex-direction: column;
  }
}

.refineSearch {
  width: 200px;

  @include mq(md) {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @include mq(sm) {
    width: 95%;
    margin: 0 auto;
    //display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    display: none;
  }

  p {
    font-size: $FONT-SIZE-15;
    font-weight: bold;
    position: relative;
    padding-right: calc(20px + 1em);
      @include mq(sm) {
        border-top: solid 1px #ccc;
        //border-bottom: solid 1px #ccc;
        margin: 0;
        padding: 15px calc(20px + 1em) 15px 0;
      }

    &.active {
      @include mq(sm) {
        //border: none;
      }
    }

    & .fa-chevron-right {
      position: absolute;
      top: calc(50% - (.5em + 10px));
      right: 2%;
      color: #999;
      display: none;

      @include mq(md) {
      }

      @include mq(sm) {
        top: calc(50% - (.5em + 10px));
        transform: rotate(90deg);
        padding: 10px;
        display: inline-block;
      }
    }
  }
  &__foodService {
    margin-bottom: 80px;

    @include mq(md) {
      width: 48%;
      margin-bottom: 40px;
    }
    @include mq(sm) {
      width: 100%;
      margin-bottom: 0;
    }
    ul {
      border-top: solid 1px #ccc;
      @include mq(sm) {
        display: none;
        margin: 0;
        background-color: #f9f9f9;
      }
    }
  }
  &__foodIndustry {
    margin-bottom: 80px;

    @include mq(md) {
      width: 48%;
      margin-bottom: 40px;
    }
    @include mq(sm) {
      width: 100%;
      margin-bottom: 40px;
      margin-top: -1px;
    }
    ul {
      border-top: solid 1px #ccc;
      @include mq(sm) {
        display: none;
        margin: 0;
        background-color: #f9f9f9;
      }
    }
    p {
      @include mq(sm) {
        border-bottom: solid 1px #ccc;
      }
      &.active {
        @include mq(sm) {
          border-bottom: none;
        }
      }
    }
  }
  &__case {
    width: 100%;
  }
  &__item {
    display: block;
    width: 100%;
    //height: 50px;
    line-height: 50px;
    border-bottom: solid 1px #ccc;
    font-size: $FONT-SIZE-13;
    position: relative;
    &:hover {
      @include mq(lg) {
        background-color: #eee;
      }
    }
    & > a {
      display: block;
      padding: 0 0 0 10px;
      position: relative;

      &:after {
        content: "\f054";
        font-family: "fontAwesome";
        display: inline-block;
        position: absolute;
        //top: calc(50% - .5em);
        right: 2%;
        color: #999;
        @include mq(md) {
          display: none;
        }
        @include mq(sm) {
          display: none;
        }
      }

      &:hover {
        background-color: #eee;
        @include mq(md) {
          background-color: transparent;
        }
        @include mq(sm) {
          background-color: transparent;
        }
      }
    }
    ul {
      width: 200px;
      display: none;
      position: absolute;
      top: -1px;
      left: 200px;
      z-index: 100;
      background-color: #fff;
      padding: 10px 0;
      box-shadow: 0 0 16px rgba(#000,.2);

      @include mq(md) {
        position: static;
        width: 100%;
        box-shadow: none;
        //display: block;
      }
      @include mq(sm) {
        position: static;
        width: 100%;
        box-shadow: none;
        background-color: #eee;
      }
      li {
      }
      a {
        //width: 100%;
        line-height: 30px;
        padding: 0 0 0 10px;
        display: block;

        &:hover {
          @include mq(lg) {
            background-color: #eee;
            background-image: url(#{$rootUrl}/images/common/arrow.png);
            background-position: 95% center;
            background-repeat: no-repeat;
            background-size: 5px;
          }
        }
      }
    }
    & .fa-chevron-right {
      display: inline-block;
      position: absolute;
      top: calc(50% - .5em);
      right: 2%;
      color: #999;

      @include mq(lg) {
        display: none;
      }

      @include mq(md) {
        top: calc(1em - .5em);
        transform: rotate(90deg);
        padding: 10px;
      }

      @include mq(sm) {
        top: calc(1em - .5em);
        transform: rotate(90deg);
        padding: 10px;
      }
    }
  }
}



.productsMain {
  width: 780px;

  @include mq(md) {
    width: 95%;
    margin: 0 auto;
  }
  @include mq(sm) {
    width: 95%;
    margin: 0 auto;
  }

  h1 {
    width: 100%;
    font-size: $FONT-SIZE-24;
    border-bottom: solid 2px #ccc;
    padding: 0 0 10px;
    color: $COLOR__ACCENT-PRIMARY;
    position: relative;

    &[id^="category"] {
      border-top: solid 4px $COLOR__SUB-SECONDARY;
      border-bottom: solid 1px #ccc;
      //background-color: rgba(#fff61d,.2);
      padding: 15px 10px;
      margin-bottom: 10px;
      box-shadow: 0 10px 10px -10px rgba(0,0,0,.1);
      color: $COLOR__FONT;
      color: $COLOR__SUB-SECONDARY;
      //text-shadow: -1px 1px 0px rgba(#fff,1), 1px 2px 8px rgba($COLOR__FONT,.3);
    }

    button {
      //position: absolute;
      //bottom: 10px;
      //right: 0;
      position: relative;
      top: -4px;
      background-color: rgba(#999, .2);
      border-radius: 16px;
      width: 110px;
      height: 32px;
      cursor: pointer;
      outline: none;
      z-index: 10;
      font-size: $FONT-SIZE-12;
      color: #999;

      i {
        color: #999;
        font-size: $FONT-SIZE-18;
        margin-right: 8px;
        position: relative;
        top: 2px;
      }

      &.active {
        color: rgba(#f0c74d, 1);
        background-color: rgba(#f0c74d, .2);

        i {
          color: rgba(#f0c74d, 1);
        }
      }
    }
  }

  h2 {
  font-size: $FONT-SIZE-24;
    background: url(#{$rootUrl}/images/products/three.png) left bottom no-repeat;
    position: relative;
    padding: 0 0 10px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0px;
      left: 90px;
      border-bottom: solid 2px #ccc;
      width: calc(100% - 90px);
    }
  }

  .productMainBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include mq(md) {
    }
    @include mq(sm) {
      flex-direction: column;
    }

    figure {
      width: 360px;
      min-height: 360px;
      max-height: 360px;
      background-color: #fff;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: center;
      position: relative;
      //padding-bottom: 40px;
      margin: 0 0 10px;

      @include mq(sm) {
        width: 100%;
        min-height: 240px;
        align-items: center;
      }

      img {
        max-width: 360px;
        max-height: 360px;
        width: auto;
        height: auto;
        position: relative;
        @include mq(sm) {
          max-width: 240px;
          max-height: 240px;
        }
      }

    }
  }

  .productData {
    width: 400px;

    @include mq(md) {
      width: 50%;
      margin-left: 10px;
    }
    @include mq(sm) {
      width: 100%;
    }
  }
  .labellist {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: $FONT-SIZE-13;
    span {
      display: inline-block;
      padding: 5px 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: #fff;
      border-radius: 1px;
      &.type1 {
        background-color: $COLOR__SUB-PRIMARY;
      }
      &.type2 {
        background-color: $COLOR__SUB-TERTIARY;
      }
    }
  }
  .productDescription {
    font-size: $FONT-SIZE-18;
    margin: 10px 0 20px;
  }
  .productFeature {
    margin-bottom: 40px;
    p {
      font-size: $FONT-SIZE-16;
    }
  }
  .productDocument {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      position: relative;

      &:after {
        display: block;
        content: "";
        width: 32%;
        margin-left: 2%;

        @include mq(md) {
          width: 48%;
        }

        @include mq(sm) {
          width: 100%;
        }
      }
    }
    li {
      width: 32%;
      height: 60px;
      margin-bottom: 30px;
      @include mq(md) {
        width: 48%;
        margin-bottom: 20px;
      }
      @include mq(sm) {
        width: 100%;
        margin-bottom: 10px;
      }

      & + li {
        margin-left: auto;
      }

      &:nth-of-type(3n+1) {
        @include mq(lg) {
          margin-left: 0;
        }
      }

      &:nth-of-type(2n+1) {
        @include mq(md) {
          margin-left: 0;
        }
      }

      a {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 60px;
        line-height: 1;
        color: #fff;
        font-size: $FONT-SIZE-16;
        text-align: left;
        padding: 0 0 0 20px;
        margin: 0;

        &:hover {
          @include mq(lg) {
            box-shadow: 0 4px 16px rgba(#000,.2);
          }
        }
      }
      &.type1 a {
        background-color: $COLOR__SUB-TERTIARY;

        &:hover {
          @include mq(lg) {
            //background-color: darken($COLOR__SUB-TERTIARY, 5%);
          }
        }
      }
      &.type2 a {
        background-color: #456070;

        &:hover {
          @include mq(lg) {
            //background-color: darken(#456070, 5%);
          }
        }
      }
      &.type3 a {
        background-color: #4f6fbf;

        &:hover {
          @include mq(lg) {
            //background-color: darken(#320000, 5%);
          }
        }
      }
      i {
        margin-right: 10px;
        line-height: 1;
      }
      &.prohibited {
        a {
          background-image: url(#{$rootUrl}/images/products/prohibited.png);
          background-position: right 10px center;
          background-size: 66px;
          padding-right: 80px;
        }
      }
    }
    span {
      font-size: $FONT-SIZE-12;
      display: inline-block;
      width: 100%;
      line-height: 1;
      margin: -1.5em 0 0;
      padding: 0;
      //position: absolute;
    }
  }
  .productJanItf {}
  .productTable {
    width: 100%;
    th {
      width: 120px;
      font-size: $FONT-SIZE-14;
      vertical-align: middle;
      @include mq(md) {
      }
      @include mq(sm) {
        width: 100%;
      }
    }
    td {
      font-size: $FONT-SIZE-14;
      vertical-align: middle;
      width: auto;

      img {
        display: none;
        width: 40px;
        height: auto;
      }
    }
  }
}

/* ------------------------------------------------------------
Type
------------------------------------------------------------ */

.l-productsType {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;

  @include mq(md) {
    flex-direction: column;
  }
  @include mq(sm) {
    flex-direction: column;
  }
}
.categoryList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-flow: wrap;
  margin-bottom: 40px;

  @include mq(md) {
    margin-bottom: 80px;
  }
  @include mq(sm) {
    margin-bottom: 40px;
  }

  li {
    margin-bottom: 15px;

    @include mq(md) {
      width: 48%;
      margin-bottom: 10px;
    }
    @include mq(sm) {
      //width: 100%;
      margin-bottom: 5px;
      margin-right: 5px;
    }
    & + li {
      margin-left: 20px;

      @include mq(md) {
        margin-left: auto;
      }
      @include mq(sm) {
        margin-left: 0;
      }
    }
    &:nth-of-type(4n+1) {
      @include mq(lg) {
        margin-left: 0;
      }
    }
    &:nth-of-type(2n+1) {
      @include mq(md) {
        margin-left: 0;
      }
      @include mq(sm) {
        margin-left: 0;
      }
    }
  }
  a {
    width: 180px;
    min-height: 40px;
    height: 100%;
    line-height: 1;
    padding: 5px 30px 5px 10px;
    border-radius: 3px;
    background-color: $COLOR__ACCENT-PRIMARY;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: $FONT-SIZE-12;
    cursor: pointer;
    position: relative;

    &:after {
      content: "\f105";
      font-family: "fontAwesome";
      font-size: $FONT-SIZE-18;
      position: absolute;
      right: 10px;
      top: calc(50% - .5em);
      line-height: 1;
    }

    @include mq(md) {
      width: 100%;
    }
    @include mq(sm) {
      width: 100%;
    }

    &:hover {
      @include mq(lg) {
        box-shadow: 0 4px 16px rgba(#000,.2);
        //background-color: darken($COLOR__ACCENT-PRIMARY, 5%);
      }
    }
  }

  & + h1 {
    margin-bottom: 0;
  }
}

.typeStyle {
  width: 100%;
  max-width: 780px;
  margin: 0 auto 80px;
  border-bottom: solid 1px #ccc;

  @include mq(md) {
    max-width: 100%;
  }

  @include mq(sm) {
    max-width: 100%;
  }

  tbody {

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
      width: 100%;
    }
  }
  tr {

    @include mq(md) {
    }

    @include mq(sm) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      padding-bottom: 10px;
      border-top: solid 1px #ccc;
    }
    &:first-child {
      @include mq(sm) {
        display: none;
      }
    }
    & + tr:nth-of-type(2) {
      @include mq(sm) {
        border-top: none;
      }
    }
  }
  th {
    font-size: $FONT-SIZE-15;
    //border-top: solid 1px #ccc;
    text-align: center;
    padding: 1em 10px;
  }
  td {
    text-align: left;
    font-size: $FONT-SIZE-13;
    border-top: solid 1px #ccc;
    padding: 11px 0 11px 10px;
    position: relative;

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
      width: 100%;
      padding: 0 10px;
    }

    &:nth-of-type(1) {
      width: 410px;

      @include mq(md) {
      }

      @include mq(sm) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      figure {
        width: 90px;
        height: auto;
        min-height: 90px;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq(sm) {
          float: none;
        }
      }
    }
    &:nth-of-type(2) {
      width: 110px;
      @include mq(sm) {
        padding-left: 4em;
        min-height: 1.5em;
      }
      &:after {
        @include mq(sm) {
          content: "形状 : ";
          color: $COLOR__FONT;
          position: absolute;
          left: 0;
        }
      }
    }
    &:nth-of-type(3) {
      width: 110px;
      @include mq(sm) {
        padding-left: 4em;
        min-height: 1.5em;
      }
      &:after {
        @include mq(sm) {
          content: "液性 : ";
          color: $COLOR__FONT;
          position: absolute;
          left: 0;
        }
      }
    }
    &:nth-of-type(4) {
      width: 150px;
      @include mq(sm) {
        padding-left: 4em;
        min-height: 1.5em;
      }
      &:after {
        @include mq(sm) {
          content: "荷姿 : ";
          color: $COLOR__FONT;
          position: absolute;
          left: 0;
        }
      }
    }

    &:nth-of-type(n) {
      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
      }
    }

    .block {
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: space-around;
      align-content: center;
      flex-wrap: wrap;
      height: 100%;
      margin: 0 0 0 10px;
      padding: 10px 0;
      min-height: 90px;
    }
    .name {
      margin: 5px 0;
      font-size: $FONT-SIZE-15;
      a {
        color: $COLOR__ACCENT-PRIMARY;
      }
    }
    .typeLabel {
      font-size: $FONT-SIZE-10;
      display: inline-block;
      span {
        display: inline-block;
        border: solid 1px #999;
        color: #999;
        margin-right: 2px;
        margin-bottom: 2px;
        line-height: 1;
        padding: 5px;
      }
    }

    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
      width: 100%;
      border-top: none;
    }
  }
  img {
    max-width: 90px;
    max-height: 90px;
    width: auto;
    height: auto;
  }
}

/* ------------------------------------------------------------
Category
------------------------------------------------------------ */

.l-productsCategory {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;

  @include mq(md) {
  }

  @include mq(sm) {
    flex-direction: column;
  }
}


.testtest {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  li {
    display: flex;
    flex-direction: column;
    width: 240px;
    background-color: #ccc;
  }

  .button {
    display: flex;
    width: 100%;
    height: 40px;
    background-color: $COLOR__ACCENT-PRIMARY;
    color: #fff;
    line-height: 40px;
    margin: 10px 0 0;
    text-align: center;
  }
}



.categoryStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
  width: 100%;
  max-width: 780px;
  margin: 0 auto 80px;

  @include mq(md) {
    max-width: 100%;
  }

  @include mq(sm) {
    max-width: 100%;
  }

  &:after {
    display: block;
    content: "";
    width: 240px;

    @include mq(md) {
      width: 32%;
    }

    @include mq(sm) {
      width: 48%;
    }
  }
  li {
    width: 240px;
    //height: 100%;
    margin-bottom: 30px;
    min-height: 240px;
    padding-bottom: 50px;
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    @include mq(md) {
      width: 32%;
    }

    @include mq(sm) {
      width: 48%;
    }

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      @include mq(lg) {
        //margin-bottom: 0;
      }
      @include mq(lg) {
        //margin-bottom: 0;
      }
    }
  }
  figure {
    max-width: 240px;
    max-height: 240px;
    min-height: 240px;
    display: flex;
    align-items: center;

    @include mq(md) {
      max-width: 100%;
      max-height: 100%;
      min-height: 200px;
    }

    @include mq(sm) {
      max-width: 100%;
      max-height: 100%;
      min-height: 160px;
    }
    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    img {
      width: auto;
      height: auto;
      max-width: 240px;
      max-height: 240px;

      @include mq(md) {
        max-width: 100%;
        max-height: 200px;
      }

      @include mq(sm) {
        max-width: 100%;
        max-height: 160px;
        min-width: 140px;
      }
    }
  }
  h3 {
    font-size: $FONT-SIZE-16;
    a {
      color: $COLOR__ACCENT-PRIMARY;
    }
  }
  .labellist {
    margin: 0;
    span {
      background-color: #e3f5ff;
      color: $COLOR__ACCENT-PRIMARY;
      margin-right: 2px;
      margin-bottom: 2px;
      display: inline-block;
      padding: 5px;
    }
  }
  .button {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: $COLOR__ACCENT-PRIMARY;
    color: #fff;
    line-height: 40px;
    //margin: 10px 0 0;
    //margin-top: auto;
    text-align: center;
    position: absolute;
    bottom: 0;

    &:after {
      content: "\f105";
      font-family: "fontAwesome";
      font-size: $FONT-SIZE-18;
      position: absolute;
      right: 10px;
      top: calc(50% - .5em);
      line-height: 1;
    }

    @include mq(lg) {
      &:hover {
        background-color: darken($COLOR__ACCENT-PRIMARY,10%);
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(md) {
      &:hover {
        background-color: $COLOR__ACCENT-PRIMARY;
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(sm) {
      background-color: $COLOR__ACCENT-PRIMARY;
    }
  }
}

/* ------------------------------------------------------------
Device
------------------------------------------------------------ */


.l-device ,
.l-document {
  text-align: left;
  .inner {
    max-width: 1000px;
    margin: 0 auto 80px;

    @include mq(md) {
      width: 95%;
      margin: 0 auto 40px;
    }
    @include mq(sm) {
      width: 95%;
      margin: 0 auto 40px;
    }
  }

  .leadtext {
    margin: 0 0 20px;
    line-height: 2;
  }

  .headlead {
    margin: 0 0 40px;
  }

  .contentTitle {
    font-size: $FONT-SIZE-24;
    padding-left: 10px;
    border-left: solid 5px $COLOR__ACCENT-PRIMARY;
  }
  h3 {
    font-size: $FONT-SIZE-18;
    margin: 0 0 20px;
  }

  .arrow {
    padding: 0 0 0 15px;
    background: url(#{$rootUrl}/images/common/arrow.png) left center no-repeat;
    background-size: 5px;
    font-size: $FONT-SIZE-18;
    color: $COLOR__ACCENT-PRIMARY;
    i {
      margin-left: 5px;
      color: #ccc;
    }
  }
  .ex-l {
    font-size: $FONT-SIZE-18;
    color: $COLOR__ACCENT-PRIMARY;
    i {
      margin-right: 5px;
      color: #ccc;
    }
  }

  .filesize {
    font-weight: 400;
    font-size: calc(1em - .25em);
  }

  .note {
    font-size: $FONT-SIZE-14;
  }

  .note + h3 {
    margin-top: 80px;
  }

  .note + .contentTitle {
    margin-top: 80px;
  }

  .pdflist + h3 {
    margin-top: 80px;
  }

  .pdflist + .contentTitle {
    margin-top: 80px;
  }
}

/* ------------------------------------------------------------
Document
------------------------------------------------------------ */


.pdflist {
  border-bottom: solid 1px #ccc;
  li {
    border-top: solid 1px #ccc;
    font-size: $FONT-SIZE-18;
    padding: 10px 0;
    a {
      padding: 0 0 0 15px;
      background: url(#{$rootUrl}/images/common/arrow.png) left center no-repeat;
      background-size: 5px;
      color: $COLOR__ACCENT-PRIMARY;
    }
    i {
      margin-left: 5px;
      color: #ccc;
    }
  }
}




/* ------------------------------------------------------------
Topics
------------------------------------------------------------ */

.l-productsTopics {
  .inner {
    max-width: 1000px;
    margin: 0 auto;
    margin: 0 auto 80px;

    @include mq(md) {
      width: 95%;
      margin: 0 auto 40px;
    }
    @include mq(sm) {
      width: 95%;
      margin: 0 auto 40px;
    }
  }
  .button {
    width: 160px;
    height: 40px;
    background-color: #ccc;
    color: #fff;
    line-height: 40px;
    margin: 20px;

    @include mq(lg) {
      &:hover {
        background-color: $COLOR__ACCENT-PRIMARY;
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(md) {
      &:hover {
        background-color: $COLOR__ACCENT-PRIMARY;
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(sm) {
      background-color: $COLOR__ACCENT-PRIMARY;
    }
  }
}
