
/* =========================================================
Sanitation
========================================================= */

.l-sanitation {
  margin: 0 0 120px;

  @include mq(md) {
    margin: 0 0 80px;
  }

  @include mq(sm) {
    margin: 0 0 40px;
  }

  &__list {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      flex-direction: column;
    }

    &:after {
      content: "";
      display: block;
      background-color: #e3f5ff;
      width: 92%;
      height: calc(100% - 40px);
      position: absolute;
      top: 40px;
      left: 4%;
      z-index: -1;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 90%;
        left: 5%;
      }
    }
  }

  &__item {
    width: 48%;
    height: auto;
    position: relative;
    margin: 0 0 40px;

    &:nth-of-type(even) {
      margin-left: 4%;

      @include mq(md) {
      }

      @include mq(sm) {
        margin-left: 2.5%;
      }
    }

    @include mq(md) {
      margin: 0 0 4%;
    }

    @include mq(sm) {
      width: 95%;
      height: 100%;
      margin: 0 2.5% 2.5%;
    }

    &.longname {
      h3 {
        font-size: $FONT-SIZE-30;

        @include mq(md) {
          //font-size: calc(600% + 12 * (48vw - 768px) / 232)
          font-size: calc(1.5em + 20 * (100vw - 768px) / 232)
        }

        @include mq(sm) {
          font-size: calc(3em + 30 * (100vw - 320px) / 468)
        }
      }
    }
  }

  &__label {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    letter-spacing: -.015em;

    h3 {
      display: inline-block;
      line-height: 1;
      margin: 0 10px -.2em 0;
      font-size: $FONT-SIZE-48;
      font-weight: bold;
      text-shadow: 0 0 16px rgba(0,0,0,.5);

      @include mq(md) {
        //font-size: calc(880% + 12 * (48vw - 768px) / 232)
        font-size: calc(4.4em + 12 * (100vw - 768px) / 232)
      }

      @include mq(sm) {
        font-size: calc(4.4em + 44 * (100vw - 320px) / 448)
      }
    }
    p {
      display: inline-block;
      line-height: 1;
      margin: 0;
      font-size: $FONT-SIZE-18;
      font-weight: bold;
      text-shadow: 0 0 16px rgba(0,0,0,.5);
      position: relative;
      bottom: -.2em;

      @include mq(md) {
        //font-size: calc(360% + 6 * (48vw - 768px) / 232)
        font-size: calc(2.4em + 6 * (100vw - 768px) / 232)
      }

      @include mq(sm) {
        font-size: calc(1.8em + 18 * (100vw - 320px) / 448)
      }
    }
  }
}











/* ------------------------------------------------------------ */

.l-aboutHACCP {

  .leadtext {
    max-width: 780px;
    margin: 0 auto 40px;
    line-height: 2;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
    }
  }
  .note {
    font-size: $FONT-SIZE-16;
    max-width: 780px;
    margin: 40px auto 80px;
    text-align: left;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
    }
  }
  .process {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 780px;
    margin: 40px auto 160px;
    height: auto;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
      display: block;
    }

    img {
      width: 360px;
      height: auto;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
        height: auto;
      }

      & + img {

        @include mq(sm) {
          margin-top: 80px;
        }
      }
    }
  }
}

.haccpDescription {
  background-color: $COLOR__ACCENT-PRIMARY;
  color: #fff;

  .inner {
    max-width: 1000px;
    margin: 0 auto;
    padding: 60px 0;
    text-align: center;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
    }
  }

  .initial {
    font-size: 108px;
    font-weight: 700;
    letter-spacing: .75em;
    text-shadow: 0 0 16px rgba(0,0,0,.5);
    line-height: 1;
    text-indent: .8em;
    margin: 0 0 30px;
    padding: 0;

    @include mq(md) {
      text-indent: .1em;
      letter-spacing: .25em;
      font-size: 68px;
        margin: 0 0 10px;
    }

    @include mq(sm) {
      margin: 0 0 10px;
      font-size: 41px;
      text-indent: .1em;
      letter-spacing: .25em;
    }
  }
  .sentence {
    margin: 0;
    line-height: 1;
    //text-align: left;
  }
  .word {
    color: #fff;
    font-size: $FONT-SIZE-16;
    font-weight: 700;
    display: inline-block;
    letter-spacing: 0.03em;
    margin-bottom: 20px;

    @include mq(md) {
      font-size: $FONT-SIZE-12;
    }

    @include mq(sm) {
      font-size: $FONT-SIZE-12;
    }

    &::first-letter {
      color: #ff0;
    }

    &:nth-of-type(1) {
      margin: 0 .75em 0 -13em;

      @include mq(md) {
        margin: 0;
      }

      @include mq(sm) {
        margin: 0;
      }
    }
    &:nth-of-type(2) {
      margin: 0 10.5em 0 0;

      @include mq(md) {
        margin: 0;
      }

      @include mq(sm) {
        margin: 0;
      }
    }
    &:nth-of-type(3) {
      margin: 0 .75em 0 0;

      @include mq(md) {
        margin: 0;
      }

      @include mq(sm) {
        margin: 0;
      }
    }
    &:nth-of-type(4) {
      margin: 0 .75em 0 0;

      @include mq(md) {
        margin: 0;
      }

      @include mq(sm) {
        margin: 0;
      }
    }
    &:nth-of-type(5) {}
  }
  p {
    font-size: $FONT-SIZE-36;
    text-align: left;
    display: inline-block;
    margin: 0;
    letter-spacing: .175em;

    @include mq(md) {
      font-size: $FONT-SIZE-24;
      letter-spacing: 0;
    }

    @include mq(sm) {
      font-size: $FONT-SIZE-24;
      letter-spacing: 0;
    }

    span {
      border-bottom: solid 2px #fff;
      display: inline-block;
      padding-bottom: 3px;
    }
  }
}

.l-institutionalization {
  .inner {
    width: 780px;
    margin: 0 auto;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
    }
  }
  .leadtext {
    width: 780px;
    margin: 0 auto 40px;
    line-height: 2;

    @include mq(md) {
      width: 95%;
    }

    @include mq(sm) {
      width: 95%;
    }
  }

  .guideline {
    background-color: #e3f5ff;
    text-align: left;
    padding: 80px 0;

    &__title {
      color: $COLOR__ACCENT-PRIMARY;
      font-size: $FONT-SIZE-24;
      letter-spacing: .1em;
      text-align: center;
      margin: 0 0 20px;

      @include mq(md) {
        font-size: $FONT-SIZE-22;
      }

      @include mq(sm) {
      }
    }

    &__standard {
      width: 100%;
      padding: 20px;
      border: solid 1px $COLOR__ACCENT-PRIMARY;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @include mq(md) {
      }

      @include mq(sm) {
        flex-direction: column;
      }

      p {
        color: $COLOR__ACCENT-PRIMARY;
        font-size: $FONT-SIZE-18;
        font-weight: bold;
        width: 230px;

        @include mq(md) {
          width: auto;
          margin-right: 2%;
        }

        @include mq(sm) {
          width: auto;
        }
      }
    }
    &__situation {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @include mq(md) {
      }

      @include mq(sm) {
        flex-direction: column;
      }

      p {
        width: 100%;
        font-size: $FONT-SIZE-18;
        font-weight: bold;
        position: relative;

        @include mq(md) {
        }

        @include mq(sm) {
          text-align: center;
        }

        span {
          color: $COLOR__ACCENT-PRIMARY;
          font-size: $FONT-SIZE-36;
          line-height: 1;
          position: absolute;
          top: -6px;
          margin-left: 10px;

          @include mq(md) {
          }

          @include mq(sm) {
            display: none;
          }
        }
      }
    }
  }

  .standard__item {
    width: 240px;
    height: 90px;
    padding: 11px 0 11px 11px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
    }

    img {
      //width: 100px;
      width: auto;
      height: auto;
    }

    .label {
      color: $COLOR__FONT;
      display: inline-block;
      font-weight: bold;
      font-size: $FONT-SIZE-16;
      line-height: 1.5;
      letter-spacing: .01em;

      span {
        color: $COLOR__ACCENT-SECONDARY;
      }
    }

    & + .standard__item {
      margin-left: 30px;

      @include mq(md) {
        margin-left: .5%;
      }

      @include mq(sm) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  .commentary {
    font-size: $FONT-SIZE-16;
    max-width: 780px;
    margin: 80px auto 40px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mq(md) {
    }

    @include mq(sm) {
      flex-direction: column;
      width: 95%;
    }

    .left {
      width: 360px;
      line-height: 2;
      margin-top: -.5em;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
      }
    }

    .right {
      width: 360px;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
      }
    }

    .largeScale ,
    .smallScale {
      background-color: #e3f5ff;
      padding: 20px;

      h4 {
        margin: 0 0 20px;
        font-size: $FONT-SIZE-18;
        color: $COLOR__ACCENT-PRIMARY;
      }
      p {
        margin: 0 0 20px;

        .highlighter {
          background: linear-gradient(transparent 70%, #ff0 30%);
          display: inline;
          font-size: $FONT-SIZE-16;
          padding: 0;
        }
      }
      span {
        background-color: #fff;
        padding: 20px;
        display: inline-block;
        font-size: $FONT-SIZE-12;
      }
    }

    .largeScale {
      margin-bottom: 20px;
    }
    .smallScale {
    }
  }

  .note {
    font-size: $FONT-SIZE-16;
    max-width: 780px;
    margin: 40px auto 40px;
    text-align: left;
  }

  .chronology {
    margin-bottom: 80px;
    position: relative;
    z-index: 0;

    &:after {
      content: "";
      background-color: #fff;
      display: block;
      width: 1px;
      height: 480px;
      top: 60px;
      left: 50%;
      position: absolute;
      z-index: 1000;
      margin: 0 0 0 -379px;

      @include mq(md) {
        display: none;
      }

      @include mq(sm) {
      }
    }

    &__year {
      padding: 10px 0;

      img {
        width: 167px;
        height: 44px;
      }
    }

    .inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      //align-content: center;
      align-items: center;
      max-width: 780px;

      @include mq(md) {
        max-width: 780px;
      }

      @include mq(sm) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .y2017 {
      background-color: #00b0f0;
    }
    .y2018 {
      background-color: #ffcc00;
    }
    .y2019 {
      background-color: #000;
    }
    .y2020 {
      background-color: #00b050;
    }
    .y2021 {
      background-color: #ff0000;
    }

    &__detail {
      background-color: #fff;
      border-radius: 5px;
      width: 500px;
      height: 100px;
      padding: 20px;
      text-align: left;
      font-size: $FONT-SIZE-16;
      font-weight: bold;
      display: flex;
      align-items: center;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
        height: auto;
        margin-top: 10px;
      }

      & + li {
        margin-top: 1px;
      }
    }
  }
}

/* ------------------------------------------------------------ */


.l-aboutFoodpoisoning {
  max-width: 1000px;
  margin: 0 auto 160px;

  @include mq(md) {
  }

  @include mq(sm) {
    max-width: 95%;
    margin: 0 auto 80px;
  }

  .leadtext {
    max-width: 780px;
    margin: 0 auto 40px;
    line-height: 2;
  }

  figure {
    width: 640px;
    height: auto;
    margin: 0 auto;

    @include mq(md) {
      width: 100%;
      max-width: 640px;
    }

    @include mq(sm) {
      width: 100%;
      max-width: 640px;
    }
  }
}

.l-whenHappens {
  max-width: 1000px;
  margin: 0 auto 160px;

  @include mq(md) {
  }

  @include mq(sm) {
    max-width: 95%;
    margin: 0 auto 80px;
  }

  .leadtext {
    max-width: 780px;
    margin: 0 auto 40px;
    line-height: 2;
  }

  figure {
    width: 640px;
    height: auto;
    margin: 0 auto;

    @include mq(md) {
      width: 100%;
      max-width: 640px;
    }

    @include mq(sm) {
      width: 100%;
      max-width: 640px;
    }
  }
}

.l-causeAndMeasures {
  margin: 0 auto 80px;

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
    max-width: 1000px;
    margin: 0 auto;

    @include mq(md) {
      width: 100%;
      align-items: stretch;
    }

    @include mq(sm) {
      flex-direction: column;
    }
  }

  &__item {
    //height: 100%;

    @include mq(md) {
      display: flex;
      flex-direction: column;
    }

    @include mq(sm) {
      margin-bottom: 40px;
    }

    &:nth-of-type(1) {
      width: 18%;
      min-width: 180px;

      @include mq(md) {
        min-width: auto;
      }

      @include mq(sm) {
        width: 100%;
        min-width: auto;
      }

      ul {
        background-color: #e0caea;
        border-radius: 10px 0 0 10px;

        @include mq(md) {
        }

        @include mq(sm) {
          border-radius: 0;
        }
      }
    }
    &:nth-of-type(2) {
      width: 63%;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
      }

      ul {
        background-color: #f5f3c8;
        //border-radius: 10px;
      }
    }
    &:nth-of-type(3) {
      width: 18%;
      min-width: 180px;

      @include mq(md) {
        min-width: auto;
      }

      @include mq(sm) {
        width: 100%;
        min-width: auto;
      }
      ul {
        background-color: #e8e8e8;
        border-radius: 0 10px 10px 0;

        @include mq(md) {
        }

        @include mq(sm) {
          border-radius: 0;
        }
      }
    }


    h3 {
      margin: 0 0 20px;
      text-align: center;
      font-size: $FONT-SIZE-16;
    }
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: flex-start;
      flex-wrap: wrap;
      padding: 20px;
      min-height: 330px;
      margin: 0;

      @include mq(md) {
        min-height: 88%;
      }

      @include mq(sm) {
        flex-direction: column;
        min-height: auto;
        width: 100%;
      }
    }
    li {
      width: 140px;
      height: 140px;
      margin: 0;
      background-color: #fff;

      @include mq(md) {
        width: 100%;
        height: 50%;
      }

      @include mq(sm) {
        width: 100%;
        height: auto;
      }


      &:nth-of-type(-n + 4) {
        margin-bottom: 10px;

        @include mq(md) {
        }

        @include mq(sm) {
          margin-bottom: 0;
        }
      }

      & + li {

        @include mq(md) {
        }

        @include mq(sm) {
          margin-top: 20px;
        }
      }
    }

    img {
      cursor: pointer;
    }
  }
  &__item {
    &:nth-of-type(2) {
      li {
        @include mq(md) {
          width: 23.5%;
        }
      }
    }
  }
}



.modaal-container {
  border-radius: 10px;
  //overflow: hidden;
  width: calc( 100vw - 50px );
}

.modaal-content-container {
  display: flex;
  flex-direction: row;
  //max-width: 780px;
  max-height: 80vh;

  @include mq(md) {
  }

  @include mq(sm) {
    flex-direction: column;
    overflow-y: scroll;
  }

  figure {
    width: 40%;
    height: calc(100% - 60px);
    margin: 30px 0 30px 30px;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 80%;
      margin: 0 auto;
    }
  }

  .modalBody {
    width: 60%;
    height: 80vh;
    overflow-y: scroll;
    color: $COLOR__FONT;
    padding: 30px 30px 30px 0;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
      overflow-y: visible;
      padding-right: 10px;
    }

    h3 {
      font-size: $FONT-SIZE-36;
      line-height: 1;
      margin: 0 0 20px;
      position: relative;
      padding-top: 60px;
      padding-left: 160px;

      @include mq(md) {
      }

      @include mq(sm) {
        padding-top: 120px;
        padding-left: 0;
        text-align: center;
        margin: 0 0 10px;
      }

      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        //background-color: #ccc;
        width: 100px;
        height: 100px;
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-size: 140px;
        font-weight: bold;
        line-height: 1;
        text-align: center;

        @include mq(md) {
        }

        @include mq(sm) {
          left: calc(50% - 50px);
          font-size: 100px;
        }
      }

      &.num01:before { content: "01"; }
      &.num02:before { content: "02"; }
      &.num03:before { content: "03"; }
      &.num04:before { content: "04"; }
      &.num05:before { content: "05"; }
      &.num06:before { content: "06"; }
      &.num07:before { content: "07"; }
      &.num08:before { content: "08"; }
      &.num09:before { content: "09"; }
      &.num10:before { content: "10"; }
    }

    .en {
      font-size: $FONT-SIZE-16;
      padding-left: 160px;
      margin: 0 0 40px;
      color: #999;

      @include mq(md) {
      }

      @include mq(sm) {
        padding-left: 0;
        text-align: center;
      }
    }

    h4 {
      font-size: $FONT-SIZE-18;
      border-left: solid 5px #972ab3;
      padding: 0;
      padding-left: 10px;
      margin: 0 0 20px;
      line-height: 1.5;
    }

    ul {
      margin: 0 0 40px;
    }

    li {
      font-size: $FONT-SIZE-16;
      margin: 0 0 0 30px;
      list-style: disc;
    }
  }
}





/* ------------------------------------------------------------ */


.l-HygieneRelated {
  width: 100%;
  margin: 0 0 80px;
}









/* ------------------------------------------------------------ */

.l-creanpedia {

}

.l-latestIssue {
  margin: 0 auto 80px;
  max-width: 1000px;

  @include mq(md) {
    width: 95%;
  }

  @include mq(sm) {
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: $FONT-SIZE-24;
    margin: 0;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;

    @include mq(md) {
    }

    @include mq(sm) {
      flex-direction: column;
    }
  }

  &__vol {
    margin: 0;
    padding: 10px;
    background-color: $COLOR__ACCENT-PRIMARY;
    color: #fff;
    width: 20%;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
    }
  }

  &__title {
    margin: 0 0 0 20px;
    padding: 10px;
    width: 60%;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 95%;
      margin: 5px auto 0;
    }
    text-align: left;
  }

  &__time {
    margin: 0 0 0 20px;
    padding: 10px;
    width: 20%;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 95%;
      margin: 0 auto;
      text-align: left;
      font-size: $FONT-SIZE-16;
      padding: 0 10px 10px;
    }
  }
  a {
    color: $COLOR__ACCENT-PRIMARY;
    i.fa {
      color: #ccc;
      margin-left: .5em;
    }
  }
}

.l-backNumber {
  margin: 0 auto 80px;
  max-width: 1000px;

  @include mq(md) {
    width: 95%;
  }

  @include mq(sm) {
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: $FONT-SIZE-24;
    margin: 0;
    border-top: solid 1px #ccc;

    @include mq(md) {
    }

    @include mq(sm) {
      flex-direction: column;
    }

    &:last-child {
      border-bottom: solid 1px #ccc;
    }
  }

  &__vol {
    margin: 0;
    padding: 10px;
    width: 20%;
    background-color: #eee;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
    }
  }

  &__title {
    margin: 0 0 0 20px;
    padding: 10px;
    width: 60%;
    text-align: left;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 95%;
      margin: 5px auto 0;
    }
  }

  &__time {
    margin: 0 0 0 20px;
    padding: 10px;
    width: 20%;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 95%;
      margin: 0 auto;
      text-align: left;
      font-size: $FONT-SIZE-16;
      padding: 0 10px 10px;
    }
  }
  a {
    color: $COLOR__ACCENT-PRIMARY;
    i.fa {
      color: #ccc;
      margin-left: .5em;
    }
  }
}


/* ------------------------------------------------------------ */
