
/* =========================================================
section
========================================================= */


.l-recruit {

  h3 {
    font-size: $FONT-SIZE-48;
    line-height: 1;
    margin: 0 0 40px;
    //text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.83);
    position: relative;
    z-index: 1000;

    & + p {
      font-size: $FONT-SIZE-24;
      font-style: italic;
      margin: 0 0;
      //text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.83);
      position: relative;
      z-index: 1000;

      @include mq(sm) {
        font-size: $FONT-SIZE-18;
      }
    }
  }

  .recruitImage {
    //background-color: #eee;
    margin-top: -80px;
    margin-bottom: 80px;
    padding: 200px 0;
    background: url(#{$rootUrl}/images/recruit/recruit_img_01.jpg) center no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    z-index: 100;

    @include mq(sm) {
      padding: 120px 0;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $COLOR__SUB-SECONDARY;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0dadf1+0,0039ca+50,09c0a0+100 */
      background: #0dadf1; /* Old browsers */
      background: -moz-linear-gradient(-45deg,  #0dadf1 0%, #0039ca 50%, #09c0a0 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg,  #0dadf1 0%,#0039ca 50%,#09c0a0 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg,  #0dadf1 0%,#0039ca 50%,#09c0a0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0dadf1', endColorstr='#09c0a0',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

      opacity: .83;
      z-index: 120;
    }

    svg {
      fill: rgba(0,0,0,.5);
      position: absolute;
      top: calc(50% - 111px);
      left: calc(50% - 226px);
      z-index: 140;
    }
  }

  .block {
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1% 1%;
    margin: 0 0 80px;

    @include mq(sm) {
      //margin: 0;
      padding: 0 2.5% 2.5%;
      flex-direction: column;
    }

    .leadtext {
      width: 98%;
      text-align: center;
      margin: 0 0 20px;

      @include mq(sm) {
        width: 95%;
        text-align: left;
        margin: 0 auto 20px;
      }
    }

    .block {
      width: 49.5%;
      padding: 80px 20px;

      @include mq(sm) {
        width: 100%;
      }
    }
  }


  .salesNclerk ,
  .researcher {
    position: relative;
    z-index: 0;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(#fff,.5);
      width: 100%;
      height: 100%;
      z-index: 1;
      border: solid 1px #eee;
    }

    h4 {
      font-size: $FONT-SIZE-24;
      margin: 0 0 10px;
      line-height: 1;
      position: relative;
      z-index: 10;
    }

    .en {
      font-size: $FONT-SIZE-14;
      color: #999;
      margin: 0;
      line-height: 1;
      //font-weight: bold;
      position: relative;
      z-index: 10;
    }

    ul {
      margin: 40px 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      //flex-wrap: wrap;
      position: relative;
      z-index: 10;

      @include mq(md) {
        flex-direction: column;
        align-items: center;
      }

      @include mq(sm) {
        flex-direction: column;
        align-items: center;
      }
    }

    li {
      font-size: $FONT-SIZE-18;
      width: 240px;
      line-height: 2;

      & + li {
        margin-left: 20px;

        @include mq(md) {
          margin-left: 0;
          margin-top: 20px;
        }

        @include mq(sm) {
          margin-left: 0;
          margin-top: 20px;
        }
      }

      a {
        height: 60px;
        border-radius: 3px;
        background-color: $COLOR__ACCENT-PRIMARY;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: #fff;
        padding: 10px 5px;
        position: relative;
        line-height: 1;

        &:after {
          content: "\f105";
          font-family: "fontAwesome";
          font-size: $FONT-SIZE-24;
          position: absolute;
          right: 10px;
          top: calc(50% - .5em);
          line-height: 1;
        }

        @include mq(md) {
        }

        &:hover {}
      }
    }
  }

  .salesNclerk {
    //background-color: #eee;

    background: url(#{$rootUrl}/images/recruit/salesnclerk_bg.jpg) center no-repeat;
    background-size: cover;
    @include mq(sm) {
      margin-bottom: 20px;
    }
  }

  .researcher {
    //background-color: #ddd;

    background: url(#{$rootUrl}/images/recruit/researcher_bg.jpg) center no-repeat;
    background-size: cover;
  }
}

/* ------------------------------------------------------------ */

.l-salesnclerk ,
.l-researcher {

  .leadtext {
    max-width: 1000px;
    margin: 0 auto 80px;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }
  .recruitMenu {
    max-width: 1000px;
    margin: 40px auto 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
      flex-direction: column;
    }

    &__item {
      font-size: $FONT-SIZE-18;
      text-align: left;
      //line-height: 2;
      //border: solid 1px #ccc;
      width: calc((100% - 40px ) / 2);
      padding: 20px;
      background-color: #f6f6f6;

      @include mq(sm) {
        width: 100%;
      }

      & + li {
        margin-left: 40px;

        @include mq(sm) {
          margin-left: 0;
          margin-top: 10px;
        }
      }

      span {
        display: block;
      }

      ul {
        margin: 5px 0 0;

        li {
          display: inline-block;

          & + li {
            margin-left: 20px;
          }
        }
      }
    }

    a {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: $FONT-SIZE-14;
      line-height: 20px;
      padding: 0 0 0 15px;
      background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
      background-size: 5px;
      color: $COLOR__ACCENT-PRIMARY;

      &.current {
        //border: solid 1px #ccc;
        //color: #ccc;

      }
    }
  }

  h3 {
    border-left: solid 5px $COLOR__ACCENT-PRIMARY;
    margin: 0 auto;
    padding: 0 0 0 10px;
    font-size: $FONT-SIZE-24;
    max-width: 1000px;
    text-align: left;

    @include mq(md) {
      max-width: 95%;
    }
  }

  h4 {
    //border-left: solid 5px $COLOR__ACCENT-PRIMARY;
    margin: 0 auto 20px;
    //padding: 0 0 0 10px;
    font-size: $FONT-SIZE-18;
    max-width: 1000px;
    text-align: left;
    color: $COLOR__ACCENT-PRIMARY;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }

  &__table {
    @include mq(md) {
      max-width: 95%;
    }
  }

  .entry {
    margin: 40px auto 80px;
    max-width: 1000px;
    font-size: $FONT-SIZE-18;
    line-height: 2;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }

    a {
      display: block;
      text-align: center;
      padding: 15px 10px;
      //border: solid 1px $COLOR__ACCENT-PRIMARY;
      //color: $COLOR__ACCENT-PRIMARY;
      //border-radius: 3px;
      background-color: $COLOR__ACCENT-PRIMARY;
      color: #fff;
      font-weight: bold;
      line-height: 1;

      span {
        display: block;
        font-size: $FONT-SIZE-10;
        margin-top: 5px;
      }

      @include mq(lg) {
        &:hover {
          background-color: darken($COLOR__ACCENT-PRIMARY,10%);
          box-shadow: 0 2px 8px rgba(#000,.2);
          transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
        }
      }

      @include mq(md) {
        &:hover {
          background-color: $COLOR__ACCENT-PRIMARY;
          box-shadow: 0 2px 8px rgba(#000,.2);
          transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
        }
      }

      @include mq(sm) {
        background-color: $COLOR__ACCENT-PRIMARY;
      }
    }
  }

  .contactus {
    //border-top: solid 1px #ccc;
    margin: 40px auto 80px;
    max-width: 1000px;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }

    h4 {
      border-bottom: solid 1px #ccc;
      margin: 0 0 20px;
      padding-bottom: 20px;
    }

    p {
      font-size: $FONT-SIZE-18;
      text-align: left;
    }

    a:hover {
      color: $COLOR__FONT;
    }

    .arrow {
      display: inline-block;
      margin-top: 10px;
      padding: 0 0 0 15px;
      background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
      background-size: 5px;
      color: $COLOR__ACCENT-PRIMARY;
      &:hover {
        color: $COLOR__ACCENT-PRIMARY;
      }
    }
  }
}

.l-salesnclerk {}

.l-researcher {}
