
/* =========================================================
section
========================================================= */


.l-news {
  .inner {
    max-width: 1000px;
    margin: 0 auto;
    margin: 0 auto 80px;

    @include mq(md) {
      width: 95%;
      margin: 0 auto 40px;
    }
    @include mq(sm) {
      width: 95%;
      margin: 0 auto 40px;
    }
  }
}
