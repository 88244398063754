
/* =========================================================
section
========================================================= */
.l-entry ,
.l-inquiry {

  .leadtext {
    max-width: 780px;
    margin: 0 auto;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }

  .inner {
    max-width: 780px;
    margin: 0 auto 80px;
    text-align: left;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }

  .inquiryType {

    & + .inquiryType {
      margin-top: 80px;
    }
  }


  h3 {
    border-bottom: solid 1px #ccc;
    font-size: $FONT-SIZE-24;
  }
  p {
    font-size: $FONT-SIZE-18;
  }

  a {
    padding: 0 0 0 15px;
    background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
    background-size: 5px;
    color: $COLOR__ACCENT-PRIMARY;
  }

  table {
    margin-bottom: 0;
    @include mq(md) {
    }

    @include mq(sm) {
      display: block;
    }

    tbody {

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
        display: block;
      }
    }
    tr {
      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
        display: block;
      }
    }

    th {
      width: 31.25%;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
        display: block;
        background-color: #eee;
      }
    }
    td {
      width: 68.75%;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 100%;
        display: block;
        padding: 20px 20px 40px;
      }
    }
    input,
    textarea,
    select {
      padding: 9px ;
      background-color: #fff;
      border: solid 1px $COLOR__ACCENT-PRIMARY;
      border-radius: 3px;
      font-size: $FONT-SIZE-18;
      line-height: 1;
      width: 100%;
      color: $COLOR__FONT;

      & + span {
        display: block;
        margin-top: 10px;
        font-size: $FONT-SIZE-14;
      }
    }
    select {
      height: 2.4em;
      margin-bottom: 2em;
    }
    textarea {
      min-height: 5em;
    }
    .required {
      background-color: $COLOR__ALERT;
      color: #fff;
      font-size: $FONT-SIZE-10;
      padding: 3px 6px;
      border-radius: 3px;
      text-align: center;
      line-height: 1;
      position: relative;
      top: -2px;
    }
  }
  .block--submit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button {
    //width: 100%;
    height: 40px;
    background-color: $COLOR__ACCENT-PRIMARY;
    color: #fff;
    line-height: 40px;
    margin: 20px 0 0;
    padding: 0 20px;
    cursor: pointer;

    @include mq(lg) {
      &:hover {
        background-color: darken($COLOR__ACCENT-PRIMARY,10%);
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(md) {
      &:hover {
        background-color: $COLOR__ACCENT-PRIMARY;
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(sm) {
      background-color: $COLOR__ACCENT-PRIMARY;
    }
    &--submit {

    }
  }
  label {
    width: 48%;
    display: flex;
    padding: 9px;
    border-radius: 3px;
    cursor: pointer;
  }
  .radiogroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    input[type=radio] {
      display: none;
    }

    input[type=radio] + label {
      background-color: #f9f9f9;
      border: solid 1px #eee;

      & + input {
        pointer-events: none;
        background-color: #f9f9f9;
        border: solid 1px #eee;
      }
    }

    input[type=radio]:checked + label {
      background-color: $COLOR__ACCENT-PRIMARY;
      border: solid 1px $COLOR__ACCENT-PRIMARY;
      color: #fff;

      & + input {
        background-color: #fff;
        border: solid 1px $COLOR__ACCENT-PRIMARY;
        pointer-events: auto;
      }
    }
  }
  .otherRegion {
    margin-top: 20px;
  }
}

.l-entry {
  table {
    select {
      margin: 0;
    }
  }
  .graduation {
    margin-top: 20px;
  }
  .half {
    width: 50%;
    margin-right: .5em;

    @include mq(sm) {
      width: 70%;
    }
  }
  .tableTitle {
    border-bottom: none;
    font-size: $FONT-SIZE-24;
    margin: 80px 0 0;
  }
}

.l-confirm {
  .leadtext {
    margin: 0 auto 40px;
  }
  table {
    p {
      padding: 10px 0;
      background-color: #fff;
      //border: solid 1px #ccc;
      //border-radius: 3px;
      font-size: $FONT-SIZE-18;
      line-height: 1;
      width: 100%;
      color: $COLOR__FONT;
    }
    h4 {
      font-size: $FONT-SIZE-18;
      border-bottom: solid 1px #ccc;
    }
  }
  .block--confirm {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .button + .button {
      margin-left: 20px;
    }
  }
  .button--back {
    background-color: $COLOR__FONT;

    @include mq(lg) {
      &:hover {
        background-color: darken($COLOR__FONT,10%);
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(md) {
      &:hover {
        background-color: $COLOR__FONT;
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(sm) {
      background-color: $COLOR__FONT;
    }
  }
}
