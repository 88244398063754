
/* =========================================================
section
========================================================= */

.login {
  .globalWrapper {
    background-color: #eee;
    background: url(#{$rootUrl}/images/home/water_bg.png);
    background-position: center top;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    min-height: calc(100vh - 63px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
  }
}

.loginbox {
  width: 640px;
  min-height: 400px;
  background-color: #fff;
  padding: 40px 0;
  box-shadow: 4px 0 32px rgba(#000,.15);
  border-top: solid 5px $COLOR__ACCENT-PRIMARY;

  @include mq(sm) {
    width: 100%;
  }

  img {
    width: 80px;
    height: auto;
    margin: 0 auto 20px;
  }
  h1 {
    font-size: $FONT-SIZE-24;
    margin: 0 0 20px;
    padding: 0;
  }
}

.common {}
.firstTime__entry {
  display: none;
  .firstTime & {
    display: block;
  }
}

.formRow {
  border-top: solid 1px #ccc;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 20px;
  text-align: left;
  font-size: $FONT-SIZE-18;

  @include mq(sm) {
    flex-direction: column;
    margin: 0;
  }
  .title {
    width: 30%;
    padding: 10px;

    @include mq(sm) {
      width: 100%;
      background-color: #eee;
    }
  }
  .input {
    width: 70%;
    padding: 10px 10px 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include mq(sm) {
      width: 100%;
      padding: 10px 10px 20px;
    }
  }
  input {
    border: solid 1px #ccc;
    font-size: $FONT-SIZE-18;
    padding: 5px;
    width: 100%;
    border-radius: 3px;

    &[data-input-name="name"] {
      width: calc((100% - 10px) / 2);
    }
    &[data-input-name="kana"] {
      width: calc((100% - 10px) / 2);
    }
  }
  &.submit {
    justify-content: center;
  }
  .error {
    font-size: $FONT-SIZE-14;
    color: $COLOR__ALERT;
    margin-top: 10px;
    display: inline-block;
    width: 100%;

    & + span {
      margin-top: 5px;
    }
  }
  .button {
    //width: 100%;
    height: 40px;
    background-color: $COLOR__ACCENT-PRIMARY;
    color: #fff;
    line-height: 40px;
    margin: 20px 0 0;
    padding: 0 20px;
    cursor: pointer;

    @include mq(lg) {
      &:hover {
        background-color: darken($COLOR__ACCENT-PRIMARY, 10%);
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(md) {
      &:hover {
        background-color: $COLOR__ACCENT-PRIMARY;
        box-shadow: 0 2px 8px rgba(#000,.2);
        transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
      }
    }

    @include mq(sm) {
      background-color: $COLOR__ACCENT-PRIMARY;
    }
    &--submit {

    }
  }
}
.backtoHome {
  text-align: left;
  margin-left: 20px;
  font-size: $FONT-SIZE-14;

  i {
    margin-right: 5px;
  }
}










.l-mypage {

  &__browse,
  &__favorite {

    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    @include mq(md) {
      width: calc(100% - 20px);
    }

    @include mq(sm) {
      width: calc(100% - 20px);
    }
  }

  &__list {
    clear: both;
    margin: 0 0 0;
  }

  &__item {
    text-align: left;
    min-height: 60px;
    border-bottom: solid 1px #ddd;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 18px 50px;
    font-size: $FONT-SIZE-16;

    &:first-child {
      border-top: solid 1px #ddd;
    }

    @include mq(md) {
    }

    @include mq(sm) {
      padding: 18px 0;
      flex-wrap: wrap;
    }
  }

  &__favorite &__item {
    padding: 0;
  }

  &__browse {
    padding: 0 0 80px;

    @include mq(md) {
      padding: 40px 0 40px;
    }

    @include mq(sm) {
      padding: 40px 0 40px;
    }

    .l-mypage__date {
      display: inline-block;
      font-size: $FONT-SIZE-16;
      //width: 160px;
      letter-spacing: -.025em;
      min-width: 5em;

      @include mq(md) {
      }

      @include mq(sm) {
        display: block;
        margin-left: 10px;
      }

      img {
        width: 80px;
        height: auto;
      }
    }

    .l-mypage__description {
      display: block;
      font-size: $FONT-SIZE-16;
      margin: 0 0 0 10px;
      width: 100%;

      @include mq(md) {
      }

      @include mq(sm) {
        margin: 0;
        margin-top: calc(18px - .25em);
        padding: 0 10px;
        flex-basis: 100%;
        //background-color: #fafafa;
      }
    }
  }

  &__favorite {
    padding: 80px 0 80px;

    @include mq(md) {
      padding: 40px 0 40px;
    }

    @include mq(sm) {
      padding: 40px 0 40px;
    }

    .l-mypage__date {
      display: inline-block;
      font-size: $FONT-SIZE-16;
      //width: 160px;
      letter-spacing: -.025em;
      width: 80px;

      @include mq(md) {
      }

      @include mq(sm) {
      }

      img {
        width: 80px;
        height: auto;
      }
    }

    .l-mypage__description {
      display: block;
      font-size: $FONT-SIZE-16;
      margin: 0 0 0 10px;
      width: calc(100% - 180px);
      word-break: break-all;
      padding-right: 20px;

      @include mq(md) {
        padding-right: 10px;
      }

      @include mq(sm) {
        padding-right: 10px;
      }
    }
  }

  &__delete ,
  &__detail {

    .button {
      //width: 100%;
      height: 40px;
      color: #fff;
      line-height: 40px;
      //margin: 20px 0 0;
      padding: 0 10px;
      cursor: pointer;

      @include mq(lg) {
        &:hover {
          box-shadow: 0 2px 8px rgba(#000,.2);
          transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
        }
      }

      @include mq(md) {
        &:hover {
          box-shadow: 0 2px 8px rgba(#000,.2);
          transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
        }
      }
      @include mq(sm) {
      }

      i {
        margin-right: 5px;

        @include mq(md) {
          margin-right: 0;
        }
        @include mq(sm) {
          margin-right: 0;
        }
      }

      span {

        @include mq(md) {
          display: none;
        }
        @include mq(sm) {
          display: none;
        }
      }
    }
  }

  &__delete {

    .button {
      &--delete {
        background-color: $COLOR__FONT;
        width: 80px;

        @include mq(lg) {
          &:hover {
            background-color: darken($COLOR__FONT, 10%);
          }
        }

        @include mq(md) {
          width: 40px;
          border-radius: 20px;
          &:hover {
            background-color: $COLOR__FONT;
          }
        }

        @include mq(sm) {
          background-color: $COLOR__FONT;
          width: 40px;
          border-radius: 20px;
        }
      }
    }
  }
  &__detail {
    .button {

      &--detail {
        background-color: $COLOR__ACCENT-PRIMARY;
        width: 80px;
        margin-left: 20px;
        text-align: center;

        @include mq(lg) {
          &:hover {
            background-color: darken($COLOR__ACCENT-PRIMARY, 10%);
          }
        }

        @include mq(md) {
          width: 40px;
          border-radius: 20px;
          margin-left: 10px;
          &:hover {
            background-color: $COLOR__ACCENT-PRIMARY;
          }
        }

        @include mq(sm) {
          width: 40px;
          border-radius: 20px;
          margin-left: 10px;
          background-color: $COLOR__ACCENT-PRIMARY;
        }
      }
    }
  }
  &__logout {
    margin: 0 auto 80px;
    .button {
      &--logout {
        background-color: $COLOR__ACCENT-PRIMARY;
        text-align: center;
        padding: 10px 20px;
        color: #fff;
        cursor: pointer;

        @include mq(lg) {
          &:hover {
            background-color: darken($COLOR__ACCENT-PRIMARY, 10%);
          }
        }

        @include mq(md) {
          &:hover {
            background-color: $COLOR__ACCENT-PRIMARY;
          }
        }

        @include mq(sm) {
          background-color: $COLOR__ACCENT-PRIMARY;
        }
      }
    }
  }
}





.l-anet {
  .inner {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 0 80px;
  }

  .anetMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 40px;
    @include mq(md) {
      width: 95%;
      margin: 0 auto 40px;
    }
    @include mq(sm) {
      width: 95%;
      margin: 0 auto 40px;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: flex-start;
      text-align: left;
      @include mq(sm) {
        flex-direction: column;
      }
      li {
        width: 23%;
        background-color: #e3f5ff;
        padding: 20px;
        @include mq(md) {
          width: 49%;
          margin-bottom: 20px;
        }
        @include mq(sm) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      p {
        font-size: $FONT-SIZE-12;
        line-height: 1.25;
        margin: 10px 0 0;
      }
    }
    a {
      font-size: $FONT-SIZE-18;
      padding: 0 0 0 15px;
      background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
      background-size: 5px;
      color: $COLOR__ACCENT-PRIMARY;
    }
  }

  .anetHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 20px;

    p {
      font-size: $FONT-SIZE-18;
      text-align: left;
    }

    img {
      width: 80px;
      margin-right: 20px;
    }
  }
}
