
/* =========================================================
Business
========================================================= */

.l-business {
  margin:  0 0 80px;

  .leadtext {
    max-width: 780px;
    margin: 0 auto;
    line-height: 2;
    text-align: center;

    @include mq(sm) {
      max-width: 95%;
      text-align: left;
    }
  }

  &__service ,
  &__industry {

    h3 {
      text-align: left;
      font-size: $FONT-SIZE-24;
      max-width: 1000px;
      margin: 0 auto 40px;
      padding-left: 10px;
      @include mq(md) {
        max-width: 95%;
      }
    }

    .inner {
      max-width: 1000px;
      margin: 40px auto 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include mq(md) {
        max-width: 95%;
      }

      @include mq(sm) {
        max-width: 95%;
        flex-direction: column;
      }
    }

    .side {
      width: 42%;

      @include mq(sm) {
        width: 100%;
      }

      p {
        font-size: $FONT-SIZE-18;
        text-align: left;
        line-height: 2;
      }

      .en {
        font-size: $FONT-SIZE-36;
        font-weight: bold;
        margin: 0;
        line-height: 1;
      }
    }

    ul {
      margin: 0;
      font-size: $FONT-SIZE-18;

      @include mq(sm) {
        margin-bottom: 40px;
      }
    }
    li {

      & + li {
        margin-top: 10px;
      }
    }
    a {
      line-height: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 380px;
      height: 50px;
      color: #fff;
      text-align: left;
      padding-left: 54px;

      @include mq(md) {
        width: 100%;
      }

      @include mq(sm) {
        width: 100%;
      }
    }

    .application {
      width: 56%;
      padding: 0 0 40px 40px;
      margin: 0 0 0 0;
      //box-shadow: 0 4px 32px rgba(0,0,0,.2);
      position: relative;

      @include mq(sm) {
        padding: 20px 0 40px;
        width: 100%;
      }

      &__description {
        width: 100%;
        p {
          font-size: $FONT-SIZE-18;
          text-align: left;
          margin: 20px 0 0;
        }

        a {
          background-color: transparent;
          display: flex;
          justify-content: center;
          text-align: center;
          margin: 0 auto;
          padding: 0;
          width: 80%;
          height: auto;
        }
        img {
          margin: 0 auto;
        }
      }
    }

    figure {
      max-width: 1000px;
      height: 360px;
      margin: 0 auto 0;

      @include mq(sm) {
        margin: 0 auto 0;
      }
    }
  }

  &__service {

    h3 {
      border-left: solid 5px #0079a8;
    }

    .service__detail {

      p {
      }

      .en {
        color: #bfcde0;
      }
    }

    ul {}
    li {
      &:nth-of-type(1) a {
        background-image: url(#{$rootUrl}/images/common/icon/service.png);
        background-position:  9px center;
        background-repeat: no-repeat;
        background-size: 36px;
      }
      &:nth-of-type(2) a {
        background-image: url(#{$rootUrl}/images/common/icon/agency.png);
        background-position:  12px center;
        background-repeat: no-repeat;
        background-size: 34px;
      }
    }

    a {
      background-color: #0079a8;
      position: relative;
      border-radius: 3px;
      min-height: 50px;

      &:after {
        content: "\f105";
        font-family: "fontAwesome";
        font-size: $FONT-SIZE-24;
        position: absolute;
        right: 10px;
        top: calc(50% - .5em);
        line-height: 1;
      }
    }

    .application {
      //background-color: #f9f9f9;
    }

    figure {
      background: url(#{$rootUrl}/images/business/service_img.jpg) center no-repeat;
      background-size: cover;
    }
  }

  &__industry {
    margin: 40px 0 0;

    @include mq(sm) {
      margin: 40px 0 0;
    }

    .industry__detail {

      p {
      }

      .en {
        color: #a3cda9;
      }
    }

    h3 {
      border-left: solid 5px #0a7c48;
    }

    ul {}
    li {
      &:nth-of-type(1) a {
        background-image: url(#{$rootUrl}/images/common/icon/industry.png);
        background-position: 12px center;
        background-repeat: no-repeat;
        background-size: 33px;
      }
    }

    a {
      background-color: #0a7c48;
      position: relative;
      border-radius: 3px;
      min-height: 50px;

      &:after {
        content: "\f105";
        font-family: "fontAwesome";
        font-size: $FONT-SIZE-24;
        position: absolute;
        right: 10px;
        top: calc(50% - .5em);
        line-height: 1;
      }
    }

    .application {
      //background-color: #fff;
    }

    figure {
      background: url(#{$rootUrl}/images/business/industry_img.jpg) center no-repeat;
      background-size: cover;
    }
  }
}


/* ------------------------------------------------------------ */

.l-businessField ,
.l-soldto ,
.l-mainProducts {
  margin-bottom: 160px;

  @include mq(md) {
    margin-bottom: 80px;
  }

  @include mq(sm) {
    margin-bottom: 80px;
  }

  .leadtext {
    max-width: 1000px;
    margin: 0 auto 40px;
    text-align: center;
    line-height: 2;

    @include mq(md) {
    }

    @include mq(sm) {
      max-width: 95%;
      text-align: left;
    }
  }

  .inner {
    max-width: 1000px;
    margin: 0 auto 0px;

    @include mq(md) {
      max-width: 95%;
    }

    @include mq(sm) {
      max-width: 95%;
    }
  }
}




.l-businessField {

  .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mq(md) {
    }

    @include mq(sm) {
      flex-direction: column;
    }
  }
  figure {
    width: 48%;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .block {
    width: 48%;
    text-align: left;
    line-height: 2;
    font-size: $FONT-SIZE-18;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
    }

    p {
      margin: 0;
    }

    a {
      padding: 0 0 0 15px;
      background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
      background-size: 5px;
      color: $COLOR__ACCENT-PRIMARY;
      float: right;

      @include mq(md) {
      }

      @include mq(sm) {
        float: none;
      }
    }
  }
}

.l-soldto {

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    img {
      width: auto;
      height: auto;

      @include mq(md) {
      }

      @include mq(sm) {
        width: 90%;
      }
    }
  }
}

.l-mainProducts {

  .inner {
    text-align: left;
    //margin-bottom: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @include mq(md) {
      width: 95%;
      margin: 0 auto;
    }

    @include mq(sm) {
      flex-direction: column-reverse;
    }
  }

  figure {
    width: 48%;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
    }
  }

  .block {
    width: 48%;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 100%;
    }
  }

  h3 {
    font-size: $FONT-SIZE-24;
    border-bottom: solid 1px #ccc;
  }

  p {
    font-size: $FONT-SIZE-18;
    //line-height: 2;
  }

  ul {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    font-size: $FONT-SIZE-18;
    min-width: 50%;
    line-height: 2;

    a {
      padding: 0 0 0 15px;
      background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
      background-size: 5px;
      color: $COLOR__ACCENT-PRIMARY;
    }
  }
}


/* ------------------------------------------------------------ */


.l-agency {
  &__map {
    background-color: #e8f7ff;

    .inner {
      max-width: 1000px;
      min-height: 760px;
      margin: 0 auto;
      padding: 20px 120px;
      background: url(#{$rootUrl}/images/business/agency_map.png) center no-repeat;
      position: relative;

      @include mq(md) {
        max-width: 95%;
        padding: 20px 0;
      }

      @include mq(sm) {
        max-width: 95%;
        min-height: auto;
        padding: 20px 0 85%;
        background-position: center bottom;
        background-size: 120%;
      }
    }

    ul {
      margin: 0;
      text-align: left;

      @include mq(sm) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    li {
      position: absolute;
      width: 180px;
      height: 60px;
      top: 50%;
      left: 50%;

      @include mq(lg) {
        &:hover {
          background-color: $COLOR__ACCENT-PRIMARY;
          box-shadow: 0 2px 16px rgba(#000,.2);
          transition: background-color .5s ease 0s,box-shadow .5s ease 0s;
        }
      }

      @include mq(sm) {
        position: static;
      }

      &:nth-of-type(1) {
        margin: -330px 0 0 -90px;
      }

      &:nth-of-type(2) {
        margin: -110px 0 0 190px;
      }

      &:nth-of-type(3) {
        margin: 20px 0 0 190px;
      }

      &:nth-of-type(4) {
        margin: 150px 0 0 -10px;
      }

      &:nth-of-type(5) {
        margin: -30px 0 0 -220px;
      }

      &:nth-of-type(6) {
        margin: 250px 0 0 -170px;
      }

      &:nth-of-type(7) {
        width: 100px;
        height: 40px;
        margin: 290px 0 0 220px;
        @include mq(sm) { & a {line-height: 40px;} }
      }

      &:nth-of-type(n) {
        @include mq(sm) { width: 48.5%;margin: 10px 0 0; }
      }
    }

    h3 {
      color: #4370aa;
      font-size: $FONT-SIZE-24;
      font-weight: 500;
      text-align: left;
      margin: 20px 0 0;
      @include mq(sm) { text-align: center;}
    }
    p {
      font-size: $FONT-SIZE-16;
      text-align: left;
    }

    img {
      width: 120px;
      height: auto;
      margin: 0;
      @include mq(sm) { width: 80px;margin: 0 auto;}
    }

    a {
      display: block;
      background-color: #fff;
      font-size: $FONT-SIZE-15;
      line-height: 1.35;
      padding: 10px 5px;
      color: #666;
      text-align: center;
    }
  }

  &__list {
    max-width: 1000px;
    margin: 160px auto;
    border-top: solid 1px #ccc;

    @include mq(md) {
      margin: 80px auto;
    }

    @include mq(sm) {
      margin: 40px auto;
    }

    .region {
      margin: 0;

      &__item {
        display: flex;
        flex-direction: row;
        //background-color: #ccc;

        @include mq(md) {
          flex-direction: column;
          padding: 0 2.5%;
        }

        @include mq(sm) {
          flex-direction: column;
        }

        &:first-child {
          padding-top: 1px;
          margin-top: -1px;
        }

        & + li {
          border-top: solid 1px #ccc;
        }
      }

      &__name {
        width: 220px;
        font-size: $FONT-SIZE-20;
        text-align: left;
        padding-top: 20px;

        @include mq(sm) {
          padding: 20px 10px 0;
          width: 100%;
        }

        i {
          margin-right: 10px;
          color: #fd0004;
          font-size: $FONT-SIZE-22;
        }
      }
      &__data {
        font-size: $FONT-SIZE-13;
        text-align: left;
        padding-top: 20px;
      }

      &__head {
        display: flex;
        flex-direction: row;
        font-size: $FONT-SIZE-13;
        padding: 0 0 20px;

        @include mq(sm) {
          flex-direction: column;
          padding: 0 10px 20px;
        }

        .agencyName {
          width: 240px;

          @include mq(md) {
            width: 30%;
          }

          @include mq(sm) {
            width: 100%;
            margin-bottom: 5px;
            font-size: $FONT-SIZE-16;
          }
        }
        .agencyAddr {
          width: 540px;

          @include mq(md) {
            width: 70%;
          }

          @include mq(sm) {
            width: 100%;
          }
        }

        .fa {
          margin-right: 5px;
        }
      }

      table {

        @include mq(md) {
          width: 100%;
        }

        @include mq(sm) {
          display: block;
        }

        tbody {

          @include mq(sm) {
            display: block;
          }
        }

        tr {

          @include mq(sm) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            border-top: solid 1px #ccc;
            width: 100%;
            padding:20px 10px;
          }

          & + tr td {
            border-top: solid 1px #ccc;

            @include mq(sm) {
              border-top: none;
            }
          }

          &:nth-of-type(1) {

            @include mq(sm) {
              display: none;
            }
          }

          &:nth-of-type(2) td {
            border-top: none;
          }
        }

        th {
          background-color: #f6f6f6;
          line-height: 50px;
          vertical-align: middle;
          font-size: $FONT-SIZE-16;
          font-weight: 500;

          &:nth-of-type(1) {
            width: 240px;
            padding-left: 10px;

            @include mq(md) {
              width: 30%;
            }
          }
          &:nth-of-type(2) {
            width: 220px;

            @include mq(md) {
              width: 30%;
            }
          }
          &:nth-of-type(3) {
            width: 150px;

            @include mq(md) {
              width: 20%;
            }
          }
          &:nth-of-type(4) {
            width: 150px;

            @include mq(md) {
              width: 20%;
            }
          }
        }

        td {
          min-height: 50px;
          vertical-align: middle;
          padding: 10px 0;
          padding-right: 10px;

          @include mq(sm) {
            width: 100%;
            min-height: auto;
            padding: 0;
            display: block;
          }

          &:nth-of-type(1) {
            width: 240px;
            padding-left: 10px;

            @include mq(md) {
              width: 30%;
            }

            @include mq(sm) {
              width: 100%;
              padding: 0;
              font-size: $FONT-SIZE-16;
            }
          }
          &:nth-of-type(2) {
            width: 220px;

            @include mq(md) {
              width: 30%;
            }

            @include mq(sm) {
              width: 100%;
            }
          }
          &:nth-of-type(3) {
            width: 150px;

            @include mq(md) {
              width: 20%;
            }

            @include mq(sm) {
              width: 100%;
            }
          }
          &:nth-of-type(4) {
            width: 150px;

            @include mq(md) {
              width: 20%;
            }

            @include mq(sm) {
              width: 100%;
            }
          }

          &:nth-of-type(3) .fa,
          &:nth-of-type(4) .fa {
            display: none;

            @include mq(sm) {
              display: inline-block;
              margin-right: 5px;
            }
          }

          & + td {
            margin-top: 5px;
          }
        }

        a {
          color: $COLOR__ACCENT-PRIMARY;
          i.fa {
            color: #ccc;
            margin-left: 5px;
          }
        }
      }
    }
  }
}







/* ------------------------------------------------------------ */
